import { Tour } from '@/Components/Tour/Tour';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tourSelectors } from '@/Redux/Tours/selectors';
import { tourActions } from '@/Redux/Tours/reducer';
import { useTours } from '@/Components/Tours/hooks/useTours';

export function Tours() {
  const dispatch = useDispatch();
  const runTour = useSelector(tourSelectors.runTour);
  const openSidebar = useSelector(tourSelectors.openSidebar);
  const currentTour = useSelector(tourSelectors.currentTour);
  const activeStepIdx = useSelector(tourSelectors.activeStep);
  const currentPageTours = useTours();

  useEffect(() => {
    dispatch(tourActions.setRouteToursCount(currentPageTours.length));
  }, [currentPageTours]);

  const activeTour = useMemo(() => {
    return currentPageTours.find((item) => item.id == currentTour);
  }, [currentPageTours, currentTour]);

  const toggleTour = (active: boolean) => {
    dispatch(tourActions.setRun({ run: active, tour: currentTour, open: openSidebar }));
  };

  const toggleLiftSidebar = (lift: boolean) => {
    dispatch(tourActions.setLiftSidebar(lift));
  };

  const setTourStep = (idx: number) => {
    dispatch(tourActions.setActiveStep(idx));
  };

  if (!activeTour) return null;

  return (
    <Tour
      runTour={runTour}
      activeStep={activeStepIdx}
      setTourStep={setTourStep}
      toggleTour={toggleTour}
      toggleLift={toggleLiftSidebar}
      tour={activeTour}
    />
  );
}
