import React, { useEffect } from 'react';
import styles from './HelpSidebar.scss';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { tourSelectors } from '@/Redux/Tours/selectors';
import { tourActions } from '@/Redux/Tours/reducer';
import { useRouteItems } from './hooks/useRouteItems';
import { TourList } from './modules/TourList/TourList';
import { RouteItem } from './modules/RouteItem/RouteItem';
import { useLocalization } from '@/Hooks';
import { WarningModal } from './modules/WarningModal/WarningModal';
import { minWindowSize, useTourActions } from '@/Components/Tours/hooks/useTourActions';

export const HelpSidebar = () => {
  const dispatch = useDispatch();

  const {
    sidebar: { Title },
  } = useLocalization();

  const { routeItems } = useRouteItems();
  const { openWarningModal } = useTourActions();

  const openSidebar = useSelector(tourSelectors.openSidebar);
  const liftSidebar = useSelector(tourSelectors.liftSidebar);
  const selectedRoute = useSelector(tourSelectors.selectedRoute);
  const currentTour = useSelector(tourSelectors.currentTour);
  const warningModalIsOpen = useSelector(tourSelectors.openWarningModal);

  const closeSidebar = () => {
    dispatch(tourActions.setRun({ run: false, tour: currentTour, open: false }));
  };

  const onChangeSize = () => {
    const windowSizeCorrect = window.innerWidth >= minWindowSize;

    if (liftSidebar && !windowSizeCorrect) {
      closeSidebar();
      openWarningModal(true);
    }
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.ctrlKey || event.metaKey) {
      if (event.key == '+' || event.key == '-') {
        onChangeSize();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('resize', onChangeSize);
    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('resize', onChangeSize);
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [warningModalIsOpen, liftSidebar]);

  return (
    <>
      {warningModalIsOpen && <WarningModal />}

      <div className={cn(styles.sidebar, { [styles.open]: openSidebar, [styles.liftSidebar]: liftSidebar })}>
        <div className={styles.sidebarBlock}>
          <div className={styles.sidebarHeader}>
            <h3>{Title}</h3>
            <button className={styles.closeBtn} onClick={closeSidebar}>
              &times;
            </button>
          </div>

          <div className={styles.sidebarContent}>
            {!selectedRoute ? (
              <>
                {routeItems.map((item, idx) => (
                  <RouteItem key={item.title} idx={idx + 1} node={item} />
                ))}
              </>
            ) : (
              <TourList />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
