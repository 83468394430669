/* eslint-disable quotes */
import { SimpleObject } from '@/Types';

export type TranslationType = typeof enTranslation;
export type TranslationDocumentDesignerType = typeof enTranslationDocumentDesigner;
export type TranslationProducts = SimpleObject;

export const enTranslation = {
  Common: {
    DragNDrop: 'Drag & Drop to upload files',
    SessionBlocked: 'Session blocked by user(s): {0}',
    InputLabels: {
      Company: 'Company',
      Name: 'Name',
      Additional: 'Additional',
      Street: 'Street',
      ZipCity: 'ZIP/City',
      Country: 'Country',
      Email: 'E-Mail',
      Phone: 'Phone',
    },
    FileSizes: {
      Kb: 'KB',
      Mb: 'MB',
      Gb: 'GB',
    },
    Months: {
      January: 'January',
      February: 'February',
      March: 'March',
      April: 'April',
      May: 'May',
      June: 'June',
      July: 'July',
      August: 'August',
      September: 'September',
      October: 'October',
      November: 'November',
      December: 'December',
    },
    DaysOfWeek: {
      Sunday: 'Su',
      Monday: 'Mo',
      Tuesday: 'Tu',
      Wednesday: 'We',
      Thursday: 'Th',
      Friday: 'Fr',
      Saturday: 'Sa',
    },
    All: 'All',
    Or: 'or',
    InputPlaceholders: {
      Select: 'Select',
    },
    SelectAll: 'Select all',
    TimeFormats: {
      'h:mm:ss a': '9:00 AM (12 hours)',
      'hh:mm:ss a': '09:00 AM (12 hours)',
      'H:mm:ss': '9:00 (24 hours)',
      'HH:mm:ss': '09:00 (24 hours)',
      H_mm_ss: '9.00 (24 hours)',
      HH_mm_ss: '09.00 (24 hours)',
    },
    TimeZones: {
      'Europe/London': '(+01:00) BST London',
      'Europe/Dublin': '(+01:00) IST Dublin',
      'Europe/Lisbon': '(+01:00) WEST Lisbon',
      'Europe/Amsterdam': '(+02:00) CEST Amsterdam',
      'Europe/Andorra': '(+02:00) CEST Andorra',
      'Europe/Belgrade': '(+02:00) CEST Belgrade',
      'Europe/Berlin': '(+02:00) CEST Berlin',
      'Europe/Brussels': '(+02:00) CEST Brussels',
      'Europe/Budapest': '(+02:00) CEST Budapest',
      'Europe/Copenhagen': '(+02:00) CEST Copenhagen',
      'Europe/Gibraltar': '(+02:00) CEST Gibraltar',
      'Europe/Luxembourg': '(+02:00) CEST Luxembourg',
      'Europe/Madrid': '(+02:00) CEST Madrid',
      'Europe/Malta': '(+02:00) CEST Malta',
      'Europe/Monaco': '(+02:00) CEST Monaco',
      'Europe/Oslo': '(+02:00) CEST Oslo',
      'Europe/Paris': '(+02:00) CEST Paris',
      'Europe/Prague': '(+02:00) CEST Prague',
      'Europe/Rome': '(+02:00) CEST Rome',
      'Europe/Stockholm': '(+02:00) CEST Stockholm',
      'Europe/Tirane': '(+02:00) CEST Tirane',
      'Europe/Vienna': '(+02:00) CEST Vienna',
      'Europe/Warsaw': '(+02:00) CEST Warsaw',
      'Europe/Zurich': '(+02:00) CEST Zurich',
      'Europe/Kaliningrad': '(+02:00) EET Kaliningrad',
      'Europe/Athens': '(+03:00) EEST Athens',
      'Europe/Bucharest': '(+03:00) EEST Bucharest',
      'Europe/Chisinau': '(+03:00) EEST Chisinau',
      'Europe/Helsinki': '(+03:00) EEST Helsinki',
      'Europe/Kiev': '(+03:00) EEST Kiev',
      'Europe/Nicosia': '(+03:00) EEST Nicosia',
      'Europe/Riga': '(+03:00) EEST Riga',
      'Europe/Sofia': '(+03:00) EEST Sofia',
      'Europe/Tallinn': '(+03:00) EEST Tallinn',
      'Europe/Uzhgorod': '(+03:00) EEST Uzhgorod',
      'Europe/Vilnius': '(+03:00) EEST Vilnius',
      'Europe/Zaporozhye': '(+03:00) EEST Zaporozhye',
      'Europe/Istanbul': '(+03:00) Istanbul',
      'Europe/Kirov': '(+03:00) Kirov',
      'Europe/Moscow': '(+03:00) MSK Moscow',
      'Europe/Simferopol': '(+03:00) MSK Simferopol',
      'Europe/Minsk': '(+03:00) Minsk',
      'Europe/Astrakhan': '(+04:00) Astrakhan',
      'Europe/Samara': '(+04:00) Samara',
      'Europe/Saratov': '(+04:00) Saratov',
      'Europe/Ulyanovsk': '(+04:00) Ulyanovsk',
      'Europe/Volgograd': '(+04:00) Volgograd',
      'America/Adak': '(-09:00) HDT Adak',
      'America/Anchorage': '(-08:00) AKDT Anchorage',
      'America/Juneau': '(-08:00) AKDT Juneau',
      'America/Metlakatla': '(-08:00) AKDT Metlakatla',
      'America/Nome': '(-08:00) AKDT Nome',
      'America/Sitka': '(-08:00) AKDT Sitka',
      'America/Yakutat': '(-08:00) AKDT Yakutat',
      'America/Creston': '(-07:00) MST Creston',
      'America/Dawson_Creek': '(-07:00) MST Dawson Creek',
      'America/Fort_Nelson': '(-07:00) MST Fort Nelson',
      'America/Hermosillo': '(-07:00) MST Hermosillo',
      'America/Phoenix': '(-07:00) MST Phoenix',
      'America/Dawson': '(-07:00) PDT Dawson',
      'America/Ensenada': '(-07:00) PDT Ensenada',
      'America/Los_Angeles': '(-07:00) PDT Los Angeles',
      'America/Tijuana': '(-07:00) PDT Tijuana',
      'America/Vancouver': '(-07:00) PDT Vancouver',
      'America/Whitehorse': '(-07:00) PDT Whitehorse',
      'America/Belize': '(-06:00) CST Belize',
      'America/Costa_Rica': '(-06:00) CST Costa Rica',
      'America/El_Salvador': '(-06:00) CST El Salvador',
      'America/Guatemala': '(-06:00) CST Guatemala',
      'America/Managua': '(-06:00) CST Managua',
      'America/Regina': '(-06:00) CST Regina',
      'America/Swift_Current': '(-06:00) CST Swift Current',
      'America/Tegucigalpa': '(-06:00) CST Tegucigalpa',
      'America/Boise': '(-06:00) MDT Boise',
      'America/Cambridge_Bay': '(-06:00) MDT Cambridge Bay',
      'America/Chihuahua': '(-06:00) MDT Chihuahua',
      'America/Denver': '(-06:00) MDT Denver',
      'America/Edmonton': '(-06:00) MDT Edmonton',
      'America/Inuvik': '(-06:00) MDT Inuvik',
      'America/Mazatlan': '(-06:00) MDT Mazatlan',
      'America/Ojinaga': '(-06:00) MDT Ojinaga',
      'America/Yellowknife': '(-06:00) MDT Yellowknife',
      'America/Bogota': '(-05:00) Bogota',
      'America/Bahia_Banderas': '(-05:00) CDT Bahia Banderas',
      'America/Chicago': '(-05:00) CDT Chicago',
      'America/Indiana/Knox': '(-05:00) CDT Indiana/Knox',
      'America/Indiana/Tell_City': '(-05:00) CDT Indiana/Tell City',
      'America/Matamoros': '(-05:00) CDT Matamoros',
      'America/Menominee': '(-05:00) CDT Menominee',
      'America/Merida': '(-05:00) CDT Merida',
      'America/Mexico_City': '(-05:00) CDT Mexico City',
      'America/Monterrey': '(-05:00) CDT Monterrey',
      'America/North_Dakota/Beulah': '(-05:00) CDT North Dakota/Beulah',
      'America/North_Dakota/Center': '(-05:00) CDT North Dakota/Center',
      'America/North_Dakota/New_Salem': '(-05:00) CDT North Dakota/New Salem',
      'America/Rainy_River': '(-05:00) CDT Rainy River',
      'America/Rankin_Inlet': '(-05:00) CDT Rankin Inlet',
      'America/Resolute': '(-05:00) CDT Resolute',
      'America/Winnipeg': '(-05:00) CDT Winnipeg',
      'America/Atikokan': '(-05:00) EST Atikokan',
      'America/Cancun': '(-05:00) EST Cancun',
      'America/Coral_Harbour': '(-05:00) EST Coral Harbour',
      'America/Jamaica': '(-05:00) EST Jamaica',
      'America/Panama': '(-05:00) EST Panama',
      'America/Eirunepe': '(-05:00) Eirunepe',
      'America/Guayaquil': '(-05:00) Guayaquil',
      'America/Lima': '(-05:00) Lima',
      'America/Rio_Branco': '(-05:00) Rio Branco',
      'America/Barbados': '(-04:00) AST Barbados',
      'America/Blanc-Sablon': '(-04:00) AST Blanc-Sablon',
      'America/Curacao': '(-04:00) AST Curacao',
      'America/Martinique': '(-04:00) AST Martinique',
      'America/Port_of_Spain': '(-04:00) AST Port of Spain',
      'America/Puerto_Rico': '(-04:00) AST Puerto Rico',
      'America/Santo_Domingo': '(-04:00) AST Santo Domingo',
      'America/Boa_Vista': '(-04:00) Boa Vista',
      'America/Havana': '(-04:00) CDT Havana',
      'America/Campo_Grande': '(-04:00) Campo Grande',
      'America/Caracas': '(-04:00) Caracas',
      'America/Cuiaba': '(-04:00) Cuiaba',
      'America/Detroit': '(-04:00) EDT Detroit',
      'America/Grand_Turk': '(-04:00) EDT Grand Turk',
      'America/Indiana/Indianapolis': '(-04:00) EDT Indiana/Indianapolis',
      'America/Indiana/Marengo': '(-04:00) EDT Indiana/Marengo',
      'America/Indiana/Petersburg': '(-04:00) EDT Indiana/Petersburg',
      'America/Indiana/Vevay': '(-04:00) EDT Indiana/Vevay',
      'America/Indiana/Vincennes': '(-04:00) EDT Indiana/Vincennes',
      'America/Indiana/Winamac': '(-04:00) EDT Indiana/Winamac',
      'America/Iqaluit': '(-04:00) EDT Iqaluit',
      'America/Kentucky/Louisville': '(-04:00) EDT Kentucky/Louisville',
      'America/Kentucky/Monticello': '(-04:00) EDT Kentucky/Monticello',
      'America/Nassau': '(-04:00) EDT Nassau',
      'America/New_York': '(-04:00) EDT New York',
      'America/Nipigon': '(-04:00) EDT Nipigon',
      'America/Pangnirtung': '(-04:00) EDT Pangnirtung',
      'America/Port-au-Prince': '(-04:00) EDT Port-au-Prince',
      'America/Thunder_Bay': '(-04:00) EDT Thunder Bay',
      'America/Toronto': '(-04:00) EDT Toronto',
      'America/Guyana': '(-04:00) Guyana',
      'America/La_Paz': '(-04:00) La Paz',
      'America/Manaus': '(-04:00) Manaus',
      'America/Porto_Velho': '(-04:00) Porto Velho',
      'America/Glace_Bay': '(-03:00) ADT Glace Bay',
      'America/Goose_Bay': '(-03:00) ADT Glace Bay',
      'America/Halifax': '(-03:00) ADT Halifax',
      'America/Moncton': '(-03:00) ADT Moncton',
      'America/Thule': '(-03:00) ADT Thule',
      'America/Araguaina': '(-03:00) Araguaina',
      'America/Argentina/Buenos_Aires': '(-03:00) Argentina/Buenos Aires',
      'America/Argentina/Catamarca': '(-03:00) Argentina/Catamarca',
      'America/Argentina/Cordoba': '(-03:00) Argentina/Cordoba',
      'America/Argentina/Jujuy': '(-03:00) Argentina/Jujuy',
      'America/Argentina/La_Rioja': '(-03:00) Argentina/La Rioja',
      'America/Argentina/Mendoza': '(-03:00) Argentina/Mendoza',
      'America/Argentina/Rio_Gallegos': '(-03:00) Argentina/Rio Gallegos',
      'America/Argentina/Salta': '(-03:00) Argentina/Salta',
      'America/Argentina/San_Juan': '(-03:00) Argentina/San Juan',
      'America/Argentina/San_Luis': '(-03:00) Argentina/San Luis',
      'America/Argentina/Tucuman': '(-03:00) Argentina/Tucuman',
      'America/Bahia': '(-03:00) Bahia',
      'America/Belem': '(-03:00) Belem',
      'America/Catamarca': '(-03:00) Catamarca',
      'America/Cayenne': '(-03:00) Cayenne',
      'America/Fortaleza': '(-03:00) Fortaleza',
      'America/Maceio': '(-03:00) Maceio',
      'America/Montevideo': '(-03:00) Montevideo',
      'America/Paramaribo': '(-03:00) Paramaribo',
      'America/Punta_Arenas': '(-03:00) Punta Arenas',
      'America/Recife': '(-03:00) Recife',
      'America/Santarem': '(-03:00) Santarem',
      'America/Santiago': '(-03:00) Santiago',
      'America/Sao_Paulo': '(-03:00) Sao Paulo',
      'America/St_Johns': '(-02:30) NDT St Johns',
      'America/Godthab': '(-02:00) Godthab',
      'America/Miquelon': '(-02:00) Miquelon',
      'America/Noronha': '(-02:00) Noronha',
      'America/Danmarkshavn': '(+00:00) GMT Danmarkshavn',
      'America/Scoresbysund': '(+00:00) Scoresbysund',
      'America/Asuncion': '(-03:00) Asuncion',
      'America/Argentina/Ushuaia': '(-03:00) Argentina/Ushuaia',
      'Atlantic/Bermuda': '(-03:00) ADT Bermuda',
      'Atlantic/Stanley': '(-03:00) Stanley',
      'Atlantic/South_Georgia': '(-02:00) South Georgia',
      'Atlantic/Cape_Verde': '(-01:00) Cape Verde',
      'Atlantic/Azores': '(+00:00) Azores',
      'Atlantic/Reykjavik': '(+00:00) GMT Reykjavik',
      'Atlantic/St_Helena': '(+00:00) GMT St Helena',
      'Atlantic/Canary': '(+01:00) WEST Canary',
      'Atlantic/Faeroe': '(+01:00) WEST Faeroe',
      'Atlantic/Faroe': '(+01:00) WEST Faroe',
      'Atlantic/Madeira': '(+01:00) WEST Madeira',
      'Atlantic/Jan_Mayen': '(+02:00) CEST Jan Mayen',
      'Africa/Abidjan': '(+00:00) GMT Abidjan',
      'Africa/Accra': '(+00:00) GMT Accra',
      'Africa/Bissau': '(+00:00) GMT Accra',
      'Africa/Monrovia': '(+00:00) GMT Accra',
      'Africa/Algiers': '(+01:00) CET Algiers',
      'Africa/Tunis': '(+01:00) CET Tunis',
      'Africa/Casablanca': '(+01:00) Casablanca',
      'Africa/El_Aaiun': '(+01:00) El Aaiun',
      'Africa/Lagos': '(+01:00) WAT Lagos',
      'Africa/Ndjamena': '(+01:00) WAT Ndjamena',
      'Africa/Khartoum': '(+02:00) CAT Khartoum',
      'Africa/Maputo': '(+02:00) CAT Maputo',
      'Africa/Windhoek': '(+02:00) CAT Windhoek',
      'Africa/Ceuta': '(+02:00) CEST Ceuta',
      'Africa/Cairo': '(+02:00) EET Cairo',
      'Africa/Tripoli': '(+02:00) EET Tripoli',
      'Africa/Johannesburg': '(+02:00) SAST Johannesburg',
      'Africa/Asmera': '(+03:00) EAT Asmera',
      'Africa/Juba': '(+03:00) EAT Juba',
      'Africa/Nairobi': '(+03:00) EAT Nairobi',
      'Indian/Mahe': 'Indian/Mahe',
      'Indian/Mauritius': '(+04:00) Mauritius',
      'Indian/Reunion': '(+04:00) Reunion',
      'Indian/Kerguelen': '(+05:00) Kerguelen',
      'Indian/Maldives': '(+05:00) Maldives',
      'Indian/Chagos': '(+06:00) Chagos',
      'Indian/Cocos': '(+06:30) Cocos',
      'Indian/Christmas': '(+07:00) Christmas',
      'Asia/Baghdad': '(+03:00) Baghdad',
      'Asia/Amman': '(+03:00) EEST Amman',
      'Asia/Beirut': '(+03:00) EEST Beirut',
      'Asia/Damascus': '(+03:00) EEST Damascus',
      'Asia/Famagusta': '(+03:00) EEST Famagusta',
      'Asia/Gaza': '(+03:00) EEST Gaza',
      'Asia/Hebron': '(+03:00) EEST Hebron',
      'Asia/Nicosia': '(+03:00) EEST Nicosia',
      'Asia/Jerusalem': '(+03:00) IDT Jerusalem',
      'Asia/Qatar': '(+03:00) Qatar',
      'Asia/Riyadh': '(+03:00) Riyadh',
      'Asia/Tehran': '(+03:30) Tehran',
      'Asia/Baku': '(+04:00) Baku',
      'Asia/Dubai': '(+04:00) Dubai',
      'Asia/Tbilisi': '(+04:00) Tbilisi',
      'Asia/Yerevan': '(+04:00) Yerevan',
      'Asia/Kabul': '(+04:30) Kabul',
      'Asia/Aqtau': '(+05:00) Aqtau',
      'Asia/Aqtobe': '(+05:00) Aqtobe',
      'Asia/Ashgabat': '(+05:00) Ashgabat',
      'Asia/Atyrau': '(+05:00) Atyrau',
      'Asia/Dushanbe': '(+05:00) Dushanbe',
      'Asia/Oral': '(+05:00) Oral',
      'Asia/Karachi': '(+05:00) PKT Karachi',
      'Asia/Qyzylorda': '(+05:00) Qyzylorda',
      'Asia/Samarkand': '(+05:00) Samarkand',
      'Asia/Tashkent': '(+05:00) Tashkent',
      'Asia/Yekaterinburg': '(+05:00) Yekaterinburg',
      'Asia/Colombo': '(+05:30) Colombo',
      'Asia/Kolkata': '(+05:30) IST Kolkata',
      'Asia/Katmandu': '(+05:45) Katmandu',
      'Asia/Almaty': '(+06:00) Almaty',
      'Asia/Bishkek': '(+06:00) Bishkek',
      'Asia/Dhaka': '(+06:00) Dhaka',
      'Asia/Omsk': '(+06:00) Omsk',
      'Asia/Qostanay': '(+06:00) Qostanay',
      'Asia/Thimphu': '(+06:00) Thimphu',
      'Asia/Urumqi': '(+06:00) Urumqi',
      'Asia/Yangon': '(+06:30) Yangon',
      'Asia/Bangkok': '(+07:00) Bangkok',
      'Asia/Barnaul': '(+07:00) Barnaul',
      'Asia/Ho_Chi_Minh': '(+07:00) Ho Chi Minh',
      'Asia/Hovd': '(+07:00) Hovd',
      'Asia/Krasnoyarsk': '(+07:00) Krasnoyarsk',
      'Asia/Novokuznetsk': '(+07:00) Novokuznetsk',
      'Asia/Novosibirsk': '(+07:00) Novosibirsk',
      'Asia/Tomsk': '(+07:00) Tomsk',
      'Asia/Jakarta': '(+07:00) WIB Jakarta',
      'Asia/Pontianak': '(+07:00) WIB Pontianak',
      'Asia/Brunei': '(+08:00) Brunei',
      'Asia/Harbin': '(+08:00) CST Harbin',
      'Asia/Macau': '(+08:00) CST Macau',
      'Asia/Shanghai': '(+08:00) CST Shanghai',
      'Asia/Taipei': '(+08:00) CST Taipei',
      'Asia/Choibalsan': '(+08:00) Choibalsan',
      'Asia/Hong_Kong': '(+08:00) HKT Hong Kong',
      'Asia/Irkutsk': '(+08:00) Irkutsk',
      'Asia/Kuala_Lumpur': '(+08:00) Kuala Lumpur',
      'Asia/Kuching': '(+08:00) Kuching',
      'Asia/Manila': '(+08:00) PST Manila',
      'Asia/Singapore': '(+08:00) Singapore',
      'Asia/Ulaanbaatar': '(+08:00) Ulaanbaatar',
      'Asia/Makassar': '(+08:00) WITA Makassar',
      'Asia/Chita': '(+09:00) Chita',
      'Asia/Dili': '(+09:00) Dili',
      'Asia/Tokyo': '(+09:00) JST Tokyo',
      'Asia/Pyongyang': '(+09:00) KST Pyongyang',
      'Asia/Seoul': '(+09:00) KST Seoul',
      'Asia/Khandyga': '(+09:00) Khandyga',
      'Asia/Jayapura': '(+09:00) WIT Jayapura',
      'Asia/Yakutsk': '(+09:00) Yakutsk',
      'Asia/Ust-Nera': '(+10:00) Ust-Nera',
      'Asia/Vladivostok': '(+10:00) Vladivostok',
      'Asia/Magadan': '(+11:00) Magadan',
      'Asia/Sakhalin': '(+11:00) Sakhalin',
      'Asia/Srednekolymsk': '(+11:00) Srednekolymsk',
      'Asia/Anadyr': '(+12:00) Anadyr',
      'Asia/Kamchatka': '(+12:00) Kamchatka',
      'Australia/Perth': '(+08:00) AWST Perth',
      'Australia/Eucla': '(+08:00) AWST Perth',
      'Australia/Darwin': '(+09:30) ACST Darwin',
      'Australia/Brisbane': '(+10:00) AEST Brisbane',
      'Australia/Lindeman': '(+10:00) AEST Lindeman',
      'Australia/Adelaide': '(+10:30) ACDT Adelaide',
      'Australia/Broken_Hill': '(+10:30) ACDT Broken Hill',
      'Australia/Currie': '(+11:00) AEDT Currie',
      'Australia/Hobart': '(+11:00) AEDT Hobart',
      'Australia/Melbourne': '(+11:00) AEDT Melbourne',
      'Australia/Sydney': '(+11:00) AEDT Melbourne',
      'Australia/Lord_Howe': '(+11:00) Lord Howe',
      'Pacific/Niue': '(-11:00) Niue',
      'Pacific/Pago_Pago': '(-11:00) SST Pago Pago',
      'Pacific/Honolulu': '(-10:00) HST Honolulu',
      'Pacific/Rarotonga': '(-10:00) Rarotonga',
      'Pacific/Tahiti': '(-10:00) Tahiti',
      'Pacific/Marquesas': '(-09:30) Marquesas',
      'Pacific/Gambier': '(-09:00) Gambier',
      'Pacific/Pitcairn': '(-08:00) Pitcairn',
      'Pacific/Galapagos': '(-06:00) Galapagos',
      'Pacific/Easter': '(-05:00) Easter',
      'Pacific/Palau': '(+09:00) Palau',
      'Pacific/Guam': '(+10:00) ChST Guam',
      'Pacific/Chuuk': '(+10:00) Chuuk',
      'Pacific/Port_Moresby': '(+10:00) Port Moresby',
      'Pacific/Bougainville': '(+11:00) Bougainville',
      'Pacific/Efate': '(+11:00) Efate',
      'Pacific/Guadalcanal': '(+11:00) Guadalcanal',
      'Pacific/Kosrae': '(+11:00) Kosrae',
      'Pacific/Noumea': '(+11:00) Noumea',
      'Pacific/Pohnpei': '(+11:00) Pohnpei',
      'Pacific/Fiji': '(+12:00) Fiji',
      'Pacific/Funafuti': '(+12:00) Funafuti',
      'Pacific/Kwajalein': '(+12:00) Kwajalein',
      'Pacific/Majuro': '(+12:00) Majuro',
      'Pacific/Nauru': '(+12:00) Nauru',
      'Pacific/Norfolk': '(+12:00) Norfolk',
      'Pacific/Tarawa': '(+12:00) Tarawa',
      'Pacific/Wake': '(+12:00) Wake',
      'Pacific/Wallis': '(+12:00) Wallis',
      'Pacific/Enderbury': '(+13:00) Enderbury',
      'Pacific/Fakaofo': '(+13:00) Fakaofo',
      'Pacific/Auckland': '(+13:00) NZDT Auckland',
      'Pacific/Tongatapu': '(+13:00) Tongatapu',
      'Pacific/Chatham': '(+13:45) Chatham',
      'Pacific/Apia': '(+14:00) Apia',
      'Pacific/Kiritimati': '(+14:00) Kiritimati',
      'Antarctica/Palmer': '(-03:00) Palmer',
      'Antarctica/Rothera': '(-03:00) Rothera',
      'Antarctica/Troll': '(+02:00) Troll',
      'Antarctica/Syowa': '(+03:00) Syowa',
      'Antarctica/Mawson': '(+05:00) Mawson',
      'Antarctica/Vostok': '(+06:00) Vostok',
      'Antarctica/Davis': '(+07:00) Davis',
      'Antarctica/Casey': '(+08:00) Casey',
      'Antarctica/DumontDUrville': '(+10:00) DumontDUrville',
      'Antarctica/Macquarie': '(+11:00) Macquarie',
      GMT: '(+00:00) GMT',
      UTC: '(+00:00) UTC',
    },
  },
  Notifications: {
    ChangePaymentStatusCompleted: {
      title: 'Change payment status complete',
      text: 'Change payment status for order {0} completed.',
    },
    GetMpAccountTokenSuccess: {
      title: 'Connection',
      text: 'Сonnection with your account has been successfully established.',
    },
    GetMpAccountTokenError: {
      title: 'Connection',
      text: 'Connection failed. Check the data entered.',
    },
    UploadInvoiceFailed: {
      title: 'Upload invoice',
      text: 'Upload invoice failed',
    },
    CancelInvoiceFailed: {
      title: 'Cancel invoice',
      text: 'Cancel invoice failed',
    },
    SynchOrdersUnhandledError: {
      title: 'Orders synchronization error',
      text: 'Unhandled errors for {0} {0_account}:<br/>{1}',
      parts: {
        account: {
          new: 'account',
          many: 'accounts',
          few: 'accounts',
        },
      },
    },
    SynchFeedbacksUnhandledError: {
      title: 'Feedbacks synchronization error',
      text: 'Unhandled errors for {0} {0_account}:<br/>{1}',
      parts: {
        account: {
          new: 'account',
          many: 'accounts',
          few: 'accounts',
        },
      },
    },
    SynchFeedbacksTokenError: {
      title: 'Feedbacks synchronization error',
      text: 'Token is invalid. Check token status in Marketplace settings for {0} {0_account}: {1}',
      parts: {
        account: {
          new: 'account',
          many: 'accounts',
          few: 'accounts',
        },
      },
    },
    SynchOrdersTokenError: {
      title: 'Orders synchronization error',
      text: 'Token is invalid. Check token status in Marketplace settings for {0} {0_account}: {1}',
      parts: {
        account: {
          new: 'account',
          many: 'accounts',
          few: 'accounts',
        },
      },
    },
    SynchFeedbacksAllReadyStartedError: {
      title: 'Fedbacks synchronization error',
      text: 'Synchronization allready started!',
    },
    SynchOrdersAllReadyStartedError: {
      title: 'Orders synchronization error',
      text: 'Synchronization allready started!',
    },
    SynchOrdersComplete: {
      title: 'Orders synchronization complete',
      text:
        '<b>Sales</b><br/>&ensp;{0} {0_newSales}{1}&ensp;{2} {2_updatedSales}{3}<br/><b>Purchases</b><br/>&ensp;{4} {4_newPurchases}{5}&ensp;{6} {6_updatedPurchases}{7}',
      parts: {
        newSales: {
          one: 'new sale<br/>',
          few: 'new sales<br/>',
          many: 'new sales<br/>',
        },
        updatedSales: {
          one: 'updated sale<br/>',
          many: 'updated sales<br/>',
          few: 'updated sales<br/>',
        },
        newPurchases: {
          one: 'new purchase<br/>',
          many: 'new purchases<br/>',
          few: 'new purchases<br/>',
        },
        updatedPurchases: {
          one: 'updated purchase',
          many: 'updated purchases',
          few: 'updated purchases',
        },
      },
    },
    SynchEmptyOrdersComplete: {
      title: 'Orders synchronization complete',
      text: 'No new and updated orders',
    },

    SynchEmptyPurchasesComplete: {
      title: 'Orders synchronization complete',
      text:
        '<b>Sales</b><br/>&ensp;{0} {0_newSales}{1}&ensp;{2} {2_updatedSales}{3}<b>Purchases</b><br/>&ensp;No new or updated purchases.',
      parts: {
        newSales: {
          one: 'new sale<br/>',
          few: 'new sales<br/>',
          many: 'new sales<br/>',
        },
        updatedSales: {
          one: 'updated sale<br/>',
          many: 'updated sales<br/>',
          few: 'updated sales<br/>',
        },
      },
    },
    UploadFilesCompleted: {
      title: 'Upload files complete',
      text: 'Uploading {0} files completed.',
    },
    SynchEmptySalesComplete: {
      title: 'Orders synchronization complete',
      text:
        '<b>Sales</b><br/>&ensp;No new or updated sales.<br/><b>Purchases</b><br/>&ensp;{0} {0_newPurchases}{1}&ensp;{2} {2_updatedPurchases}{3}',
      parts: {
        newPurchases: {
          one: 'new purchase<br/>',
          many: 'new purchases<br/>',
          few: 'new purchases<br/>',
        },
        updatedPurchases: {
          one: 'updated purchase',
          many: 'updated purchases',
          few: 'updated purchases',
        },
      },
    },
    SynchShippingStatusesComplete: {
      title: 'Order shiping statuses synchronization complete',
      text: '<b>Total shipping statuses updated</b> :{0}<br/>&ensp;{1}',
    },
    SynchShippingStatusesAlreadyStartedError: {
      title: 'Order shiping statuses synchronization error',
      text: 'Synchronization already started!',
    },
    SynchShippingStatusesEmptyComplete: {
      title: 'Order shiping statuses synchronization complete',
      text: 'No shipping statuses was updated for orders.',
    },
    SynchShippingStatusesUnhandledError: {
      title: 'Order shiping statuses synchronization error',
      text: 'Unhandled errors for {0} {0_logistic}:<br/>{1}',
      parts: {
        logistic: {
          new: 'logistic',
          many: 'logistics',
          few: 'logistics',
        },
      },
    },
    JobError: {
      title: 'Error',
      text: 'Error while executing task: {0}. {1}',
    },
    EmailConfirmationComplete: {
      title: 'E-Mail',
      text: 'Congratulations, your email has been successfully verified',
    },
    EmailResendSuccess: {
      title: 'E-Mail',
      text: 'Email with confirmation link successfully sent',
    },
    PasswordChangeSuccess: {
      title: 'Password',
      text: 'The password for your account has been successfully changed.',
    },
    CopyAddressSuccess: {
      text: 'Address successfully copied.',
      title: '',
    },
    GenerationLabelError: {
      title: 'Generation Label',
      text: "Can't generate label for order ID={0}. Error {1}.",
    },
    CreateShopOrderSuccess: {
      title: 'Create order',
      text: 'Order successfully created. Invoice has been sent to your email address.',
    },
    ChangeOrderMarkerDeclinedByMarketplace: {
      title: '',
      text:
        'The status cannot be changed for the order ID {0}. This change for this order has been blocked by the marketplace.',
    },
    MarketplaceInvoiceManagerTaskAlreadyStarted: {
      title: 'Marketplace invoice manager error',
      text: 'Marketplace invoice manager allready started!',
    },
    UploadInvoicesComplete: {
      title: 'Invoice uploading complete',
      text: 'Uploading {1_invoice} on marketplace for account {0} completed. Uploaded {1_invoice} {1_number}: {1}',
      parts: {
        invoice: {
          one: 'invoice',
          many: 'invoices',
          few: 'invoices',
        },
        number: {
          one: 'number',
          many: 'numbers',
          few: 'numbers',
        },
        uploaded: {
          one: 'Uploaded',
          many: 'Uploaded',
          few: 'Uploaded',
        },
      },
    },
    RemovingInvoicesComplete: {
      title: 'Invoice uploading complete',
      text: 'Removing {1_invoice} on marketplace for account {0} completed. {1_removed} {1_invoice} {1_number}: {1}',
      parts: {
        invoice: {
          one: 'invoice',
          many: 'invoices',
          few: 'invoices',
        },
        number: {
          one: 'number',
          many: 'numbers',
          few: 'numbers',
        },
        removed: {
          one: 'Removed',
          many: 'Removed',
          few: 'Removed',
        },
      },
    },
  },
  Errors: {
    UserWarehouseTemplateChangeError: {
      title: 'Template change error',
      text: 'You cannot change the order of existing items. Please add items to the bottom',
    },
    ServerIsNotAllowed: {
      title: 'Error',
      text: 'Server is not allowed. Please try again later!',
    },
    UserNotFoundError: {
      title: 'User not found!',
      text: 'User with current login and password not found.',
    },
    UserWithTheSameLoginAlreadyExistsError: {
      title: 'User',
      text: 'User with the same login already exists.',
    },
    IncorrectPasswordError: {
      title: 'Password',
      text: 'The password must contain at least one number, one lowercase, one uppercase, 6 characters.',
    },
    MarketplaceUserNotFoundError: {
      title: 'The Marketplace user was not found',
      text: 'Create market user for your country.',
    },
    DeletingSupplierWithNonZeroProductsError: {
      title: 'Supplier',
      text:
        'Supplier cannot be deleted because the warehouse management still receives one or more items for this supplier.',
    },
    EmptyOriginalShippingMethodError: {
      title: 'Shipping method',
      text: 'Shipping methods cannot be empty.',
    },
    DublicatedActualShippingMethodError: {
      title: 'Shipping method',
      text: "Rule with same parameters and '{0}' actual shipping method already created.",
    },
    DublicatedOriginalShippingMethodInAmountRuleError: {
      title: 'Marketplace',
      text: "Rule for account '{0}' with same parameters and '{1}' original shipping method already created.",
    },
    DublicatedOriginalShippingMethodInShippigRuleError: {
      title: 'Shipping method',
      text: "Rule with same parameters and '{0}' original shipping method already created.",
    },
    DublicatedMaxItemsQtyInPackagingRuleError: {
      title: 'Quantity',
      text: 'Rule with same max item quantity already created.',
    },
    DublicatedDocumentCategoryError: {
      title: 'Document category',
      text: "A document category '{0}' with same main category has already been created.",
    },
    DublicatedDocumentSubcategoryError: {
      title: 'Document subcategory',
      text: "A document subcategory '{0}' with same category has already been created.",
    },
    DublicatedDocumentTypeError: {
      title: 'Document type',
      text: "A document type '{0}' has already been created.",
    },
    DublicatedAllocateCategoryError: {
      title: 'Allocate category',
      text: "An allocate category '{0}' has already been created.",
    },
    DocumentTemplateHasChildReportsError: {
      title: 'Template has childs reports',
      text: 'Document template cannot be deleted.Template has childs reports',
    },
    DocumentTemplateModifyingAccessDeniedError: {
      title: 'Access denied',
      text: 'You cannot delete document template.',
    },
    UnhandledApplicationError: {
      title: 'Error',
      text: 'Inner server error. Try again later.',
    },
    UserProductNotFoundForOrder: {
      title: 'User product',
      text: 'Not found for order with id={0}',
    },
    DeleteUserProductHasChildOrdersError: {
      title: 'DeleteUserProductHasChildOrdersError',
      text: 'User product has child orders and cannot be deleted',
    },
    UserProductMoreThanOne: {
      title: 'Logistic product ',
      text: 'More than one for order with id={0}. User product ids={1}',
    },
    ProductCategoryNotFoundForOrder: {
      title: 'Product category ',
      text: 'Not found item category for order with id={0}',
    },
    FileNotFound: {
      title: 'File',
      text: 'File not found',
    },
    DublicatedReportError: {
      title: 'Report error ',
      text: 'A report with this title has already been created',
    },
    DublicatedLogisticTypeError: {
      title: 'Dublicated logistic type error',
      text: "An allocate logistic type '{0}' has already been created.",
    },
    DublicatedLogisticProductTypeError: {
      title: 'Dublicated product type error',
      text: "An allocate product type '{0}' has already been created.",
    },
    DublicatedLogisticAdditionalServiceTypeError: {
      title: 'Dublicated additional service type error',
      text: "An allocate additional service type '{0}' has already been created.",
    },
    DublicatedLogisticSubProductTypeError: {
      title: 'Dublicated subproduct type error',
      text: "An allocate subproduct type '{0}' has already been created.",
    },
    DublicatedLogisticShippingMethodTypeError: {
      title: 'Dublicated shipping method type error',
      text: "An allocate shipping method type '{0}' has already been created.",
    },
    DublicatedLogisticPackageTypeError: {
      title: 'Dublicated package type error',
      text: "An allocate package type '{0}' has already been created.",
    },
    MarketUserNotFoundForCountry: {
      title: 'Market user not found for this country',
      text: '',
    },
    NoFilesWereFoundForReportWithThisFilter: {
      title: 'Report error',
      text: 'No files were found for the report with this filter.',
    },
    DublicatedDocumentError: {
      title: 'Document error',
      text: '{0_file} with {0_same} {0_name} has already been {0_downloaded}.',
      parts: {
        file: {
          one: 'file',
          few: 'file',
          many: 'file',
        },
        name: {
          one: 'name',
          few: 'name',
          many: 'name',
        },
        downloaded: {
          one: 'downloaded',
          few: 'downloaded',
          many: 'downloaded',
        },
        same: {
          one: 'same',
          few: 'same',
          many: 'same',
        },
      },
    },
    DublicatedSubscriptionError: {
      title: 'Subscription error',
      text: 'Subscription with same name or sku has already been create.',
    },
    UserProductPackagesNotFound: {
      title: 'Packages not found',
      text: '',
    },
    DublicatedDigitalProductError: {
      title: 'Digital product error',
      text: 'Digital product with same name or sku has already been created.',
    },
    GenerationLabelError: {
      title: 'Generation Label',
      text: "Can't generate label. Error {0}-{1}",
    },
    EmptyUserCurrenciesError: {
      title: 'Update currency error',
      text: 'The user must have more than 0 currencies.',
    },
    NoDefaultUserCurrencyError: {
      title: 'Update currency error',
      text: 'One standard currency must be specified.',
    },
    LogisticBillableContractNotFound: {
      title: 'Generation label error',
      text: "Generation label for order '{0}' has error: User product is billable, but logistic contract not found.",
    },
    LogisticNoBillableContractNotFound: {
      title: 'Generation label error',
      text: "Generation label for order '{0}' has error: User product is no billable, but logistic contract not found.",
    },
    DublicatedUploadTemplateError: {
      title: 'Upload template error',
      text: 'Upload template with same name has already been created.',
    },
    AutoSaveUploadTemplateDeleteError: {
      title: 'Upload template deleting error',
      text: 'Unable to remove template with autosave flag. First select a new template for autosave.',
    },
    UploadTemplateForAutoSaveNotFoundError: {
      title: 'Upload template not found',
      text: 'Upload template for documents autosave not found.',
    },
    PackageSkuNotUnique: {
      title: 'Package error',
      text: 'Package with same sku has already been created',
    },
    PackageNameNotUnique: {
      title: 'Package error',
      text: 'This Name already exists',
    },
    EmailCredentialsError: {
      title: 'Email credentials error',
      text:
        "Your '{0}' marketplace account email information is incorrect. Please check the correctness of the entered data.",
    },
    NoInvoicesForOrdersError: {
      title: 'No invoices for orders',
      text: 'No invoice has been created for the orders: {0}.',
    },
    NoCancelInvoicesForOrdersError: {
      title: 'No cancel invoices for orders',
      text: 'No cancel invoice has been created for the orders: {0}.',
    },
    DocumentsForOrdersNotFoundError: {
      title: 'Document not found',
      text: 'Document not found in online archive for orders: {0}.',
    },
    DublicatedDocumentTemplateError: {
      title: 'Template error',
      text: 'Template with same name has already been created.',
    },
    AccountChartValuesAlreadyExistsError: {
      title: 'Account chart values',
      text: 'Account chart values have already been created: <br /> {0}.',
    },
    AccountChartValuesExistsForChangedTypeError: {
      title: 'Account chart column',
      text: "You can't update column. Values already created for this account chart column.",
    },
    AccountChartValuesExistsForDeletedTypeError: {
      title: 'Account chart column',
      text: "You can't delete column. Values already created for this account chart column.",
    },
    AccountChartTypeWithSameNameAlreadyExistsError: {
      title: 'Account chart column',
      text: "Account chart column with name '{0}' has already been created.",
    },
    AccountChartWithSameTitleAlreadyExistsError: {
      title: 'Account chart',
      text: "Account chart with title '{0}' has already been created.",
    },
    SMTPServerOrPortIsUnavailable: {
      title: 'Error',
      text: 'Check SMTP server address and port',
    },
    EmailOrPasswordInvalid: {
      title: 'Error',
      text: 'Email or password invalid',
    },
  },
  Warnings: {
    KauflandDoesNotSupportChangeSentStatus: {
      message:
        'Kaufland does not support change "sent" status to not sent. {0_order} {1} will not be changed on marketplace.',
      parts: {
        order: {
          one: 'Order',
          few: 'Orders',
          many: 'Orders',
        },
      },
    },
  },
  Enums: {
    BackgroundTaskType: {
      SyncOrders: {
        Title: 'Sync orders',
        Text: '',
      },
      SyncPurchases: {
        Title: 'Sync purchases',
        Text: '',
      },
      SyncFeedbacks: {
        Title: 'Sync feedbacks',
        Text: '',
      },
      ChangeDeliveryStatus: {
        Title: 'Change delivery status',
        Text: '',
      },
    },
    Countries: {
      Afghanistan: 'Afghanistan',
      AlandIslands: 'Aland Islands',
      Albania: 'Albania',
      Algeria: 'Algeria',
      AmericanSamoa: 'American Samoa',
      Andorra: 'Andorra',
      Angola: 'Angola',
      Anguilla: 'Anguilla',
      Antarctica: 'Antarctica',
      AntiguaAndBarbuda: 'Antigua and Barbuda',
      Argentina: 'Argentina',
      Armenia: 'Armenia',
      Aruba: 'Aruba',
      Australia: 'Australia',
      Austria: 'Austria',
      Azerbaijan: 'Azerbaijan',
      Bahamas: 'Bahamas',
      Bahrain: 'Bahrain',
      Bangladesh: 'Bangladesh',
      Barbados: 'Barbados',
      Belarus: 'Belarus',
      Belgium: 'Belgium',
      Belize: 'Belize',
      Benin: 'Benin',
      Bermuda: 'Bermuda',
      Bhutan: 'Bhutan',
      Bolivia: 'Bolivia',
      BonaireSintEustatiusAndSaba: 'Bonaire, Sint Eustatius and Saba',
      BosniaAndHerzegovina: 'Bosnia and Herzegovina',
      Botswana: 'Botswana',
      BouvetIsland: 'Bouvet Island',
      Brazil: 'Brazil',
      BritishIndianOceanTerritory: 'British Indian Ocean Territory',
      Brunei: 'Brunei',
      Bulgaria: 'Bulgaria',
      BurkinaFaso: 'Burkina Faso',
      BurmaMyanmar: 'Burma (Myanmar)',
      Burundi: 'Burundi',
      Cambodia: 'Cambodia',
      Cameroon: 'Cameroon',
      Canada: 'Canada',
      CapeVerde: 'Cape Verde',
      CaymanIslands: 'Cayman Islands',
      CentralAfricanRepublic: 'Central African Republic',
      Chad: 'Chad',
      Chile: 'Chile',
      China: 'China',
      ChristmasIsland: 'Christmas Island',
      CocosKeelingIslands: 'Cocos (Keeling) Islands',
      Colombia: 'Colombia',
      Comoros: 'Comoros',
      CongoDemRepublic: 'Congo. Dem. Republic',
      CongoRepublic: 'Congo Republic',
      CookIslands: 'Cook Islands',
      CostaRica: 'Costa Rica',
      Croatia: 'Croatia',
      Cuba: 'Cuba',
      Curacao: 'Curacao',
      Cyprus: 'Cyprus',
      CzechRepublic: 'Czech Republic',
      Denmark: 'Denmark',
      Djibouti: 'Djibouti',
      Dominica: 'Dominica',
      DominicanRepublic: 'Dominican Republic',
      EastTimor: 'East Timor',
      Ecuador: 'Ecuador',
      Egypt: 'Egypt',
      ElSalvador: 'El Salvador',
      EquatorialGuinea: 'Equatorial Guinea',
      Eritrea: 'Eritrea',
      Estonia: 'Estonia',
      Ethiopia: 'Ethiopia',
      FalklandIslands: 'Falkland Islands',
      FaroeIslands: 'Faroe Islands',
      Fiji: 'Fiji',
      Finland: 'Finland',
      France: 'France',
      FrenchGuiana: 'French Guiana',
      FrenchPolynesia: 'French Polynesia',
      FrenchSouthernTerritories: 'French Southern Territories',
      Gabon: 'Gabon',
      Gambia: 'Gambia',
      Georgia: 'Georgia',
      Germany: 'Germany',
      Ghana: 'Ghana',
      Gibraltar: 'Gibraltar',
      Greece: 'Greece',
      Greenland: 'Greenland',
      Grenada: 'Grenada',
      Guadeloupe: 'Guadeloupe',
      Guam: 'Guam',
      Guatemala: 'Guatemala',
      Guernsey: 'Guernsey',
      Guinea: 'Guinea',
      GuineaBissau: 'Guinea-Bissau',
      Guyana: 'Guyana',
      Haiti: 'Haiti',
      HeardIslandAndMcDonaldIslands: 'Heard Island and McDonald Islands',
      Honduras: 'Honduras',
      HongKong: 'Hongkong',
      Hungary: 'Hungary',
      Iceland: 'Iceland',
      India: 'India',
      Indonesia: 'Indonesia',
      Iran: 'Iran',
      Iraq: 'Iraq',
      Ireland: 'Ireland',
      Israel: 'Israel',
      Italy: 'Italy',
      IvoryCoast: 'Ivory Coast',
      Jamaica: 'Jamaica',
      Japan: 'Japan',
      Jersey: 'Jersey',
      Jordan: 'Jordan',
      Kazakhstan: 'Kazakhstan',
      Kenya: 'Kenya',
      Kiribati: 'Kiribati',
      KoreaDemRepublicOf: 'Korea. Dem. Republic of',
      Kuwait: 'Kuwait',
      Kyrgyzstan: 'Kyrgyzstan',
      Laos: 'Laos',
      Latvia: 'Latvia',
      Lebanon: 'Lebanon',
      Lesotho: 'Lesotho',
      Liberia: 'Liberia',
      Libya: 'Libya',
      Liechtenstein: 'Liechtenstein',
      Lithuania: 'Lithuania',
      Luxemburg: 'Luxemburg',
      Macau: 'Macau',
      Macedonia: 'Macedonia',
      Madagascar: 'Madagascar',
      Malawi: 'Malawi',
      Malaysia: 'Malaysia',
      Maldives: 'Maldives',
      Mali: 'Mali',
      Malta: 'Malta',
      ManIsland: 'Man Island',
      MarshallIslands: 'Marshall Islands',
      Martinique: 'Martinique',
      Mauritania: 'Mauritania',
      Mauritius: 'Mauritius',
      Mayotte: 'Mayotte',
      Mexico: 'Mexico',
      Micronesia: 'Micronesia',
      Moldova: 'Moldova',
      Monaco: 'Monaco',
      Mongolia: 'Mongolia',
      Montenegro: 'Montenegro',
      Montserrat: 'Montserrat',
      Morocco: 'Morocco',
      Mozambique: 'Mozambique',
      Namibia: 'Namibia',
      Nauru: 'Nauru',
      Nepal: 'Nepal',
      Netherlands: 'Netherlands',
      NetherlandsAntilles: 'Netherlands Antilles',
      NewCaledonia: 'New Caledonia',
      NewZealand: 'New Zealand',
      Nicaragua: 'Nicaragua',
      Niger: 'Niger',
      Nigeria: 'Nigeria',
      Niue: 'Niue',
      NorfolkIsland: 'Norfolk Island',
      NorthernMarianaIslands: 'Northern Mariana Islands',
      Norway: 'Norway',
      Oman: 'Oman',
      Pakistan: 'Pakistan',
      Palau: 'Palau',
      PalestinianTerritories: 'Palestinian Territories',
      Panama: 'Panama',
      PapuaNewGuinea: 'Papua New Guinea',
      Paraguay: 'Paraguay',
      Peru: 'Peru',
      Philippines: 'Philippines',
      Pitcairn: 'Pitcairn',
      Poland: 'Poland',
      Portugal: 'Portugal',
      PuertoRico: 'Puerto Rico',
      Qatar: 'Qatar',
      ReunionIsland: 'Reunion Island',
      Romania: 'Romania',
      RussianFederation: 'Russian Federation',
      Rwanda: 'Rwanda',
      SaintBarthelemy: 'Saint Barthelemy',
      SaintHelenaAscensionAndTristan: 'Saint Helena, Ascension and Tristan da Cunha[b]',
      SaintKittsAndNevis: 'Saint Kitts and Nevis',
      SaintLucia: 'Saint Lucia',
      SaintMartin: 'Saint Martin',
      SaintPierreAndMiquelon: 'Saint Pierre and Miquelon',
      SaintVincentAndTheGrenadines: 'Saint Vincent and the Grenadines',
      Samoa: 'Samoa',
      SanMarino: 'San Marino',
      SaoTomeAndPrincipe: 'Sao Tome and Principe',
      SaudiArabia: 'Saudi Arabia',
      Senegal: 'Senegal',
      Serbia: 'Serbia',
      Seychelles: 'Seychelles',
      SierraLeone: 'Sierra Leone',
      Singapore: 'Singapore',
      SintMaarten: 'Sint Maarten (Dutch part)',
      Slovakia: 'Slovakia',
      Slovenia: 'Slovenia',
      SolomonIslands: 'Solomon Islands',
      Somalia: 'Somalia',
      SouthAfrica: 'South Africa',
      SouthGeorgiaAndTheSouthSandwichIslands: 'South Georgia and the South Sandwich Islands',
      SouthKorea: 'South Korea',
      SouthSudan: 'South Sudan',
      Spain: 'Spain',
      SriLanka: 'Sri Lanka',
      Sudan: 'Sudan',
      Suriname: 'Suriname',
      SvalbardAndJanMayen: 'Svalbard and Jan Mayen',
      Swaziland: 'Swaziland',
      Sweden: 'Sweden',
      Switzerland: 'Switzerland',
      Syria: 'Syria',
      Taiwan: 'Taiwan',
      Tajikistan: 'Tajikistan',
      Tanzania: 'Tanzania',
      Thailand: 'Thailand',
      Togo: 'Togo',
      Tokelau: 'Tokelau',
      Tonga: 'Tonga',
      TrinidadAndTobago: 'Trinidad and Tobago',
      Tunisia: 'Tunisia',
      Turkey: 'Turkey',
      Turkmenistan: 'Turkmenistan',
      TurksAndCaicosIslands: 'Turks and Caicos Islands',
      Tuvalu: 'Tuvalu',
      Uganda: 'Uganda',
      Ukraine: 'Ukraine',
      UnitedArabEmirates: 'United Arab Emirates',
      UnitedKingdom: 'United Kingdom',
      UnitedStates: 'United States (USA)',
      UnitedStatesMinorOutlyingIslands: 'United States Minor Outlying Islands',
      Uruguay: 'Uruguay',
      Uzbekistan: 'Uzbekistan',
      Vanuatu: 'Vanuatu',
      VaticanCityState: 'Vatican City State',
      Venezuela: 'Venezuela',
      Vietnam: 'Vietnam',
      VirginIslandsBritish: 'Virgin Islands (British)',
      VirginIslandsUS: 'Virgin Islands (U.S.)',
      WallisAndFutuna: 'Wallis and Futuna',
      WesternSahara: 'Western Sahara',
      Yemen: 'Yemen',
      Zambia: 'Zambia',
      Zimbabwe: 'Zimbabwe',
      None: 'None',
    },
    CancelStatus: {
      CancelInitializedByBuyer: 'Cancelation initialized by buyer',
      CancelRejectedBySeller: 'Cancel rejected by seller',
      CanceledNotPaid: 'Canceled, not paid',
      CanceledNoRefund: 'Canceled, no refund',
      CanceledRefunded: 'Canceled, refunded',
      CanceledRefundFailed: 'Canceled, refund failed',
      CanceledRefundPending: 'Canceled, refund pending',
      CanceledWithoutReturning: 'Canceled without returning',
      CanceledNotReturnedYet: 'Canceled, not returned yet',
      CanceledReturned: 'Canceled, returned',
      CancelCompleted: 'Canceled, completed',
      FulfillmentCancel: 'Fulfillment Cancel',
      Invalid: 'Invalid status',
    },
    ColumnType: {
      String: 'Text',
      Boolean: 'Boolean',
      Date: 'Date',
      DateTime: 'Date and time',
      Number: 'Number',
    },
    DateRangeType: {
      Last30Days: 'last 30 days',
      Last90Days: 'last 90 days',
      Today: 'today',
      Yesterday: 'Yesterday',
      ThisWeek: 'This week',
      LastWeek: 'Last week',
      ThisMonth: 'This month',
      LastMonth: 'Last month',
      Custom: 'Custom',
      ThisQuartal: 'This quartal',
      LastQuartal: 'Last quartal',
      ThisYear: 'This year',
      LastYear: 'Last year',
    },
    DocumentPrintOrderType: {
      OrderId: 'By order',
      OrderNumber: 'By number',
    },
    OrderStatusFilter: {
      PaidNotReceived: 'Paid, not received',
      NotPaidNotReceived: 'Not paid, not received',
      ReceivedToday: 'Received today',
      ReceivedOn: 'Received on',
      ReceivedNotFeedbackReceived: 'Goods received, not feedback received',
      ReceivedNoFeedbackSubmitted: 'Goods received, not feedback submitted',
      NotPaidAll: 'Not paid, all',
      NotPaidFulfillment: 'Not paid (fulfillment)',
      NotPaidOverdue: 'Not paid, overdue',
      NotPaidArchived: 'Not paid, archived',
      NotShippedAll: 'Not shipped, all',
      NotShippedFulfillment: 'Not shipped (fulfillment)',
      NotShippedShippingToday: 'Not shipped, shipping today',
      NotShippedShippingLater: 'Not shipped, shipping later',
      NotShippedShippingOverdue: 'Not shipped, shipping overdue',
      NotShippedNoShippingRequired: 'Not shipped, no shipping required',
      NotShippedArchived: 'Not shipped, archived',
      ShippedToday: 'Shipped today',
      ShippedOn: 'Shipped on',
      FeedbackReceivedButNotSubmitted: 'Feedback received, but not submitted',
      FeedbackSubmittedButNotReceived: 'Feedback submitted, but not received',
      AllNotArchived: 'All, not archived',
      AllArchived: 'All, archived',
      All: 'All',
    },
    OrderStatusFilterGroups: {
      Statuses: 'Statuses',
      Shipping: 'Shipping',
      Payment: 'Payment',
      Feedback: 'Feedback',
      All: 'All',
    },
    OrderMarker: {
      Paid: 'Paid',
      NotPaid: 'Cancel - NotPaid',
      Shipped: 'Shipped',
      NotShipped: 'Cancel - shipped',
      Received: 'Received',
      NotReceived: 'Cancel - received',
      Notified: 'Notified',
      NotNotified: 'Cancel - notified',
      CanceledNotPaid: 'Canceled, not paid',
      NotCanceledNotPaid: 'Cancel - canceled, not paid',
      RefundedPaid: 'Refunded without shipping',
      NotRefundedPaid: 'Cancel - refunded without shipping',
      RefundedAndNotReturned: 'Refunded without return',
      NotRefundedAndNotReturned: 'Cancel - refund without return',
      Archived: 'Archived',
      NotArchived: 'Cancel - archived',
      Exported: 'Exported',
      NotExported: 'Cancel - exported',
      FeedbackReceived: 'Feedback received marking',
      NotFeedbackReceived: 'Cancel - feedback received',
      FeedbackSubmitted: 'Feedback submitting',
      NotFeedbackSubmitted: 'Cancel - feedback submitting',
      OrderEditEnabled: 'Order edit enable',
      OrderEditDisabled: 'Order edit disabled',
      AddressEditEnabled: 'Address edit enabled',
      AddressEditDisabled: 'Address edit disabled',
      Placeholder: 'Status change',
      ItemUnitName: 'Account(s)',
    },
    OrderType: {
      Sale: 'Order',
      Purchase: 'Purchase',
    },
    ExperienceType: {
      Positive: 'Positive',
      Neutral: 'Neutral',
      Negative: 'Negative',
    },
    ConfirmationType: {
      Yes: 'Yes',
      No: 'No',
    },
    OrderStatus: {
      Pending: 'Pending',
      Active: 'Active',
      Completed: 'Completed',
      Canceled: 'Canceled',
      Archived: 'Archived',
    },
    ShippingMethod: {
      DeutchePostWarensendung: 'Deutsche Post Warensendung',
      DpdClassic: 'DPD Classic',
      ES_CartasInternacionalesHasta20: 'Cartas internacionales hasta 20 gr',
      ES_EmsPostalExpressInternational: 'Ems postal expres internacional',
      ES_CartasNacionalesDeMas20: 'Correos: cartas ordinarias',
      ES_CartasNacionalesHasta20: 'Cartas nacionales hasta 20 gr',
      ES_CartasPostalInternational: 'Cartas y tarjetas postales internacionales',
      ES_EconomyDeliveryFromAbroad: 'Envio economico desde el extranjero',
      ES_EconomyPacketInternational: 'Paquete internacional economy',
      ES_Otros: 'Otros (ver descripcion)',
      ES_ExpressDeliveryFromAbroad: 'Envio urgente desde el extranjero',
      ES_OtherInternational: 'Envio internacional economico',
      ES_PaqueteAzulDeMas2kg: 'Correos: Postal Expres',
      ES_PaqueteAzulHasta2kg: 'Paquete Azul (nacional) hasta 2 kg',
      ES_PaqueteInternacionalEconomico: 'Paquete Internacional Economico',
      ES_Pickup: 'España Pickup',
      ES_CartasInternacionalesDeMas20: 'Cartas internacionales de mas de 20 gr',
      ES_ExpeditedInternational: 'Envio internacional urgente',
      ES_ENTREGA_KIALA_8KG: 'Entrega a un Kiala point hasta 8 kg',
      DE_StandardversandAusDemAusland: 'Standard postage from outside DE',
      DE_VersicherterVersandInternational: 'Versicherter Versand  International',
      DE_SonstigeDomestic: 'Sonstige',
      DE_SonstigeInternational: 'Sonstige  International',
      DE_SonstigerVersandInternational: 'Sonstiger Versand (Siehe Artikelbeschreibung)',
      DE_SparversandAusDemAusland: 'Economy postage from outside DE',
      DE_SpecialDelivery: 'Sonderversand',
      DE_SpecialDispatch: 'Sonderversand (z.B. Mobel, KFZ)DE_DHLPackchen',
      DE_StandardDispatch: 'Unversicherter Versand Sonstiges',
      DE_StandardInternational: 'Standardpauschale fur internationalen Versand',
      ES_StandardDeliveryFromAbroad: 'Envio estandar desde el extranjero',
      DE_TrackedDeliveryFromAbroad: 'Versand mit Nachverfolgung aus dem Ausland',
      DE_UnversicherterExpressVersandInternational: 'Unversicherter Express - Versand',
      DE_UnversicherterVersand: 'Unversicherter Versand',
      DE_UnversicherterVersandInternational: 'Unversicherter Versand International Deutschland',
      DE_UPS: 'UPS deutschland',
      DE_UPSInternational: 'UPS International',
      DE_VersicherterExpressVersandInternational: 'Versicherter Express - Versand',
      DE_VersicherterSonderversandZBMobelKFZ: 'Versicherter Sonderversand (z.B. Mobel, KFZ)',
      DE_Writing: 'Einschreiben (Versand inkl. Einschreibengebuhr) Germany',
      ES_StandardInternational: 'Envio internacional estandar',
      FR_ChronopostClassicInternational: 'Chronopost Classic International',
      ES_Urgente: 'Otro courier 24 horas',
      FR_LaPosteColissimoEmballageInternational: 'La Poste - Colissimo Emballage International',
      FR_LaPosteColissimoInternational: 'La Poste - Colissimo International',
      FR_LaPosteInternationalEconomyCourier: 'La Poste - Courrier International Economique',
      FR_LaPosteInternationalPriorityCourier: 'La Poste - Courrier International Prioritaire',
      FR_LaPosteLetterMax: 'La Poste Lettre Max',
      FR_OtherInternational: 'Autre livraison internationale economique',
      FR_Pickup: 'Enlevement',
      FR_LaPosteColisEconomiqueInternational: 'La Poste - Colis Economique International',
      FR_PostOfficeLetter: 'Lettre',
      FR_PostOfficeLetterRecommended: 'Lettre recommandee',
      FR_RemiseEnMainPropre: 'Remise en main propre',
      FR_StandardInternational: 'Autre livraison internationale standard',
      FR_TrackedDeliveryFromAbroad: "Livraison a partir de l'etranger avec suivi de commande",
      FR_UPSExpressInternational: 'UPS Express',
      DE_Sonstige: 'Sonstige (Siehe Artikelbeschreibung)',
      FR_UPSStandard: 'UPS Standard',
      FR_PostOfficeLetterFollowed: 'Lettre avec suivi',
      ES_TrackedDeliveryFromAbroad: 'Envio desde el extranjero con seguimiento',
      FR_ExpeditedInternational: 'Autre livraison internationale express',
      FR_DHLInternational: 'DHL France',
      FR_KIALA_DELIVERY: 'Livraison en Relais Kiala',
      FR_AuteModeDenvoiDeColis: "Autre mode d'envoi de colis",
      FR_Autre: "Autre mode d'envoi de courrier",
      FR_Chrono10: 'Chrono 10',
      FR_Chrono13: 'Chrono 13',
      FR_Chrono18: 'Chrono 18',
      FR_Chronopost: 'Chronopost',
      FR_Ecopli: 'Ecopli',
      FR_ChronopostChronoRelais: 'Chronopost - Chrono Relais',
      FR_ChronopostExpressInternational: 'Chronopost Express International',
      FR_ChronopostPremiumInternational: 'Chronopost Premium International',
      FR_Colieco: 'Colieco',
      FR_ColiposteColissimo: 'Colissimo',
      FR_ColiposteColissimoDirect: 'Coliposte Colissimo Direct',
      FR_ColiposteColissimoRecommended: 'Colissimo Recommande',
      FR_DHLExpressEuropack: 'DHL Express Europack',
      FR_ChronoposteInternationalClassic: 'Chronoposte International Classic',
      DE_SonderversandZBMobelKFZ: 'Sonderversand (z.B. Mobel, KFZ)',
      BENL_EconomyDeliveryFromAbroad: 'Voordelige verzending uit het buitenland Belgium',
      DE_PaketInternational: 'Paketversand  International',
      DE_DHL2KGPaket: 'DHL 2kg Paket (nur fuer kurze Zeit)',
      DE_DHLPackchen: 'DHL Packchen',
      DE_DHLPackchenInternational: 'DHL Packchen International',
      DE_DHLPaeckchenPackstation: 'DHL Paeckchen Packstation',
      DE_DHLPaket: 'DHL Paket',
      DE_DHLPaketInternational: 'DHL Paket International',
      DE_DHLPaketIntlExpress: 'DHL Paket International Express',
      DE_DeutschePostWarensendungInternational: 'Deutsche Post Warensendung International',
      DE_DHLPaketPackstation: 'DHL Paket Packstation',
      DE_DHLPostpaketInternational: 'DHL Postpaket International',
      DE_DHLStarPaeckchen: 'DHL Star-Paeckchen',
      DE_DPBriefInternational: 'Deutsche Post Brief Intern.',
      DE_DPBuecherWarensendung: 'Deutsche Post Buecher-/Warensendung',
      DE_DPD: 'DPD Germany',
      DE_DPDInternational: 'DPD International Germany',
      DE_eBayDHLPaeckchen: 'eBay DHL Paeckchen',
      DE_DHLPostpaket: 'DHL Postpaket',
      DE_eBayDHLPaket24x7: 'eBay DHL Paket 24/7 (Abgabe und Lieferung an Packstation)',
      DE_DeutschePostWarensendung: 'Deutsche Post Warensendung',
      DE_DeutschePostBuchersendung: 'Deutsche Post Buchersendung',
      FR_UPSStandardAgainstRefund: 'Contre remboursement',
      CN_OthersInternational: 'Others (see description)',
      CN_PersonalDelivery: 'Seller door delivery (limited to the city)',
      CN_PostOfficeExpress: 'EMS Express',
      CN_RegularLogisticsInternational: 'Regular logistics',
      CN_RegularPackage: 'China Post Post Office',
      CN_StandardInternational: 'International Standard Fixed Freight',
      DE_DeutschePostByendung: 'Deutsche Post Byendung',
      CN_StandardMailingInternational: 'Standard Mailing',
      DE_COD: 'Nachnahme',
      DE_DeutschePostBrief: 'Deutsche Post Brief',
      DE_DeutschePostBriefInternational: 'Deutsche Post Brief International',
      DE_DeutschePostBriefIntlEcon: 'Deutsche Post Presse & Bucher Economy',
      DE_DeutschePostBriefIntlPri: 'Deutsche Post Presse & Bucher Priority',
      DE_DeutschePostBriefLandInternational: 'Deutsche Post Brief (Land)',
      DE_DeutschePostBriefLuftInternational: 'Deutsche Post Brief (Luft)',
      DE_BitteTreffenSieEineAuswahl: 'DE_BITTE_TREFFEN_SIE_EINE_AUSWAHL',
      DE_Pickup: 'Abholung',
      DE_eBayHermesPaketShop2Shop: 'eBay Hermes Paket Shop2Shop',
      DE_eBayHermesPaketSperrgutShop2Shop: 'eBay Hermes Paket Sperrgut Shop2Shop (Abgabe und Zustellung im Paketshop)',
      DE_IloxxStandard: 'iloxx Standard',
      DE_iLoxxStandardInternational: 'iloxx Standard International',
      DE_IloxxTransport: 'iloxx Transport',
      DE_IloxxTransportInternational: 'iloxx Transport International',
      DE_IloxxTransportXXL: 'iloxx Transport XXL',
      DE_iLoxxTransportXXLInternational: 'iloxx Transport XXL International',
      DE_IloxxUbernachtExpress: 'iloxx Express',
      DE_IloxxEuropaInternational: 'iloxx Europa',
      DE_iLoxxUbernachtExpressInternational: 'iloxx Ubernacht Express',
      DE_InsuredDispatch: 'Versicherter Versand',
      DE_InsuredExpressOrCourier: 'Versicherter Express- oder Kurierversand',
      DE_InsuredExpressOrCourierInternational: 'Versicherter Express- oder Kurierversand International',
      DE_InsuredSpecialDispatch: 'Versicherter Sonderversand',
      DE_NachnahmeVersandInklNachnahmegebuhr: 'Nachnahme (Versand inkl. Nachnahmegebuhr)',
      DE_Nachname: 'Nachnahme (inkl. aller Gebuehren)',
      DE_Paket: 'Paketversand',
      DE_IloxxWorldWideInternational: 'iloxx World Wide',
      DE_eBayHermesPaketShop2ShopKaeufer:
        'eBay Hermes Paket Shop2Shop (Kaeufer erhaelt E-Mail von Hermes bei Zustellung)',
      DE_HermesPaketVersichertInternational: 'Hermes Paket international (versichert)',
      DE_HermesPaketUnversichertInternational: 'Hermes Paket International (unversichert)',
      DE_Einschreiben: 'Einschreiben (inkl. aller Gebuehren)',
      DE_EinschreibenVersandInklEinschreibengebuhr: 'Einschreiben (Versand inkl. Einschreibengebuhr) Deutschland',
      DE_ExpeditedInternational: 'Expresspauschale fur internationalen Versand Deutschland',
      DE_Express: 'Expressversand',
      DE_ExpressInternational: 'Expressversand international',
      DE_ExpressOrCourier: 'Express- oder Kurierversand Deutschland',
      DE_ExpressOrCourierInternational: 'Express- oder Kurierversand International Deutschland',
      DE_HermesPaketVersichert: 'Hermes Paket (versichert)',
      DE_ExpressversandAusDemAusland: 'Express postage from outside DE',
      DE_GLSInternational: 'GLS International',
      DE_HermesPaket: 'Hermes Paket',
      DE_HermesPaketInternational: 'Hermes Paket International',
      DE_HermesPaketShop2Shop: 'Hermes Paket Shop2Shop',
      DE_HermesPaketShop2ShopKaeufer: 'Hermes Paket Shop2Shop (Kaeufer erhaelt E-Mail von Hermes bei Zustellung)',
      DE_HermesPaketSperrgut: 'Hermes Paket Sperrgut',
      DE_HermesPaketUnversichert: 'Hermes Paket (unversichert)',
      DE_GLS: 'GLS',
      FR_UPSStandardInternational: 'UPS Standard International',
      HK_DomesticSpecialShipping: 'Domestic special shipping Hong kong',
      TW_DwellingMatchPost: 'Home delivery',
      TW_ExpeditedInternationalFixedRate: 'International Express Fixed Rate for Taiwan',
      TW_ExpressMail: 'Express delivery',
      TW_FedExInternationalEconomy: 'FedEx International Economy',
      TW_FedExInternationalPriority: 'FedEx International Priority',
      TW_Other: 'Other',
      TW_OtherInternational: 'Other international shipping charges (see description)',
      TW_DwellingMatchCOD: 'Home delivery cash on delivery',
      TW_ParcelPost: 'Freight delivery',
      TW_SelfPickup: 'Face to face',
      TW_StandardInternationalFixedRate: 'International Standard Fixed Rate for Taiwan',
      TW_UnregisteredMail: 'Post office without registration',
      TW_UPSWorldwideExpedited: 'UPS Worldwide Expedited',
      TW_UPSWorldwideExpress: 'UPS Worldwide Express',
      TW_UPSWorldwideExpressPlus: 'UPS Worldwide Express Plus',
      TW_RegisteredMail: 'Post office plus registration',
      TW_CPInternationalRegisteredParcelPost: 'Post office aviation registration package',
      TW_CPInternationalParcelPost: 'Post office air parcel',
      PL_StandardDeliveryFromAbroad: 'Przesylka z zagranicy - standardowa',
      PL_TrackedDeliveryFromAbroad: 'Przesylka z zagranicy - ze sledzeniem',
      PL_UPSInternational: 'UPS Poland  International',
      SG_Delivery: 'Singapur Pickup',
      SG_DomesticNonStandardMail: 'Domestic non standard mail',
      SG_DomesticSpeedpostIslandwide: 'Domestic Speedpost Islandwide',
      SG_DomesticStandardMail: 'Domestic standard mail',
      TW_CPInternationalRegisteredLetterPost: 'Post office aviation registered packet',
      SG_InternationalCourier: 'International courier (DHL, FedEx, UPS)',
      SG_InternationalStandardMail: 'International standard mail',
      SG_LocalCourier: 'Local courier',
      SG_LocalPickUpOnly: 'Local pick up only',
      TW_COD: 'Post office cash on delivery',
      TW_CPInternationalEMS: 'Post Office International Express EMS',
      TW_CPInternationalLetterPost: 'Post Office Airbag',
      TW_CPInternationalOceanShippingParcel: 'Post office international land and water package',
      SG_InternationalExpressMailService: 'International Express Mail Service (EMS)',
      PL_Pickup: 'Polska Pickup',
      CN_Others: 'Other (see item description)',
      HK_DomesticRegularShipping: 'Domestic regular shipping Hong kong',
      PL_InternationalSpecial: 'Przesylka zagraniczna - priorytetowa',
      PL_ExpressDeliveryFromAbroad: 'Przesylka z zagranicy - ekspresowa',
      IE_SellersStandardRate: "Ireland Seller's standard rate",
      IE_SellersStandardRateInternational: "Seller's Standard International Rate",
      IE_StandardDeliveryFromAbroad: 'Standard delivery from abroad',
      IE_StandardInternationalFlatRatePostage: "Ireland Standard Int'l Flat Rate Postage",
      IE_SwiftPostExpressInternational: 'SwiftPost Express',
      IE_SwiftPostInternational: 'SwiftPost International',
      IE_SwiftPostNational: 'SwiftPost National',
      IE_RegisteredPost: 'Registered Post',
      IE_TrackedDeliveryFromAbroad: 'Tracked delivery from abroad',
      IN_Courier: 'Local - Courier',
      IN_ExpeditedInternational: 'International - Expedited',
      IN_Express: 'National - Express',
      IN_FlatRateCOD: 'Flat Rate COD',
      IN_LocalCOD: 'Local - COD',
      IN_NationalCOD: 'National - COD',
      IN_OtherInternational: 'International - other',
      IN_BuyerPicksUpAndPays: 'Buyer picks up and pays',
      IN_Regular: 'National - Regular',
      IE_OtherInternationalPostage: "Ireland Other Int'l Postage (see description)",
      IE_OtherCourier: 'Ireland Other courier',
      HK_InternationalRegularShipping: 'International regular shipping',
      HK_InternationalSpecialShipping: 'International special shipping',
      HK_LocalCourier: 'Hong Kong Local courier',
      HK_LocalPickUpOnly: 'Hong Kong Local pick up only',
      IE_CollectionInPerson: 'Collection in person - Ireland',
      IE_CollectionInPersonInternational: 'International collection in person',
      IE_EconomyDeliveryFromAbroad: 'Economy delivery from abroad',
      IE_OtherCourierInternational: 'Other courier or delivery service Ireland',
      IE_EconomySDSCourier: 'Economy SDS Courier',
      IE_EMSSDSCourier: 'EMS SDS Courier',
      IE_EMSSDSCourierInternational: 'EMS SDS Courier International',
      IE_ExpeditedInternationalFlatRatePostage: "Expedited Int'l Flat Rate Postage Ireland",
      IE_ExpressDeliveryFromAbroad: 'Express delivery from abroad',
      IE_FirstClassLetterService: 'First Class Letter Service',
      IE_InternationalEconomyService: 'International Economy Service',
      UK_OtherCourier: 'Other Courier',
      IE_InternationalPriorityService: 'International Priority Service',
      IE_EconomySDSCourierInternational: 'Economy SDS Courier International',
      PL_InternationalRegular: 'Przesylka zagraniczna - zwykla',
      IN_StandardInternational: 'International - Standard',
      IT_ExpressCourier: 'Corriere espresso',
      NL_GLSBusinessInternational: 'GLS (Business only)',
      NL_Other: 'Andere verzendservice',
      NL_OtherInternational: 'Andere internationale verzending (zie beschrijving)',
      NL_ParcelPost: 'Pakketpost',
      NL_Pickup: 'Nederland Pickup',
      NL_RegisteredMail: 'Verzending met ontvangstbevestiging',
      NL_StandardDelivery: 'Standaardverzending',
      NL_FedExInternational: 'FedEx Netherlands',
      NL_StandardDeliveryFromAbroad: 'Standaardverzending uit het buitenland',
      NL_TPGPostTNTInternational: 'TPG Post/TNT Netherlands',
      NL_TrackedDeliveryFromAbroad: 'Verzending met internationale tracering uit het buitenland',
      NL_UPSInternational: 'UPS Netherland International',
      PL_DHLInternational: 'DHL Poland',
      PL_DomesticRegular: 'Domestic Regular shipping Poland',
      PL_DomesticSpecial: 'Domestic Special shipping Poland',
      PL_EconomyDeliveryFromAbroad: 'Przesylka z zagranicy - ekonomiczna',
      NL_StandardInternational: 'Vaste kosten standaard internationale verzending',
      IT_ExpeditedInternational: 'Spedizione internazionale celere a prezzo fisso',
      NL_ExpressDeliveryFromAbroad: 'Verzending per expresse uit het buitenland',
      NL_EconomyDeliveryFromAbroad: 'Voordelige verzending uit het buitenland',
      IT_ExpressPackageMaxi: 'Paccocelere Maxi',
      IT_InsuredMail: 'Posta assicurata',
      IT_MailRegisteredLetter: 'Posta raccomandata',
      IT_MailRegisteredLetterWithMark: 'Posta raccomandata con contrassegno',
      IT_OtherInternational: 'Altre spedizioni internazionali (vedi descrizione)',
      IT_Pickup: 'Italia Pickup',
      IT_PriorityMail: 'Posta prioritaria',
      NL_ExpeditedInternational: 'Vaste kosten versnelde internationale verzending',
      IT_QuickMail: 'Posta celere',
      IT_QuickPackage3: 'Pacco celere 3',
      IT_RegularMail: 'Posta ordinaria',
      IT_RegularPackage: 'Pacco ordinario',
      IT_StandardInternational: 'Spedizione internazionale standard a prezzo fisso',
      IT_TrackedDeliveryFromAbroad: 'Spedizione tracciata dall estero',
      NL_DHLInternational: 'DHL Netherlands',
      NL_DPDGBRInternational: 'DPD International Netherlands',
      IT_QuickPackage1: 'Pacco celere 1',
      CN_OtherInternational: 'Other international shipping charges (see note)',
      CN_ExpressDeliverySameCity: 'City Express',
      AU_RegisteredParcelPostPrepaidSatchel3kg: 'Registered Parcel Post Prepaid Satchel 3kg',
      AU_RegisteredParcelPostPrepaidSatchel500g: 'Registered Parcel Post Prepaid Satchel 500g',
      AU_RegisteredParcelPostPrepaidSatchel5kg: 'Registered Parcel Post Prepaid Satchel 5kg',
      AU_RegisteredSmallParcel: 'Registered Small Parcel',
      AU_Regular: 'Regular',
      AU_RegularParcelWithTracking: 'Regular Parcel With Tracking',
      AU_RegularParcelWithTrackingAndSignature: 'Regular Parcel With Tracking And Signature',
      AU_RegularWithInsurance: 'Regular with Insurance',
      AU_SeaMailInternational: 'Sea Mail',
      AU_SmallParcels: 'Small Parcels',
      AU_SmallParcelWithTracking: 'Small Parcel With Tracking',
      AU_SmallParcelWithTrackingAndSignature: 'Small Parcel With Tracking And Signature',
      AU_StandardDelivery: 'Standard delivery (1-6 business days) in Australia',
      AU_StandardDeliveryFromOutsideAU: 'Standard Delivery From Outside AU',
      AU_StandardInternational: 'Standard International Flat Rate Postage',
      AU_RegisteredParcelPost: 'Registered Parcel Post',
      AU_Registered: 'Registered',
      AU_PrePaidParcelPostSatchels5kg: 'PrePaid Parcel Post Satchels 5kg',
      AU_PrePaidParcelPostSatchels500g: 'PrePaid Parcel Post Satchels 500g',
      AU_Other: 'Australia Other',
      AU_OtherInternational: 'Other international postage',
      AU_Pickup: 'Australia Pickup',
      AU_PrePaidExpressPostInternationalBox10kg: 'PrePaid Express Post International Box 10kg',
      AU_PrePaidExpressPostInternationalBox20kg: 'PrePaid Express Post International Box 20kg',
      AU_PrePaidExpressPostInternationalBox5kg: 'PrePaid Express Post International Box 5kg',
      AU_PrePaidExpressPostInternationalEnvelopeB4: 'PrePaid Express Post International Envelope B4',
      BEFR_DePostInternational: 'De Post Belgium (French)',
      AU_PrePaidExpressPostInternationalEnvelopeC5: 'PrePaid Express Post International Envelope C5',
      AU_PrePaidExpressPostInternationalSatchels3kg: 'PrePaid Express Post International Satchels 3kg',
      AU_PrePaidExpressPostPlatinum3kg: 'PrePaid Express Post Platinum 3kg',
      AU_PrePaidExpressPostPlatinum500g: 'PrePaid Express Post Platinum 500g',
      AU_PrePaidExpressPostSatchel3kg: 'PrePaid Express Post Satchel 3kg',
      AU_PrePaidExpressPostSatchel500g: 'PrePaid Express Post Satchel 500g',
      AU_PrePaidExpressPostSatchel5kg: 'PrePaid Express Post Satchel 5kg',
      AU_PrePaidParcelPostSatchels3kg: 'PrePaid Parcel Post Satchels 3kg',
      AU_PrePaidExpressPostInternationalSatchels2kg: 'PrePaid Express Post International Satchels 2kg',
      BEFR_DHLInternational: 'DHL Belgium (French)',
      BEFR_EconomyDeliveryFromAbroad: "Livraison economique a partir de l'etranger",
      BEFR_ExpeditedInternational: 'Frais fixes pour livraison internationale express',
      BEFR_LivraisonStandardPrixforFaitaire: 'Livraison standard - prix forfaitaire',
      BEFR_Other: 'Autre service de livraison',
      BEFR_OtherInternational: 'Autres livraisons internationales (voir description)',
      BEFR_PaquetKilopost: 'Paquet Kilopost (2 jours ouvrables)',
      BEFR_PaquetKilopostInternationale: 'Paquet Kilopost Internationale',
      BEFR_ParcelPost: 'Colis',
      BEFR_Pickup: 'Belgium Pickup',
      BEFR_LivraisonStandardInternationalePrixforFaitaire: 'Livraison standard internationale - prix forfaitaire',
      BEFR_PointRetraitKiala: 'Point retrait Kiala (1 a 4 jours ouvrables)',
      BEFR_PostInternationalStandard: 'La Poste - livraison standard',
      BEFR_PriorityDelivery: 'Livraison express',
      BEFR_RegisteredMail: 'Courrier en recommande',
      BEFR_StandardDelivery: "Livraison standard a partir de l'etranger",
      BEFR_StandardDeliveryFromAbroad: "Livraison standard a partir de l'etranger International",
      BEFR_StandardInternational: 'Frais de livraison internationale fixes',
      BEFR_Taxipost: 'Taxipost (express)',
      BEFR_PostInternationalRegistered: 'La Poste - envoi recommande',
      AU_Freight: 'Freight delivery in Australia. Used for heavy and bulky items.',
      BEFR_LivraisonSecuriseInternational: 'Livraison securisee International',
      BEFR_LivraisonExpressPrixforFaitaire: 'Livraison express - prix forfaitaire',
      BEFR_ExpressDeliveryFromAbroad: "Livraison express a partir de l'etranger",
      BEFR_ExpressTaxipost: 'BEFR_Express Taxipost',
      BEFR_FedExInternational: 'FedEx Belgium (French)',
      BEFR_LaPosteCertifiedShipping: 'La Poste - envoi recommande (1 jour ouvrable)',
      BEFR_LaPosteenvoieNONPRIOR: 'La Poste envoie NON PRIOR',
      BEFR_LaPosteenvoiePRIOR: 'La Poste envoie PRIOR',
      BEFR_LaPosteenvoieRecommande: 'La Poste envoie recommande',
      BEFR_LivraisonSecurise: 'Livraison securisee',
      BEFR_LaPosteEnvoiNONPRIOR: 'La Poste envoi NON PRIOR (2 jours ouvrables)',
      BEFR_LaPosteEnvoiRecommande: 'La Poste envoi recommande (1 jour ouvrable)',
      BEFR_LaPosteInternational: 'La Poste (French)',
      BEFR_LaPosteStandardShipping: 'La Poste - livraison standard (1 a 2 jours ouvrables)',
      BEFR_LaPosteTaxipost24h: 'La Poste - Taxipost 24h (1 jour ouvrable)',
      BEFR_LaPosteTaxipostLLS: 'La Poste - Taxipost LLS (2 jours ouvrables)',
      BEFR_LaPosteTaxipostSecur: 'Autres livraisons',
      BEFR_LivraisonExpressInternationalePrixforFaitaire: 'Livraison express internationale - prix forfaitaire',
      BEFR_LaPosteEnvoiPRIOR: 'La Poste envoi PRIOR (1 jour ouvrable)',
      AU_ExpressWithInsurance: 'Express with Insurance',
      AU_ExpressPostSatchel500g: 'eBay Australia Post Express Post 500g Satchel',
      AU_ExpressPostSatchel3kg: 'eBay Australia Post Express Post 3kg Satchel',
      DE_IntlEconomyShippingFromGC:
        'Sparpreisversand weltweit von China/Hong Kong/Taiwan to worldwide (11 bis 35 Werktage)',
      DE_IntlStandardShippingFromGC:
        'Standardversand weltweit von China/Hong Kong/Taiwan to worldwide (7 bis 19 Werktage)',
      DE_StandardShippingFromGC: 'Standardversand weltweit von China/Hong Kong/Taiwan to worldwide',
      DE_IntlExpeditedShippingFromGC:
        'Expressversand weltweit von China/Hong Kong/Taiwan to worldwide (2 bis 7 Werktage)',
      DE_ExpeditedShippingFromGC: 'Expressversand weltweit von China/Hong Kong/Taiwan to worldwide',
      AT_BitteTreffenSieEineAuswahl: 'AT_BITTE_TREFFEN_SIE_EINE_AUSWAHL',
      AT_COD: 'Nachnahme Österreich',
      DE_EconomyShippingFromGC: 'Sparpreisversand weltweit von China/Hong Kong/Taiwan to worldwide',
      AT_EconomyDeliveryFromAbroad: 'Österreich Sparversand aus dem Ausland',
      AT_ExpeditedInternational: 'Expresspauschale fur internationalen Versand Österreich',
      AT_ExpressDeliveryFromAbroad: 'Expressversand aus dem Ausland Österreich',
      AT_ExpressOrCourier: 'Express- oder Kurierversand Österreich',
      AT_ExpressOrCourierInternational: 'Express- oder Kurierversand International  Österreich',
      AT_InsuredDispatch: 'Versicherter Versand Österreich',
      AT_InsuredExpressOrCourier: 'Versicherter Express- oder Kurierversand Österreich',
      AT_InsuredExpressOrCourierInternational: 'Versicherter Express- oder Kurierversand International Österreich',
      AT_EinschreibenVersandInklEinschreibengebuhr: 'Einschreiben (Versand inkl. Einschreibengebuhr) Österreich',
      AT_InsuredSpecialDispatch: 'Versicherter Sonder-/Speditionsversand Österreich (z.B. Mobel, KFZ)',
      PromotionalShippingMethod: 'Versand zum Sonderpreis',
      DE_bopis: 'Kostenlos Click & Collect',
      iLoxx: 'iLoxx',
      DE_SonstigeInternationa: 'Economy Int Versand',
      DE_HermesPackchen: 'Hermes Päckchen',
      DE_DHLAlterssichtprüfung18: 'DHL Paket mit Alterssichtprüfung ab 18 Jahre',
      DE_GLSPaket: 'GLS Paket',
      Hermes: 'Hermes',
      DE_DPDClassic: 'DPD Classic',
      eBayPlus: 'eBayPlus',
      DE_DPDExpress: 'DPD Express',
      DE_UPSExpress: 'UPS Express',
      DE_FedExIntlEconomy: 'FedEx International Economy',
      DE_TntIntlExp: 'TNT International Express',
      DE_MiniPakFromAbroad: 'MiniPak aus dem Ausland',
      DE_TrakPakFromAbroad: 'TrakPak aus dem Ausland',
      DE_DHLExpressWorldwide: 'DHL Express Worldwide',
      DE_DHLFreightDomestic: 'DHL Freight Domestic',
      DE_UPSStandard: 'UPS Standard',
      BEFR_TNTInternational: 'TNT',
      AT_InsuredSpecialDispatchInternational: 'Versicherter Sonderversand  International (z.B. Sperrgut, KFZ)',
      AT_OtherInternationalShipping: 'Österreich Sonstiger Versand (Siehe Artikelbeschreibung)',
      AU_AustralianAirExpressFlatRate5kg: 'Australian Air Express Flat Rate 5 kg',
      AU_AustralianAirExpressMetro15kg: 'Australian Air Express Metro 15 kg',
      AU_Courier: 'Courier',
      AU_eBayAusPost3kgFlatRateSatchel: 'eBay/Australian Post 3 kg Flat Rate Satchel',
      AU_eBayAusPost500gFlatRateSatchel: 'eBay/Australian Post 500 g Flat Rate Satchel',
      AU_EconomyAirInternational: 'Economy Air',
      AU_EconomyDeliveryFromOutsideAU: 'Economy delivery from outside Australia',
      AU_AustralianAirExpressFlatRate3kg: 'Australian Air Express Flat Rate 3 kg',
      AU_EMSInternationalCourierDocuments: 'EMS International Courier - Documents',
      AU_ExpeditedDeliveryFromOutsideAU: 'Expedited delivery from outside Australia',
      AU_ExpeditedInternational: 'Expedited international flat rate postage',
      AU_Express: 'Express',
      AU_ExpressCourierInternational: 'Express Courier International',
      AU_ExpressDelivery: 'Express delivery (1-3 business days) in Australia',
      AU_ExpressPostInternational: 'Express Post International',
      AU_ExpressPostInternationalDocuments: 'Express Post International - Documents',
      AU_EMSInternationalCourierParcels: 'EMS International Courier - Parcels',
      AT_NachnahmeVersandInklNachnahmegebuhr: 'Nachnahme Austria',
      AU_AustralianAirExpressFlatRate1kg: 'Australian Air Express Flat Rate 1 kg',
      AU_AusPostRegisteredPostInternationalPaddedBag500g:
        'Australia Post Registered Post International Padded Bag 500 g',
      AT_Pickup: 'Österreich Pickup',
      AT_Sonstige: 'Österreich Sonstige (Siehe Artikelbeschreibung)',
      AT_SonstigerVersandInternational: 'Sonstiger Versand International',
      AT_SpecialDispatch: 'Österreich Sonder-/Speditionsversand (z.B. Mobel, KFZ)',
      AT_SpecialDispatchInternational: 'Sonderversand  International (z.B. Sperrgut, KFZ)',
      AT_StandardDeliveryFromAbroad: 'Österreich Standardversand aus dem Ausland',
      AT_StandardDispatch: 'Standardversand (unversichert)',
      AU_AusPostRegisteredPostInternationalParcel: 'Australia Post Registered Post International Parcel',
      AT_StandardInternational: 'Standardpauschale für internationalen Versand',
      AT_UnversicherterExpressVersandInternational: 'Unversicherter Express Versand International',
      AT_UnversicherterVersandInternational: 'Unversicherter Versand International ',
      AT_VersicherterExpressVersandInternational: 'Versicherter Express Versand International',
      AT_VersicherterVersandInternational: 'Versicherter Versand International',
      AT_Writing: 'Einschreiben (Versand inkl. Einschreibengebuhr) Austria',
      AU_AirMailInternational: 'Air Mail',
      AU_AusPostRegisteredPostInternationalPaddedBag1kg: 'Australia Post Registered Post International Padded Bag 1 kg',
      AT_TrackedDeliveryFromAbroad: 'Versand mit Nachverfolgung aus dem Ausland Österreich',
      BEFR_TPGPostTNTInternational: 'TPG Post/TNT ',
      BEFR_TrackedDeliveryFromAbroad: "Belgium Livraison a partir de l'etranger avec suivi de commande",
      BEFR_UPSInternational: 'UPS International Belgium (French)',
      CAFR_PostXpresspostInternational: 'Postes Canada, Xpresspost - International',
      CAFR_PostXpresspostUSA: 'Postes Canada, Xpresspost - E.U.',
      CAFR_PurolatorInternational: 'Purolator International',
      CAFR_SmallPacketsInternational: 'Petits paquets - International',
      CAFR_SmallPacketsInternationalAir: 'Petits paquets - International, courrier-avion',
      CAFR_SmallPacketsInternationalGround: 'Petits paquets - International, courrier-surface',
      CAFR_StandardDelivery: 'Normes de livraison postale',
      CAFR_PostXpresspost: 'Postes Canada, Xpresspost',
      CAFR_StandardInternational: 'Expedition standard - International, tarif fixe',
      CAFR_UPSExpeditedCanada: 'UPS Expedited Canada (French)',
      CAFR_UPSExpeditedUnitedStates: 'UPS Expedited Etats-Unis',
      CAFR_UPSExpressCanada: 'UPS Express Saver',
      CAFR_UPSExpressSaverCanada: 'UPS Express Saver Canada (French)',
      CAFR_UPSExpressUnitedStates: 'UPS Express Etats-Unis',
      CAFR_UPSStandardCanada: 'UPS Standard au Canada',
      CAFR_UPSStandardUnitedStates: 'UPS Standard aux Etats-Unis',
      CAFR_UPS3DaySelectUnitedStates: '3Day Select aux Etats-Unis',
      CAFR_UPSWorldWideExpedited: 'UPS Worlwide Expedited',
      CAFR_PostUSALetterPost: 'Postes Canada, Poste aux lettres - E.U.',
      CAFR_PostSmallPacketsUSAAir: 'Postes Canada, Petits paquets - E.U. par avion',
      CA_UPSStandardCanada: 'UPS Standard Canada',
      CA_UPSStandardUnitedStates: 'UPS Standard United States',
      CA_UPSWorldWideExpedited: 'UPS Worldwide Expedited Canada',
      CA_UPSWorldWideExpress: 'UPS Worldwide Express Canada',
      CAFR_ExpeditedDelivery: 'Expedition acceleree',
      CAFR_ExpeditedInternational: 'Expedition acceleree - International, tarif fixe',
      CAFR_OtherInternational: "Autres services d'expedition internationale (voir description)",
      CAFR_PostSmallPacketsUSAGround: 'Postes Canada, Petits paquets - E.U. service de surface',
      CAFR_PostExpeditedParcel: 'Postes Canada, Colis acceleres',
      CAFR_PostInternationalLetterPost: 'Postes Canada, Poste aux lettres - International',
      CAFR_PostInternationalParcelAir: 'Postes Canada, Colis-avion - International',
      CAFR_PostInternationalParcelSurface: 'Postes Canada, Colis international de surface',
      CAFR_PostLettermail: 'Postes Canada, Poste-lettres',
      CAFR_PostPriorityCourier: 'Postes Canada, Messageries prioritaires',
      CAFR_PostRegularParcel: 'Postes Canada, Colis standard',
      CAFR_PostSmallPacketsUSA: 'Postes Canada, Petits paquets - E.U.',
      CAFR_PostExpeditedParcelUSA: 'Postes Canada, Colis acceleres - E.U.',
      CA_UPSExpressUnitedStates: 'UPS Express United States',
      CAFR_UPSWorldWideExpress: 'UPS Worlwide Express',
      CH_COD: 'Schweiz Nachnahme',
      CH_StandardDispatchAPost: 'Standardversand (A-Post)',
      CH_StandardDispatchBPost: 'Standardversand (B-Post)',
      CH_StandardInternational: 'Standardpauschale fuer internationalen Versand',
      CH_StandardversandAPostPriority: 'Standardversand (A-Post/Priority)',
      CH_StandardversandBPostEconomy: 'Standardversand (B-Post/Economy)',
      CH_TrackedDeliveryFromAbroad: 'Versand mit Nachverfolgung aus dem Ausland Schweiz',
      CH_UnversicherterVersand: 'Unversicherter Versand Schweiz',
      CH_StandardDeliveryFromAbroad: 'Standardversand aus dem Ausland',
      CH_UrgentSendungenInternational: 'URGENT Sendungen',
      CH_VersicherterVersand: 'Versicherter Versand Schweiz',
      CH_Writing: 'Einschreiben (Versand inkl. Einschreibengebuhr) Shwitzerland',
      CN_CODInternational: 'COD (same city only)',
      CN_DeliveryCompanyExpress: 'Logistics company express',
      CN_EMSInternational: 'EMS',
      CN_ExpeditedInternational: 'International courier fixed freight',
      CN_ExpressDeliveryOtherCities: 'Off-site delivery',
      CH_VersicherterSonderversandZBSperrgutKFZ: 'Versicherter Sonderversand (z.B. Sperrgut, KFZ)',
      CH_BitteTreffenSieEineAuswahl: 'CH_BITTE_TREFFEN_SIE_EINE_AUSWAHL',
      CH_SpecialDispatch: 'Sonder-/Speditionsversand (z.B. Mobel, KFZ)',
      CH_SonstigerVersandInternational: 'Schweiz Sonstiger -internationaler Versand (Siehe Artikelbeschreibung)',
      CH_EconomyDeliveryFromAbroad: 'Sparversand aus dem Ausland',
      CH_EconomySendungenInternational: 'ECONOMY Sendungen',
      CH_EinschreibenVersandInklEinschreibengebuhr: 'Einschreiben (Versand inkl. Einschreibengebuhr) Schweiz',
      CH_ExpeditedInternational: 'Expresspauschale fur internationalen Versand Schweiz',
      CH_ExpressDeliveryFromAbroad: 'Expressversand aus dem Ausland Schweiz',
      CH_ExpressOrCourier: 'Express- oder Kurierversand Schweiz',
      CH_ExpressOrCourierInternational: 'Express- oder Kurierversand International  Schweiz',
      CH_SonstigerVersandSieheArtikelbeschreibung: 'Schweiz Sonstiger Versand (Siehe Artikelbeschreibung)',
      CH_InsuredDispatch: 'Versicherter Versand (z.B. Assurance/Fragile)',
      CH_InsuredExpressOrCourierInternational: 'Versicherter Express- oder Kurierversand International Schweiz',
      CH_InsuredSpecialDispatch: 'Versicherter Sonder-/Speditionsversand (z.B. Mobel, KFZ)',
      CH_NachnahmeVersandInklNachnahmegebuhr: 'Schweiz Nachnahme (Versand inkl. Nachnahmegebuhr)',
      CH_Pickup: 'Schweiz Pickup',
      CH_PrioritySendungenInternational: 'PRIORITY Sendungen',
      CH_SonderversandZBSperrgutKFZ: 'Sonderversand (z.B. Sperrgut, KFZ)',
      CH_Sonstige: 'Schweiz Sonstige (Siehe Artikelbeschreibung)',
      CH_InsuredExpressOrCourier: 'Versicherter Express- oder Kurierversand Schweiz',
      CN_FastPostOffice: 'China Post Express Mail',
      CA_UPSExpressSaverCanada: 'UPS Express Saver Canada',
      CA_UPSExpeditedUnitedStates: 'UPS Expedited United States',
      BENL_LaPosteInternational: 'La Poste International (French)',
      BENL_Other: 'Other Shipping Service',
      BENL_OtherInternational: 'Other International Shipping Services',
      BENL_OtherShippingMethods: 'Andere verzending',
      BENL_ParcelPost: 'Parcel Post',
      BENL_Pickup: 'Afhalen',
      BENL_PostInternationalRegistered: 'De Post - aangetekende zending',
      BENL_KilopostPakjeInternational: 'Kilopost pakje Internationaal',
      BENL_PostInternationalStandard: 'De Post - standaardverzending',
      BENL_RegisteredMail: 'Registered Mail',
      BENL_StandardDelivery: 'Belgium Standard Delivery',
      BENL_StandardDeliveryFromAbroad: 'Belgium Standaardverzending uit het buitenland',
      BENL_StandardInternational: 'Standard International',
      BENL_Taxipost: 'Taxipost (express) Belgium',
      BENL_TaxipostExpressverzending: 'Taxipost expressverzending',
      BENL_TNTInternational: 'TNT Belgium',
      BENL_PriorityDelivery: 'Priority Delivery',
      BENL_TPGPostTNTInternational: 'TPG Post/TNT Belgium',
      BENL_KilopostPakje: 'Kilopost pakje (2 werkdagen)',
      BENL_FedExInternational: 'FedEx Belgium (Dutch)',
      BENL_DePostCertifiedShipping: 'De Post - aangetekende zending (1 werkdag)',
      BENL_DePostInternational: 'De Post Belgium (Dutch)',
      BENL_DePostStandardShipping: 'De Post - standaardverzending (1 tot 2 werkdagen)',
      BENL_DePostTaxipost24h: 'De Post - Taxipost 24u (1 werkdag)',
      BENL_DePostTaxipostLLS: 'De Post - Taxipost LLS (2 werkdagen)',
      BENL_DePostTaxipostSecur: 'De Post - Taxipost Secur (1 werkdag)',
      BENL_DePostZendingAangetekend: 'De Post zending - aangetekend (1 werkdag)',
      BENL_KialaAfhaalpunt: 'Kiala afhaalpunt (1 tot 4 werkdagen)',
      BENL_DePostZendingAangetekendInternational: 'De Post zending - aangetekend',
      BENL_DePostZendingNONPRIORInternational: 'De Post zending - NON PRIOR',
      BENL_DePostZendingPRIOR: 'De Post zending - PRIOR (1 werkdag)',
      BENL_DePostZendingPRIORInternational: 'De Post zending - PRIOR',
      BENL_DHLInternational: 'DHL Belgium (Dutch)',
      BENL_ExpeditedInternational: 'Expedited International',
      BENL_ExpressDeliveryFromAbroad: 'Express verzending uit het buitenland',
      BENL_DePostZendingNONPRIOR: 'De Post zending - NON PRIOR (2 werkdagen)',
      CA_UPSExpressCanada: 'UPS Express Canada',
      BENL_TrackedDeliveryFromAbroad: 'Verzending uit het buitenland met internationale tracking',
      BENL_VasteKostenStandaardVerzending: 'Vaste kosten standaard verzending',
      CA_PostSmallPacketsUSAGround: 'Canada Post Small Packets - USA - Ground',
      CA_PostTrackedPacketsInternational: 'Canada Post Tracked Packet - International',
      CA_PostTrackedPacketsUSA: 'Canada Post Tracked Packet - USA',
      CA_PostUSALetterPost: 'Canada Post USA Letter-post',
      CA_PostXpresspost: 'Canada Post Xpresspost',
      CA_PostXpresspostInternational: 'Canada Post Xpresspost - International',
      CA_PostXpresspostUSA: 'Canada Post Xpresspost - USA',
      CA_PostSmallPacketsUSAAir: 'Canada Post Small Packets - USA - Air',
      CA_PriorityWorldwide: 'Canada Post Priority Worldwide',
      CA_SmallPacketsInternational: 'Small Packets - International',
      CA_SmallPacketsInternationalAir: 'Small Packets - International - Air',
      CA_SmallPacketsInternationalGround: 'Small Packets - International - Ground',
      CA_StandardDelivery: 'Standard Delivery',
      CA_StandardInternational: 'Standard International Flat Rate Shipping',
      CA_UPS3DaySelectUnitedStates: 'UPS 3 Day Select United States',
      CA_UPSExpeditedCanada: 'UPS Expedited Canada',
      CA_PurolatorInternational: 'Canada Purolator International',
      BENL_UPSInternational: 'UPS  International belgium (Dutch)',
      CA_PostSmallPacketsUSA: 'Canada Post Small Packets - USA',
      CA_PostPriorityCourier: 'Canada Post Priority Courier',
      BENL_VasteKostenVersneldeVerzending: 'Vaste kosten versnelde verzending',
      BENL_VerzekerdeVerzending: 'Verzekerde verzending ',
      BENL_VerzekerdeVerzendingInternational: 'Verzekerde verzending  International',
      CA_ExpeditedDelivery: 'Expedited delivery',
      CA_ExpeditedInternational: 'Expedited International Flat Rate Shipping',
      CA_Freight: 'Freight',
      CA_OtherInternational: 'Other International Shipping (see description)',
      CA_PostRegularParcel: 'Canada Post Regular Parcel',
      CA_Pickup: 'Pickup Only Service',
      CA_PostExpeditedParcelUSA: 'Canada Post Expedited Parcel - USA',
      CA_PostInternationalLetterPost: 'Canada Post International Letter-post',
      CA_PostInternationalParcelAir: 'Canada Post International Parcel - Air',
      CA_PostInternationalParcelSurface: 'Canada Post International Parcel - Surface',
      CA_PostLettermail: 'Canada Post Lettermail',
      CA_PostLightPacketInternational: 'Canada Post Light Packet International',
      CA_PostLightPacketUSA: 'Canada Post Light Packet USA',
      CA_PostExpeditedParcel: 'Canada Post Expedited Parcel',
      UK_RoyalMailHMForcesMailInternational: 'Royal Mail HM Forces Mail',
      UK_CollectDropAtStoreDeliveryToDoor: 'Collect+ : drop at store-delivery to door',
      UK_CollectInPerson: 'Collection in Person - United Kingdom',
      UK_CollectInPersonInternational: 'Collect in person',
      UK_ExpeditedInternationalFlatRatePostage: "Expedited Int'l Flat Rate Postage United Kingdom",
      UK_RoyalMailAirsureInternational: 'Royal Mail Airsure',
      UK_RoyalMailFirstClassRecorded: 'Royal Mail 1st Class Signed For',
      UK_RoyalMailFirstClassStandard: 'Royal Mail 1st Class',
      UK_RoyalMailInternationalSignedFor: 'Royal Mail International Signed-for',
      UK_RoyalMailNextDay: 'Royal Mail Tracked 24',
      UK_RoyalMailSecondClassRecorded: 'Royal Mail 2nd Class Signed For',
      UK_RoyalMailAirmailInternational: 'Royal Mail Airmail',
      UK_RoyalMailSecondClassStandard: 'Royal Mail 2nd Class',
      UK_RoyalMailSpecialDelivery9am: 'Royal Mail Special Delivery 9:00 am',
      UK_RoyalMailSpecialDeliveryNextDay: 'Royal Mail Special Delivery; 1:00 pm',
      UK_RoyalMailSurfaceMailInternational: 'Royal Mail Surface Mail',
      UK_RoyalMailTracked: 'Royal Mail Tracked 48',
      UK_SellersStandardInternationalRate: 'Sellers Standard International Rate',
      UK_SellersStandardRate: "Seller's Standard Rate",
      UK_StandardInternationalFlatRatePostage: "Standard Int'l Flat Rate Postage",
      UK_RoyalMailSpecialDelivery: 'Royal Mail Special Delivery',
      UK_EconomyShippingFromOutside: 'Economy postage from outside UK',
      UK_ParcelForceIreland24International: 'Parcelforce Ireland 24',
      UK_ParcelForceIntlExpress: 'Parcelforce Global Express',
      UK_ExpeditedShippingFromOutside: 'Express postage from outside UK',
      UK_IntlTrackedPostage: 'International tracked postage',
      UK_myHermesDoorToDoorService: 'Hermes Tracked',
      UK_OtherCourier24: 'Other 24 Hour Courier',
      UK_OtherCourier3Days: 'Other Courier 3 days',
      UK_OtherCourier48: 'Other 48 Hour Courier',
      UK_ParcelForceIntlValue: 'Parcelforce Global Value',
      UK_OtherCourier5Days: 'Other Courier 5 days',
      UK_OtherInternationalPostage: "Other Int'l Postage (see description)",
      UK_Parcelforce24: 'Parcelforce 24',
      UK_Parcelforce48: 'Parcelforce 48',
      UK_ParcelForceEuro48International: 'Parcelforce Euro 48',
      UK_ParcelForceInternationalDatapost: 'Parcelforce International Datapost',
      UK_ParcelForceInternationalScheduled: 'Parcelforce International Scheduled',
      UK_ParcelForceIntlEconomy: 'Parcelforce Global Economy (Not available for destinations in Europe)',
      UK_OtherCourierOrDeliveryInternational: 'Other courier or delivery service United Kingdom',
      UK_TrackedDeliveryFromAbroad: 'Tracked delivery from outside abroad',
      UK_StandardShippingFromOutside: 'Standard postage from outside UK',
    },
    UserCategoryType: {
      B2C: 'B2C',
      B2B: 'B2B',
      Market: 'Market',
      Admin: 'Admin',
      Developer: 'Developer',
      Sales: 'Sales',
      SupportService: 'Support service',
      Designer: 'Designer',
      Billing: 'Billing',
      Finance: 'Finance',
      Language: 'Language',
    },
    UserRateClassType: {
      Class1: {
        title: 'Rate class 1',
        price: 'Price',
        percent: 'Percent',
      },
      Class2: {
        title: 'Rate class 2',
        price: 'Price',
        percent: 'Percent',
      },
      Class3: {
        title: 'Rate class 3',
        price: 'Price',
        percent: 'Percent',
      },
    },
    UserSubcategoryType: {
      B2CSmall: 'B2C small',
      B2CMedium: 'B2C medium',
      B2CLarge: 'B2C large',
      B2BSoHo: 'B2B SoHo',
      B2BSMB: 'B2B SMB',
      B2BSME: 'B2B SME',
      B2BEnterprise: 'B2B enterprise',
      Market: 'Market',
      Market1: 'Market 1 level',
      Market2: 'Market 2 level',
      Market3: 'Market 3 level',
      Admin1: 'Admin 1 level',
      Admin2: 'Admin 2 level',
      Admin3: 'Admin 3 level',
      Developer1: 'Developer 1 level',
      Developer2: 'Developer 2 level',
      Developer3: 'Developer 3 level',
      Sales1: 'Sales 1 level',
      Sales2: 'Sales 2 level',
      Sales3: 'Sales 3 level',
      SupportService1: 'Support service 1 level',
      SupportService2: 'Support service 2 level',
      SupportService3: 'Support service 3 level',
      Designer1: 'Designer 1 level',
      Designer2: 'Designer 2 level',
      Designer3: 'Designer 3 level',
      Billing1: 'Billing 1 level',
      Billing2: 'Billing 2 level',
      Billing3: 'Billing 3 level',
      Finance1: 'Finance 1 level',
      Finance2: 'Finance 2 level',
      Finance3: 'Finance 3 level',
      Language1: 'Language 1 level',
      Language2: 'Language 2 level',
      Language3: 'Language 3 level',
    },
    LogisticAdditionaServiceType: {
      IslandDelivery: 'Island Delivery',
      DepartmentalDelivery: 'Departmental delivery',
      IdCheck: 'ID-check',
      SaturdayDelivery: 'Saturday delivery',
      Exchange: 'Exchange',
      PersonalNotification: 'Personal notification',
      SmsNotificationService: 'SMS notification service',
    },
    LogisticAllowedPackageType: {
      Letter: 'Letter',
      GroundPackage: 'Ground package',
      Tyres: 'Tyre',
      Buckets: 'Bucket',
      Rolls: 'Roll',
      Sacks: 'Sack',
      Canisters: 'Canister',
      WoodenBoxes_MetalBoxes: 'Wooden boxes / metal boxes',
      FabricBales: 'Fabric bales',
      NoReadLable: 'No read lable',
      BundledPackages: 'Bundled packages',
    },
    LogisticMeasurePackageType: {
      Size: 'Size',
      DeterminingGirth: 'Max determining girth',
      ShortestLongestSize: 'Max shortest+longest size',
    },
    LogisticShippingServiceType: {
      PickupToPickup: 'Pickup to pickup',
      PickupToCurier: 'Pickup to curier',
      CurierToPickup: 'Curier to pickup',
      CurierToCurier: 'Curier to curier',
    },
    LogisticsInternationalType: {
      National: 'National',
      International: 'International, duties',
      InternationalDuty: 'International, no duties',
    },
    LogisticUserPoductType: {
      Product: 'Product',
      AdditionalService: 'Additional service',
    },
    CurrencyType: {
      USD: 'USD',
      EUR: 'EUR',
    },
    ContactType: {
      Supplier: 'Supplier',
      Customer: 'Customer',
    },
    OrderLabelStatus: {
      New: 'Not created or not printed',
      NotPrinted: 'Created and not printed',
      Printed: 'Created and printed',
      All: 'All',
    },
    DocumentSegmentType: {
      Income: 'Income',
      Expences: 'Expences',
      Other: 'Other',
      All: 'All',
      NotApplicable: 'Not applicable',
    },
    LogisticLableUserType: {
      Sender: 'Sender',
      Recepient: 'Recepient',
    },
    InvoiceType: {
      NetInvoice: 'Net Invoice',
      GrossInvoice: 'Gross Invoice',
    },
    UserSegmentType: {
      SimpleUser: 'Simple user',
      BusinessUser: 'Business user',
      MarketUser: 'Market user',
    },
    TermsOfPaymentType: {
      AdvancePayment: 'Advance Payment',
      DaysAfterDateOfInvoice: 'Days After Date Of Invoice',
      CashOnPickup: 'Cash On Pickup',
      Prepaid: 'Prepaid',
      Directpay: 'Direсtpay',
      Postpaid: 'Postpaid',
    },
    FirstWeekOfYear: {
      FirstJanuary: 'Week that contains January 1 (e.g. US, Canada)',
      StartOfNextWeek: 'Week that contains January {0} (e.g. Europe, ISO-8601)',
    },
    WeekDay: {
      Monday: 'Monday',
      Tuesday: 'Tuesday',
      Wednesday: 'Wednesday',
      Thursday: 'Thursday',
      Friday: 'Friday',
      Saturday: 'Saturday',
      Sunday: 'Sunday',
    },
    TimeZoneRegion: {
      Europe: 'Europe',
      America: 'America',
      Atlantic: 'Atlantic',
      Africa: 'Africa',
      Asia: 'Asia',
      IndianOcean: 'Indian Ocean',
      Australia: 'Australia',
      Pacific: 'Antarctica',
      Antarctica: 'Antarctica',
      Other: 'Other',
    },
    NumberGenerationType: {
      PackList: 'packlist number',
      SaleOrder: 'order number',
      PurchaseOrder: 'order number',
      Invoice: 'invoice number',
      CancelInvoice: 'cancel invoice number',
      Customer: 'customer number',
      parts: {
        PackList: {
          one: 'packlist',
          many: 'packlists',
          few: 'packlists',
        },
        SaleOrder: {
          one: 'order',
          many: 'orders',
          few: 'orders',
        },
        PurchaseOrder: {
          one: 'order number',
          many: 'order numbers',
          few: 'order numbers',
        },
        Invoice: {
          one: 'invoice number',
          many: 'invoice numbers',
          few: 'invoice numbers',
        },
        CancelInvoice: {
          one: 'cancel invoice',
          many: 'cancel invoices',
          few: 'cancel invoices',
        },
        Customer: {
          one: 'customer number',
          many: 'customer numbers',
          few: 'customer numbers',
        },
      },
    },
    PaymentMethod: {
      AmazonPayment: 'AmazonPayment',
      BankTransfer: 'Bank transfer',
      CVS: 'CVS',
      DirectDebit: 'Direct debit',
      CashOnDelivery: 'Cash on delivery',
      EbayPayment: 'Ebay payment',
      KauflandPayment: 'Kaufland payment',
      OzonPayment: 'Ozon payment',
      CreditCard: 'Credit card',
      PayPal: 'PayPal',
      GooglePay: 'GooglePay',
      AmazonPay: 'AmazonPay',
      ApplePay: 'ApplePay',
    },
    NettoBruttoType: {
      Netto: 'Netto',
      Brutto: 'Brutto',
    },
    PaymentsProgramType: {
      EbayPayments: 'EbayPayments',
    },
    PersonTitleType: {
      Mr: 'Mr',
      Mrs: 'Mrs',
    },
    UnitType: {
      Pc: 'pc',
      Pcg: 'pcg',
    },
    AutomaticRemovalDateType: {
      DocumentDate: 'after document date*',
      PaymentDate: 'after payment date*',
      UploadDate: 'after upload date*',
      AllocateDate: 'after allocate date*',
      LastModifiedDate: 'after last modified date*',
      LastReportedDate: 'after last reported date*',
    },
    AmountType: {
      PositiveAmount: 'positive amount',
      NegativeAmount: 'negative amount',
    },
    OrderHistoryType: {
      DeliveryNoteCreated: 'Delivery note {0} created',
      OrderNumberCreated: 'Order {0} created',
      InvoiceNumberCreated: 'Invoice {0} created',
      CancelInvoiceNumberCreated: 'Cancel invoice {0} created',

      PaymentStatusChanged: 'Payment status changed to {0}',
      DeliveryStatusChanged: 'Delivery status changed to {0}',
      DocumentPrinted: 'Printed {0}',
      EmailSended: 'Email sended',
      OrderSplitted: 'Order splitted',
      OrderSplittedBy: 'Order {0} splitted by {1}',
      OrderMerged: 'Order merged',
      OrderMergedInto: 'Order merged into {0}',

      FeedbackCreated: 'Feedback created',
      ShippingLabelGenerated: 'Shipping label generated with tracking number {0}',
      ShippingLabelPrinted: 'Shipping label printed with tracking number {0}',

      OrderStatusChanged: 'Order status changed to {0}',
      OrderEdited: 'Order edited',

      InternalNoteCreated: 'Internal note created',
      ExternalNoteCreated: 'External note created',
      InternalNoteUpdated: 'Internal note updated',
      ExternalNoteUpdated: 'External note updated',
      InternalNoteDeleted: 'Internal note deleted',
      ExternalNoteDeleted: 'External note deleted',
      OrderCopied: 'Order was copied from order with ID {0}',
      OrderValidatedShippingAddressReset: 'Validated shipping address was discarded by manual order updating',
    },
    LogisticContractLabelFormat: {
      DeutschePostA4: 'A4',
      DeutschePost910300700: '910-300-700',
      DeutschePost910300700oz: '910-300-700 oz',
      DeutschePost910300600: '910-300-600',
      DeutschePost910300710: '910-300-710',
      DeutschePost10070mm: '100-70mm',
      DPDA4: 'A4',
      DPDA6: 'A6',
      DPDA7: 'A7',
    },
    LogisticContractReferenceType: {
      InvoiceNumber: 'Invoice number',
      MarketplaceOrderNumber: 'Marketplace order number',
      BuyerMarketplaceAccount: 'Buyer marketplace account',
      AccountName: 'Account name',
      CustomerNumber: 'Customer number',
      HandleByTime: 'Handle by time',
      ExternalNote: 'External note',
    },
    LogisticContractShippingLabelAction: {
      PrintShippingLabel: 'Print shipping label',
      CreateShippingLabel: 'Create shipping label',
    },
    SynchronizeType: {
      AlwaysAsk: 'Always ask',
      Automatic: 'Automatic',
      Manual: 'Manual',
    },
    AppUserStatus: {
      Active: 'Active',
      Inactive: 'Inavtive',
      Deleted: 'Deleted',
    },
    BillingCycleType: {
      PerOrder: 'Per order',
      Daily: 'Daily',
      Weekly: 'Weekly',
      TwiceAMonth: 'Twice a month',
      Monthly: 'Monthly',
      Quaterly: 'Quaterly',
      Yearly: 'Yearly',
    },
    AccountChartTypeCategory: {
      Standart: 'Standard Account Chart',
      Manual: 'Create account chart yourself',
    },
    SubscriptionType: {
      Amount: 'Amount',
      Functions: 'Functions',
    },
    SubscriptionSubtype: {
      Blue: 'Blue',
      Green: 'Green',
      Lila: 'Lila',
      Orange: 'Orange',
      Pink: 'Pink',
      Red: 'Red',
      Turquoise: 'Turquoise',
    },
    SubscriptionTrialOrDiscountType: {
      None: 'None',
      Trial: 'Trial',
      Discount: 'Discount',
    },
    PeriodType: {
      Day: 'Day',
      Week: 'Week',
      Month: 'Month',
      Year: 'Year',
    },
    OrderWarningType: {
      SkuNotFound: 'One or more items not found in the warehouse. Check the correct writing of the SKU',
      ProductRunningOut:
        'One or more items will soon be out of warehouse. Order these items from your supplier right away.',
      NotEnoughProducts: 'One or more items are out of warehouse. Order these items from your supplier right away.',
      BuyerNoteExist: 'Please note that the buyer has written a comment on the order',
      FullfillmentType: '{0} Fullfillment',
    },
    UserWarehouseTemplateItemType: {
      Hal: 'Hal',
      Row: 'Row',
      Shelf: 'Shelf',
      Level: 'Level',
      Place: 'Place',
    },
    UnitGenerationType: {
      SimpleName: 'Add simple "name" unit',
      MultipleNumber: 'Add multiple Number "name" units',
      MultipleLetter: 'Add multiple Letter "name" units',
      MultipleLetterAndNumber: 'Add multiple Letter + Number "name" units',
      MultipleNumberAndLetter: 'Add multiple Number + Letter "name" units',
    },
    UnitGenerationAlphabetType: {
      Latin: 'Latin',
    },
    UserWarehouseBatchType: {
      TransferIntern: 'Internal transfer',
      TransferExtern: 'External transfer',
      TransferToFulfillment: 'Transfer to fulfillment warehouse',
      SendingToCustomer: 'Sending to customer',
      TransferIncomeManual: 'Incoming transfer (manual)',
      TransferIncome: 'Incoming transfer',
    },
    CostType: {
      Netto: 'Netto',
      Brutto: 'Brutto',
      Vat: 'Vat',
      MarketplacePayout: 'Marketplace payout',
      Profit: 'Profit',
    },
    StepType: {
      ByHour: 'By hour',
      ByDay: 'By day',
      ByWeek: 'By week',
      ByMonth: 'By month',
      ByYear: 'By year',
    },
    DocumentTemplateSharingType: {
      Free: 'Free template',
      Buy: 'Buy template',
      AdminTemplate: 'Admin template',
    },
    UserFunctionType: {
      VatIdCheck: 'VatId check',
      AdsWatermark: 'Ads watermark',
      OnlineArchive: 'Online archive',
      AddMarketplaces: 'Add marketplaces',
      DocumentTemplate: 'Document template',
    },
    DocumentTemplateType: {
      DocumentTemplate: 'Document template',
      EmailTemplate: 'Email template',
    },
    DashboardDateFilterType: {
      SalesDate: 'Sales date',
      PaidDate: 'Paid date',
    },
    ReportExtension: {
      Pdf: 'Pdf',
      ReportWithPdf: 'Report with pdf',
      Report: 'Report',
    },
    VatRateType: {
      StandardRate: 'Standard VAT rate, %',
      ReducedRate: 'Reduced VAT rate, %',
      SpecialRate1: 'Special VAT rate 1, %',
      SpecialRate2: 'Special VAT rate 2, %',
      SpecialRate3: 'Special VAT rate 3, %',
    },
  },
  DropMenu: {
    CustomerNo: 'Customer No',
    ProfileSettings: 'Profile settings',
    UpgradeAccount: 'Upgrade Account',
    Subscriptions: 'Subscriptions',
    Billing: 'Billing',
    Payments: 'Payments',
    Security: 'Security',
    Language: 'Language',
    Support: 'Support',
    Help: 'Help',
    DeleteAccount: 'Delete Account',
    LogOut: 'Log out',
  },
  Modules: {
    Synchronize: 'Synchronize',
    Dashboard: 'Dashboard',
    Sales: 'Sales',
    Purchases: 'Purchases',
    Logistic: 'Logistic',
    Email: 'E-Mail',
    Products: 'Products',
    Warehouse: 'Warehouse',
    Customers: 'Customers',
    Suppliers: 'Suppliers',
    Administration: 'Admin',
    Subscriptions: 'Subscriptions',
    DesignService: 'Design service',
    Shop: 'Shop',
    WarehouseLogistics: 'Logistics products',
    DigitalProducts: 'Digital Products',
    Listing: 'Listing',
    Messages: 'Messages',
    Accounting: 'Accounting',
    Settings: 'Settings',
  },
  AdminModules: {
    Customers: 'Customers',
    Products: 'Products',
    Warehouse: 'Warehouse',
    DigitalProducts: 'Digital Products',
    Subscriptions: 'Subscriptions',
    LogisticProducts: 'Logistic Products',
    LanguageEditor: 'Language Editor',
    Accounting: 'Accounting',
    Calendar: 'Calendar',
    Billing: 'Billing',
  },
  Buttons: {
    Accept: 'Accept',
    Yes: 'Yes',
    No: 'No',
    Save: 'Save',
    Search: 'Search',
    Next: 'Next',
    Previous: 'Previous',
    Cancel: 'Cancel',
    Ok: 'Ok',
    Print: 'Print',
    Reset: 'Reset',
    Change: 'Change',
    Payed: 'Payed',
    NotPayed: 'Not payed',
    Shipped: 'Shipped',
    NotShipped: 'Not shipped',
    Received: 'Received',
    NotReceived: 'Not Received',
    Continue: 'Continue',
    Upload: 'Upload',
    Start: 'Start',
    PreviousPage: 'Previous page',
    NextPage: 'Next page',
    DownloadFile: 'Download file',
    Download: 'Download',
    Clear: 'Clear',
    Refresh: 'Refresh',
    Delete: 'Delete',
    Update: 'Update',
    Edit: 'Edit',
    CheckConnection: 'Check connection',
    Send: 'Send',
    Later: 'Later',
    Close: 'Close',
    Preview: 'Preview',
    SendToBucket: 'Send to Bucket',
    TryAgain: 'Try again',
    More: 'More',
  },
  SelectOptions: {
    Accounts: {
      ItemUnitName: 'Account(s)',
      Placeholder: 'Accounts',
    },
    OrderMarker: {
      ItemUnitName: 'Status(es)',
      Placeholder: 'Status',
    },
  },
  Validation: {
    Required: 'Field is required!',
    ValueMustBeNumber: 'Value must be a number!',
    ValueMustBeEmail: 'Enter correct E-Mail address',
    ValueMustBeLessThan: 'Value must be less than {0}',
    ValueMustBeMoreThan: 'Value must be more than {0}',
    ValuesMustBeEqual: 'Values must be equal',
    DateMustBeMoreThan: 'Date must be more than {0}',
    IbanValidation: 'IBAN number invalid',
    ValueMustBePassword: 'The password must contain at least one number, one lowercase, one uppercase, 6 characters.',
    ValueMustBeMoreOrEqualTo: 'Value must be more or equal to {0}',
    ValueMustBeLessOrEqualTo: 'Value must be less or equal to {0}',
    StringShouldNotContainsSpecialCharacters: 'String should not contains special characters, such a [0]',
    DisabledDate: "You can't select this date",
    ShouldBeOneNumberAfterPoint: 'Please enter a number with exactly one digit after the decimal point',
  },
  Pages: {
    DesignServicePage: {},
    Dashboard: {
      Filter: {
        Title: 'Filter',
        Mandats: 'Mandats',
        Warehouses: 'Warehouses',
        ProductCategories: 'Product categories',
        Marketplaces: 'Marketplaces',
        CustomerSegments: 'Customer segments',
        CustomerCountries: 'Customer countries',
        AmountType: 'Amount type',
        Currency: 'Currency',
        Period: 'Period',
        DateFilter: 'Date filter',
        Step: 'Step',
        DefaultFilter: 'Default filter',
        NotListedInWarehouse: 'Not listed in warehouse',
        NotListedInProducts: 'Not listed in products',
      },
      Sales: {
        Title: 'Sales',
      },
      Tasks: {
        Title: 'Tasks',
        NotShippedAll: 'To be shipped',
        NotShippedShippingToday: 'of which day',
        NotPaidAll: 'Not paid',
        NotPaidOverdue: 'of which overdue',
      },
      Listings: {
        Title: 'Listings',
      },
      Traffic: {
        Title: 'Traffic',
      },
    },
    OnlineArchive: {
      Tabs: {
        Upload: 'Upload',
        Allocate: 'Allocate',
        Archive: 'Archive',
        Reports: 'Reports',
      },
      Advertising: 'Get more information about Ship Cheaper scan and print services',
      Upload: {
        DropzoneSection: {
          Upload: 'Upload',
          Mandat: 'Mandat',
          Template: 'Template',
          DateOfDocument: 'Date of document',
          SelectDocumentToUpload: 'Select document to upload',
          DocumentCategory: 'Document category',
        },
        ScanSection: {
          Scan: 'Scan',
          OnlyForElectron: 'This function is only available for the desktop version.',
          BlockScanningModal: {
            Title: 'Scan not allowed',
            Text: 'You didnt specify a scan path. Do you want to go to settings?',
          },
          DocumentsLoadedMessage: {
            Title: 'Scan documents',
            Text: 'Count of uploaded documents {0}',
          },
          UploadPathNotExistsMessage: {
            Title: 'Scan documents',
            Text: 'The specified path does not exists. Please check your settings',
          },
          DocumentsNotExistsMessage: {
            Title: 'Scan documents',
            Text: 'There are no documents in the path. Please scan documents first',
          },
          FileDeleteBlockedMessage: {
            Title: 'File delete blocked',
            Text: 'File delete blocked for some files. Please delete this files manually:',
          },
          FileMoveBlockedMessage: {
            Title: 'File move blocked',
            Text: 'File move blocked for some files. Please move this files manually:',
          },
        },
        EmailSection: {
          Email: 'Email',
        },
        ProgressSection: {
          DocumentWithSameNameHasAlreadyBeenDownloaded: 'Document with same name has already been downloaded',
        },
        LeavePageDialog: {
          title: 'Leave the page?',
          text: 'All unloaded documents will be lost.',
        },
      },
      Reports: {
        Buttons: {
          CreateReportWith: 'Create report with Ship Cheaper Print Service',
          CreateReport: 'Create report',
        },
        ReportInfo: {
          Mandat: 'Mandat',
          Title: 'Name of report',
          DocumentCategory: 'Document category',
          DocumentSubcategory: 'Subcategory',
          DocumentType: 'Document type',
          DocumentDate: 'Date of document',
          PaymentDate: 'Payment date',
          DocumentSegment: 'Document segment',
          ContactNumber: 'Customer No',
          ContactCountry: 'Country',
          AccountCharts: 'Account charts',
          ReportType: 'Report type',
          WithoutAccountCharts: 'Without account charts',
        },
        ReportsTable: {
          DownloadReport: 'Download report',
          ReportPreparing: 'Preparing of report...',
          OrderPrint: 'Order print',
          DeleteModal: {
            title: 'Deleting a report',
            text: 'Are you sure? The selected report will be deleted permanently!',
          },
        },
      },
      Allocate: {
        AllocateModal: {
          Title: 'Allicate',
          Description: 'This is allocate modal',
        },
        InfoSection: {
          DocumentSavedSuccessfully: 'Document saved successfully',
        },
        PreviewSection: {
          OpenDocumentInNewWindow: 'Open document in new window',
          FilePreviewWithThisFormatIsNotSupported: 'File preview with this format is not supported',
        },
        TableSection: {
          ChangeToStatus: 'Select documents and change status',
          ChangeDocumentsAllocateCategory: 'Changing the allocation category for documents',
          YouWantToChangeTheStatusOfDocumentsFromTo:
            "You want to change the status of {0} documents from '{1}' to '{2}'. Are you sure?",
          YouWantToChangeTheStatusOfDocumentsFromToDeleted:
            "You want to change the status of {0} documents from '{1}' to '{2}'. The documents are automatically deleted after 14 days. Are you sure?",
        },
      },
      Archive: {
        Save: 'Save [Ctrl + Enter]',
        Description: {
          Title: 'Archive modal',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt placeat qui reiciendis!',
        },
      },
      Forms: {
        GeneralInfo: {
          Mandat: 'Mandat',
          DocumentName: 'Name',
          DebitAndCredit: 'Debit/Credit',
          Category: 'Category',
          Subcategory: 'Subcategory',
          DocumentDate: 'Document date',
          PaymentDate: 'Payment date',
          DocumentType: 'Document type',
          DocumentNumber: 'Document number',
          NetAmount: 'Net',
          VatAmount: 'VAT',
          GrosAmount: 'Gross',
          AccountingInfo: {
            AccountingCategories: 'Bookkeeping categories',
            DocumentNumber: 'Document number',
            NettoOrBrutto: 'Netto / Brutto',
            Amount: 'Amount',
            Currency: 'Currency',
            VatPercent: 'VAT %',
            PostingId: 'Posting id',
            TotalAmount: 'Total amount:',
            AddCategories: 'Add 5 more categories',
            AmountType: 'Amount type',
            AccountChart: 'Account chart',
            MultiplePositionAlert: 'Multiple positions alert',
            PostingDate: 'Posting date',
            PaymentDate: 'Payment date',
            StandardCurrency: 'Standard currency',
            ExchangeRate: 'Exchange rate',
            ExchangeRateDate: 'Exchange rate date',
            NotCreated: 'not created',
          },
          DeletePostingModal: {
            Title: 'Delete posting',
            Text: 'Posting {0} will be permanently deleted. Delete posting?',
          },
          InsertValueMessages: {
            AllFieldsFilled:
              'The field is already filled. If you want to copy data from {0} then first clear the field',
            FieldsEqual: 'The field is already copied from {0} or is identical',
            MainFieldNotFilled: 'The original field {0} is empty. Fill it out first',
            CopyFieldValue: 'Copy field value {0}',
            CancelValueCopy: 'Cancel copying value',
          },
          DeletionDateChangeModal: {
            Title: 'Document deletion date has changed',
            Text: 'Document deletion date has changed from {0} to {1}',
          },
        },
        ContactInfo: {
          CustSupplNo: 'Customer/supplier No',
          Company: 'Company',
          Name: 'Name',
          Additional: 'Additional',
          Street: 'Street/No',
          ZipOrCity: 'ZIP/city',
          Country: 'Country',
          EMail: 'E-Mail',
          Phone: 'Phone',
          Note: 'Note',
          FirstContactPersonNote: 'First Contact Person Note',
          SecondContactPersonNote: 'Second Contact Person Note',
        },
        OperationsInfo: {
          Mandat: 'Mandat',
          UploadDate: 'Upload date',
          AllocateDate: 'Allocate date',
          LastModifiedDate: 'Last modified date',
          LastReportedDate: 'Last reported date',
          DeletionDate: 'Deletion date',
          Note: 'Note',
          FirstContactPersonNote: 'First contact person note',
          SecondContactPersonNote: 'Second contact person note',
        },
      },
    },
    Shop: {
      ShopCart: {
        ThereAreNoOrderedItemsInTheCart: 'There are no ordered items in the cart.',
        Titles: {
          YourShoppingCart: 'Your shopping cart',
          SelectedOptionsOverview: 'Selected options overview',
          SelectPaymentMethod: 'Select Payment Method',
        },
        Summary: {
          Total: 'Total',
          Tax: 'Tax',
          TotalNet: 'Total Net:',
          TotalVat: 'Total Vat:',
          TotalGross: 'Total Gross:',
        },
        OptionsOverview: {
          Products: 'Products',
          Delivery: 'Delivery',
          PaymentMethod: 'Payment method',
          ToPay: 'To pay',
          OrderWithAnObligationToPay: 'Order with an obligation to pay',
        },
        PaymentSection: {
          AddNewPaymentMethod: '+ Add new payment method',
        },
      },
      ShopCards: {
        NoCardsMessage: 'There are no available products for sale!',
        DigitalProducts: {
          ProductNotOrdered: '{name} not ordered',
          ProductInCart: '{name} in the cart',
          OrderNow: 'Order now',
        },
        SubscriptionProducts: {
          ShowAllFeatures: 'Show all features',
          PriceInformation: {
            text: '{0} {0_month} for {1}/month',
            parts: {
              month: {
                one: 'month',
                many: 'months',
                few: 'months',
              },
            },
          },
          AdditionalPriceInfo: 'then only for {0}/month',
          Upgrade: 'Upgrade',
          YourCurrentTarif: 'Your current tarif:',
          Subscriptions: 'Subscriptions',
          ShowAllFeaturesModal: {
            Title: 'All subscription features',
          },
        },
        TemplateFilters: {
          LoadTemplates: 'Load templates',
        },
      },
      ShopFooter: {
        ProductPriceIncludingTax: '* Product price including tax',
        ProductPriceExcludingTax: '* Product price excluding tax',
      },
      ShopModal: {
        title: 'Adding a product',
        text: 'Product added to cart!',
        okButtonText: 'Proceed to сheckout',
        cancelButtonText: 'Continue shopping',
      },
    },
    Sales: {
      GeneralInfo: {
        GeneralInfo: 'General',
        OrderDate: 'Order Date',
        Buyer: 'Customer',
        Account: 'Account',
        DeliveryNoteNo: 'DeliveryNote No',
        OrderNo: 'Order No',
        InvNo: 'Inv. No',
        CancInvNo: 'Canc.Inv.No',
        ShippingTab: 'Shipping',
        ReturnTab: 'Return',
        ShippingAddressTab: 'Valid.Ship.Addr.',
        OriginalShippingAddressTab: 'Orig.Ship.Addr.',
        InvoiceAddressTab: 'Invoice Addr.',
        ReturnAddressTab: 'Return Addr.',
        OtherTab: 'Other',
        Address: {
          Company: 'Company',
          FirstNameLastName: 'Name',
          Additional: 'Additional',
          StreetNo: 'Street/No',
          ZipCity: 'ZIP/City',
          Country: 'Country',
          UnlockNotPermitted: 'You cannot release the address change because an invoice or label have been created',
        },
        Other: {
          Email: 'E-mail',
          CustomerNumber: 'Cust.No',
          Phone: 'Phone',
          Vat: 'VAT number',
          EbayPlus: 'Ebay plus',
        },
        BuyerNote: "Buyer's note",
        ExternalNote: 'External note',
        InternalNote: 'Internal note',
        History: 'History',
        OrderHistoryModal: {
          title: 'Order history',
          description: 'The order history is displayed here.',
        },
        DocumentPrintModal: {
          Title: 'Document print',
          Text: 'Are you sure? The document will be printed!',
          CountCopy: 'Count copy',
          DocumentNumber: 'Document number',
          CreatedDate: 'Created date',
          DocumentDate: 'Document date',
        },
      },
      AdditionalInfo: {
        AdditionalInfo: 'Additional',
        SalesFormat: 'Sales Format',
        PaymentMethod: 'Payment Method',
        MarketplaceOrderNumber: 'Marketplace order number',
        OriginalShippingMethod: 'Origin Shipping Method',
        PurchaseNet: 'Purchase Net',
        MarketplaceFee: 'Marketplace Fee',
        PromotionFee: 'Promotion Fee',
        PaymentFee: 'Payment Fee',
        Shipping: 'Shipping',
        Profit: 'Profit',
        MarketplacePayout: 'Marketplace payout',
        WarehouseId: 'Warehouse ID',
        Sku: 'SKU',
        Archived: 'Archived',
        Exported: 'Exported',
        Canceled: 'Canceled',
        LastModifiedDate: 'Last Modified',
        LastSynchDate: 'Last Synchronize',
        InvoiceConfirmedOnMarketplace: 'Invoice Confirmed on Marketplace',
      },
      DetailsInfo: {
        DetailsInfo: 'Order details',
        General: 'General',
        Addresses: 'Addresses',
        Documents: 'Documents',
        Payment: 'Payment',
        Shipping: 'Shipping',
        Customer: 'Customer',
        Accounting: 'Accounting',
        System: 'System',
        Others: 'Others',
      },
      OrderItemsSection: {
        Shipping: 'Shipping',
        NetAmount: 'Net Amount',
        Vat: 'VAT',
        TotalAmount: 'Total Amount',
      },
      TableHeaders: {
        OrderType: 'Order Type',
      },
      ValidationShippingLabel: {
        Title: 'The shipping address not yet validated',
        Link: 'Validate shipping address',
      },
      OrderFormModal: {
        OrderInfo: {
          OrderDate: 'Order Date',
          Customer: 'Customer',
          TermsOfPayment: 'Terms Of Payment',
          PaymentDueDays: 'Payment Due Days',
          PaymentDueDate: 'Payment Due Date',
          PaymentMethod: 'Payment Method',
          PaidDate: 'Paid Date',
          DeliveryNoteNo: 'Delivery Note No',
          OrderNo: 'Order No',
          InvoiceNo: 'Invoice No',
          CancelInvoiceNo: 'Cancel Invoice No',
          InternalNote: 'Internal Note',
          ExternalNote: 'External Note',
          BuyerNote: 'Buyer note',
          Archived: 'Archived',
          Exported: 'Exported',
          Currency: 'Currency',
          AmountPaid: 'Amount paid',
          Mandat: 'Mandat',
          Email: 'Email',
        },
        OrderPreview: {
          Title: 'Order preview',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim perspiciatis qui vel.',
          InvoiceAddress: 'Invoice address',
          ShippingAddress: 'Shipping address',
          Shipping: 'Shipping',
          NetAmount: 'Net amount',
          Vat: 'VAT',
          TotalAmount: 'Total amount',
          OrderItems: {
            Quantity: 'Quantity',
            Title: 'Title',
            Variant: 'Variant',
            UnitPriceBrutto: 'Unit price gross',
            UnitPriceNetto: 'Unit price net',
            TotalAmountBrutto: 'Total amount gross',
            TotalAmountNetto: 'Total amount net',
            Vat: 'VAT, %',
          },
        },
        Controls: {
          AddNewCustomer: 'Add new Customer',
          SelectCustomer: 'Select Customer',
          AddDifferentShippingAddress: 'Add Different Shipping Address',
          AddSecondContactPerson: 'Add Second Contact Person',
          Cancel: 'Cancel',
          Next: 'Next',
          Save: 'Save',
          Previous: 'Previous',
          CopySubmit: 'Create Order',
        },
        OrderItems: {
          InvoiceType: 'Invoice Type',
          ShippingMethod: 'Shipping Method',
          AcceptShippingMethodRules: 'Accept Shipping Method Rules',
          ShippingCost: 'Shipping Cost',
          Package: 'Package',
          DeliveryRequired: 'Delivery Required',
        },
        GeneralInfo: {
          Add: 'Add',
          Cancel: 'Cancel',
          Update: 'Update',
          Clear: 'Clear',
          ClearForm: 'Clear Form',
        },
        Dialogs: {
          DeleteProductItemModalDialog: {
            Title: 'Delete order items',
            Text: 'Are you sure you want to delete product items?',
          },
          CantEditOrderModalDialog: {
            Title: 'You cant edit order',
            Text:
              'An invoice has already been created for this order. To process this order you must first create a cancellation invoice.',
          },
        },
        Description: {
          MainOrderInfo: {
            Title: 'Main order info',
            Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim perspiciatis qui vel.',
          },
          OrderItems: {
            Title: 'Order items',
            Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim perspiciatis qui vel.',
          },
          AddOrderItems: {
            Title: 'Add new order items',
            Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim perspiciatis qui vel.',
          },
        },
      },
      CopyOrderModal: {
        Title: 'Copy order',
        Description: 'Are you sure you want to copy order {0} for {1}?',
      },
      TableButtonSection: {
        Update: 'Update',
      },
      Dialogs: {
        DeleteSaleDialog: {
          title: 'Delete sale',
          text: 'Remove {0_selectedSale}?',
          parts: {
            selectedSale: {
              one: 'selected sale',
              few: 'selected sales',
              many: 'selected sales',
            },
          },
        },
        LockAddressEditDialog: {
          title: 'Lock/unlock address editing',
          lockText: 'Lock address editing for this order?',
          unlockText: 'Unlock address editing for this order?',
          unlockWithLostText:
            'Are you sure you want to unlock address changes when updating an order from the marketplace? Changing the address during validation will be lost',
        },
      },
      Messages: {
        BlockedFromMarketplace: 'Blocked from Marketplace (privacy)',
      },
      TableTabs: {
        Favorite: 'Favorite',
        All: 'All',
      },
    },
    Purchases: {
      GeneralInfo: {
        GeneralInfo: 'General',
        PurchaseDate: 'Purchase Date',
        Seller: 'Seller',
        Account: 'Account',
        MustReceiveUntil: 'Must receive until',
        OrderNo: 'Order No',
        ShippingTab: 'Shipping',
        ReturnTab: 'Return',
        ShippingAddressTab: 'Shipping Addr.',
        InvoiceAddressTab: 'Invoice Addr.',
        ReturnAddressTab: 'Return Addr.',
        Address: {
          Company: 'Company',
          FirstNameLastName: 'Name',
          Additional: 'Additional',
          StreetNo: 'Street/No',
          ZipCity: 'ZIP/City',
          Country: 'Country',
        },
        BuyerNote: "Buyer's note",
        ExternalNote: 'External note',
        InternalNote: 'Internal note',
        History: 'History',
      },
      TableButtonSection: {
        Update: 'Update',
      },
    },
    Products: {
      Tabs: {
        SellingPrices: 'Selling Prices',
        Transfer: 'Transfer',
        Packaging: 'Packaging',
        Reorder: 'Reorder',
        Products: 'Products',
        Reports: 'Reports',
        History: 'History',
      },
      TransferSection: {
        FromWarehouse: 'From warehouse',
        ToWarehouse: 'To warehouse',
        SelectItems: 'Select items',
        WithBarcodeScanner: 'With barcode scanner',
        General: 'General',
        WarehouseTransfer: 'Warehouse transfer',
        SelectWarehouse: 'Select warehouse to transfer',
        IncomingTransfer: 'Incoming transfer',
        IncomingManualTransfer: 'Incoming manual transfer',
        InternTransfer: 'Intern transfer',
        ExternTransfer: 'Extern transfer',
        TransferToFulfillment: 'Transfer to fulfillment',
        SendingToCustomer: 'Sending To Customer',
        UncompletedTransfers: 'Uncompleted transfers',
      },
      DetailViewTabs: {
        OtherConditions: 'Other conditions',
        Warehouse: 'Warehouse',
        Purchases: 'Purchases',
        Orders: 'Orders',
        WriteOff: 'Write-off',
        History: 'History',
        Images: 'Images',
        DynamicColumns: 'Dynamic columns',
      },
      ProductDetailView: {
        Name: 'Name',
        Sku: 'SKU',
        Ean: 'Barcode',
        Qty: 'QTY items',
        Condition: 'Condition',
        Type: 'Type',
        StockQuantity: 'Stock quantity',
        PostingId: 'Posting id',
        WarehouseName: 'Warehouse name',
        WarehouseType: 'Warehouse type',
        WarehousePlace: 'Warehouse place',
        Charge: 'Charge',
        BestBeforeDate: 'Best before date',
        LastStockIn: 'Last stock in',
        LastStockOut: 'Last stock out',
        Reposting: 'Reposting',
        DateOrdered: 'Date ordered',
        DateReceived: 'Date received',
        Supplier: 'Supplier',
        UnitCostPrices: 'Unit cost prices',
        Date: 'Date',
        Marketplace: 'Marketplace',
        OrderId: 'Order ID',
        InvoiceNumbers: 'Invoice numbers',
        User: 'User',
        Description: 'Description',
      },
      ErrorsSection: {
        ClearErrorsTitle: 'Clear errors',
        ClearErrorsText: 'Do you really want to clear the error list?',
      },
      TransferSectionModal: {
        Ean: 'Barcode',
        Qty: 'QTY',
        Enter: 'Enter',
        CreateReport: 'Create report',
        ConfirmationModal: {
          Title: 'Are you sure?',
          Text: 'The report has not yet been created!',
        },
      },
      UserWarehouseTransferModal: {
        UncompletedTransfers: {
          Title: 'Uncompleted transfers',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum ipsa qui recusandae?',
          TransfersEmpty: 'Not uncompleted transfers',
        },
        TransferIntern: {
          Title: 'Internal transfer',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum ipsa qui recusandae?',
        },
        TransferExtern: {
          Title: 'External transfer',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum ipsa qui recusandae?',
        },
        SelectExternalWarehouseModal: {
          Title: 'Select warehouse to transfer',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum ipsa qui recusandae?',
          SelectWarehouse: 'Select warehouse',
        },
        TransferToFulfillment: {
          Title: 'Fulfillment transfer',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum ipsa qui recusandae?',

          SelectFullfillmentWarehouse: {
            Title: 'Select warehouse to transfer',
            Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum ipsa qui recusandae?',
            SelectWarehouse: 'Select warehouse',
          },
        },
        CustomerTransferModal: {
          Header: 'Sending to customer',
          Description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum ipsa qui recusandae?',
          SelectUnshippedOrderSection: {
            EmptyMessage: 'No unshipped orders',
          },
        },
        TransferIncomeManual: {
          Title: 'Income manual transfer',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum ipsa qui recusandae?',
          CreatePostings: 'Create postings',
        },
        TransferIncome: {
          Title: 'Incoming transfer',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum ipsa qui recusandae?',
          NoIncoming: 'No incoming transfers',
        },
        SaveTransferBatchConfirm: {
          Title: 'Save transfer',
          Text: 'Do you want to save the current transfer to continue later?',
        },
        TransferModalForm: {
          ScanPlace: 'Scan place',
          ScanProductOrPlace: 'Scan product or place',
          BarcodeOrPlace: 'Barcode - Place',
          Quantity: 'Quantity',
          Charge: 'Charge',
          BestBeforeDate: 'Best before',
          WarrantyEndDate: 'Warranty end date',
          CustomTariffNumber: 'Custom tariff number',
          CargoDeclarationNumber: 'Cargo declaration number',
          Enter: 'Enter',
        },
        SelectedUnitInfo: {
          Place: 'Place',
          ScanPlace: 'Scan warehouse place',
        },
        SelectedWarehouseInfo: {
          NoSelectedWarehouse: 'No selected warehouse',
        },
        SelectModeModal: {
          Title: 'Select mode',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum ipsa qui recusandae?',
        },
        Actions: {
          TakeProducts: 'Take products',
          PutProducts: 'Put products',
          SendToAnotherWarehouse: 'Send to another warehouse',
          SendToCustomer: 'Send to customer',
          SelectMode: 'Select mode',
        },
      },
      WarehouseSection: {
        Title: 'Warehouse',
        Sku: 'SKU',
        WhPlace: 'WH Place',
        Item: 'Item',
        Variant: 'Variant',
        Mandat: 'Mandat',
        Category: 'Ship.Autom.Categ.',
        QtyInWh: 'QTY in WH',
        QtyAvailable: 'QTY available',
        QtyOrderedTotal: 'QTY order. total',
        QtyOrderedUnpaid: 'QTY order. unpaid',
        QtyOrderedPaid: 'QTY order. paid',
        QtyMinimum: 'QTY minimum',
        QtyTarget: 'QTY target',
        QtyAtDeliveryDate: 'QTY at deliv. date',
        Ean: 'Barcode',
        EanPackage: 'Barcode package',
        Vat: 'VAT, %',
        Length: 'Length',
        Height: 'Height',
        Width: 'Width',
        Weight: 'Weight',
        Categories: 'Categories',
      },
      ProductPackagingSection: {
        SalesPackaging: 'Other packaging',
        Name: 'Name',
        Sku: 'SKU',
        Ean: 'Barcode',
        Qty: 'QTY items',
        AddNewPackage: '+ Add new package',
        EditPackages: 'Edit packages',
      },
      SupplierSection: {
        QtyReorderItems: 'QTY reorder items',
        QtyReorderPack: 'QTY reorder pack',
        DateReorder: 'Date reorder',
        ExpectedDeliveryDate: 'Expect. deliv. date',
        Recommend: 'Recommend',
        Is: 'Is',
        Reordered: 'Reordered',
        Supplier: 'Supplier',
        Item: 'Item',
        UnitPrice: 'Unit price',
        Currency: 'Currency',
        QtyInPack: 'QTY in pack',
        Variant: 'Variant',
        Moq: 'MOQ',
        DeliveryTimeInDays: 'Delivery time in days',
        AcceptRecommendedReorder: 'Accept recommended reorder',
        Received: 'Received',
        ReorderTitle: 'Reorder',
      },
      SellingPricesSection: {
        ShipCheaperCountryCode: 'Ship Cheaper {0}',
      },
      DynamicColumnsSection: {
        NoColumns: 'No dynamic columns',
        AddColumns: 'Add columns',
      },
      DynamicColumnsModal: {
        Title: 'Dynamic columns',
        Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum ipsa qui recusandae?',
        ColumnName: 'Column name',
        Required: 'Required',
        AddToAllProducts: 'To all products',
        ColumnType: 'Column type',
        AddNewColumn: 'Add new column',
        SaveOrDelete: 'First save or delete new column',
        NoColumns: 'No dynamic columns',
        DeleteColumnModal: {
          Title: 'Delete column',
          Text: 'Are you sure? All data associated with this column will be lost.',
        },
      },
      ImagesSection: {
        SelectImages: 'Select images',
        MaxImageCount: 'Maximum count of images is {0}',
      },
      AddColumnsToSelectedProductsModal: {
        Title: 'Add columns to selected products',
        Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum ipsa qui recusandae?',
        AddColumns: 'Add columns',
      },
      ModalDialogs: {
        DeletePackageModal: {
          Text: 'Delete package',
          Title: 'Are you sure you want to delete package?',
        },
        DeleteProductModal: {
          Title: 'Deleting product',
          Text: 'Are you sure? The selected product will be deleted!',
        },
        DeleteReportModal: {
          Title: 'Deleting product',
          Text: 'Are you sure? The selected report will be deleted!',
        },
      },
      Description: {
        ProductModal: {
          Title: 'Product',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum ipsa qui recusandae?',
        },
        BarcodeModal: {
          Title: 'Scanning',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum ipsa qui recusandae?',
        },
      },
    },
    Warehouse: {
      Tabs: {
        Warehouse: 'Warehouse',
        Transfer: 'Transfer',
        Reports: 'Reports',
      },
      Transfer: {
        CustomerTransferModal: {
          Header: 'Sending to customer',
          Description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum ipsa qui recusandae?',
          ConfirmationModal: {
            Title: 'Confirmation',
            Text: 'Are you sure? All changes will be applied!',
          },
          SelectUnshippedOrderSection: {
            EmptyMessage: 'No unshipped orders',
          },
        },
        ExternalTransferModal: {
          Header: 'External transfer',
          Description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum ipsa qui recusandae?',
          SelectExternalWarehouseModal: {
            Header: 'Select warehouse for transfer',
            Description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum ipsa qui recusandae??',
          },
        },
        FulfillmentTransferModal: {
          Header: 'Fulfillment transfer',
          Description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum ipsa qui recusandae?',
          SelectFulfillmentWarehouseModal: {
            Header: 'Select warehouse for transfer',
            Description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum ipsa qui recusandae?',
          },
        },
        IncomingManualTransferModal: {
          Header: 'Incoming manual transfer',
          Description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum ipsa qui recusandae?',
        },
        IncomingTransferModal: {
          Header: 'Incoming transfer',
          Description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum ipsa qui recusandae?',
          SelectTransferSection: {
            Description:
              'Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur',
          },
        },
        InternalTransferModal: {
          Header: 'Internal transfer',
          Description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum ipsa qui recusandae?',
        },
        SelectedUnitInfo: {
          EmptyMessage: 'Scan warehouse place',
        },
        SelectedWarehouseInfo: {
          EmptyMessage: 'No selected warehouse',
        },
      },
      WarehousePlan: {
        WarehouseLocation: {
          Warehouse: 'Warehouse',
          Mandats: 'Mandats',
          Types: 'Types',
          Address: 'Address',
          GenerateUnits: 'Generate units',
        },
        UnitModal: {
          AddUnit: 'Add new warehouse units',
          Description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, molestias!',
          WarehouseTypes: 'Warehouse types',
          Template: 'Template',
          StartFrom: 'Start from',
          AddBtn: 'Add',
          AddType: 'Add type',
          Value: 'Value',
          Alphabet: 'Alphabet',
          From: 'From',
          To: 'To',
          Sequence: 'Sequence',
        },
        GenerateUnitsModal: {
          Title: 'Generate units',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, molestias!',
          Template: 'Template',
        },
        UpdateUnitModal: {
          Title: 'Update warehouse unit',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, molestias!',
          Number: 'Unit number',
        },
        DeleteUnitModal: {
          Title: 'Delete warehouse unit',
          Text: 'Are you sure? The selected unit will be deleted permanently!',
        },
        BlockUnitCreatingModal: {
          Title: 'Blocking unit creation',
          Text: 'In this warehouse structure, all units that you have in the settings are applied',
          CreateUnit: 'Create new unit type',
        },
        LocationProducts: {
          Products: 'Products',
          History: 'History',
        },
        Messages: {
          DeleteBlocked: 'You need to delete products to remove this unit',
          CreateLowerLevelBlocked: 'You need to delete the products to share this ”unit”',
        },
      },
    },
    Authorization: {
      Titles: {
        Welcome: 'We warmly welcome you',
        ConfirmEmail: 'Confirm E-Mail',
        ChangePassword: 'Change password',
        ResetPassword: 'Reset password',
        Comeback: "It's good to have you back!",
      },
      AlreadyRegistered: 'Already registered?',
      Buttons: {
        Login: 'Login',
        SignUp: 'Sign up',
        ResendEmail: 'Resend E-Mail',
        SendEmail: 'Send E-Mail',
        Continue: 'Continue',
        GoToLogin: 'Go to login',
      },
      SignIn: {
        PleaseLogInToShipCheaperErpSystem: 'Please log in to Ship Cheaper ERP-system',
        Or: 'or',
        Email: 'E-Mail',
        Password: 'Password',
        ForgorYourPassword: 'Forgot your password?',
      },
      SignUp: {
        InfoForm: {
          WouldYouLikeToRegisterAPrivateOrCompanyAccount: 'Would you like to register a private or company account?',
          PrivateAccount: 'Private account',
          CompanyAccount: 'Company account',
          FirstName: 'First name',
          LastName: 'Last name',
          TheNameOfTheCompany: 'The name of the company',
          Email: 'E-Mail',
          Password: 'Password',
          CompanyLocation: 'Company location',
        },
        AddressForm: {
          YourSenderAddressForShippingLabels: 'Your Invoice address',
          Company: 'Company',
          FirstName: 'First name',
          LastName: 'Last name',
          Street: 'Street',
          HouseNo: 'House No',
          Zip: 'ZIP',
          City: 'City',
          County: 'Country',
          Phone: 'Phone',
          AddDifferentShippingAddress: '+ Your different sender address for shipping labels',
          RemoveDifferentShippingAddress: 'Remove  different sender address ',
          AddDifferentReturnAddress: '+ Add different return address',
          RemoveDifferentReturnAddress: 'Remove different return address',
        },
        OrdersForm: {
          HowManyOrdersDoYouExpectToSendAveragePerMonth: 'How many orders do you expect to send average per month?',
          UninsuredShipping: 'Uninsured shipping (for example letters)',
          InsuredShipping: 'Insured shipping (with tracking number)',
          National: 'National',
          International: 'International',
        },
        UserTerms: {
          ByCreating: 'By creating an account, you confirm that you have read and accepted the terms of the ',
          UserAgreement: 'User Agreement',
          AndThe: 'and the ',
          UserPrivacyNotice: 'User Privacy Notice',
        },
      },
      EmailConfirmation: {
        PleaseConfirmYourEmailAddress: 'Please confirm your E-Mail address!',
        EmailConfirmationLinkIsInvalid: 'E-Mail confirmation link is invalid!',
        WeSentAConfirmationLinkToYourEmail:
          'We sent a confirmation link with activation code to your E-Mail. The E-Mailexpires in 24 hours.',
        ToContinueUsingTheShipCheaperERPSystemYouNeedToConfirmYourEmailAddress:
          'To continue using the Ship-Cheaper ERP system, you need to confirm your E-Mail address.',
        TheAccountFromThisLinkIsNotRegistered:
          'The account from this link is not registered. Log in to the correct account or register a new account in the Ship-Cheaper ERP system.',
        TheCodeFromTheLinkIsIncorrectOrTheLinkHasExpired:
          'The code from the link is incorrect or the link has expired. You can send a new link to your E-Mail using the button below.',
        RedirectingToErpSystem: 'Redirecting to ERP system',
        CheckingTheLink: 'Checking the link',
      },
      ResetPassword: {
        PleaseEnterYourEmailAddressToResetYourPassword: 'Please enter your E-Mail address to reset your password',
        EmaiSentSuccessfully: 'E-Mail sent successfully',
        WeWillSendALinkToResetYourPasswordToYourEmail:
          'We will send a link to reset your password to your E-Mail. The email expires in 24 hours.',
        CheckYourEmailForALinkToResetYourPassword:
          'Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.',
        EmailAddress: 'E-Mail',
      },
      ChangePassword: {
        PleaseEnterYourNewPasswordBelow: 'Please enter your new password below.',
        Password: 'Password',
        ConfirmPassword: 'Confirm password',
        ThePasswordsDoNotMatch: 'The passwords do not match',
        CheckingTheLink: 'Checking the link',
        RedirectingToLogin: 'Redirecting to login',
        SomethingIsWrongWithTheLinkPleaseTryToResetYourPasswordAgain:
          'Something is wrong with the link. Please, try to reset your password again.',
      },
    },
    Settings: {
      Items: {
        Account: 'Account',
        Synchronize: 'Synchronize',
        Mandats: 'Mandats',
        Logistic: 'Logistic',
        ShippingAutomation: 'Shipping Automation',
        OrderAutomation: 'Order Automation',
        AddressBook: 'Address Book',
        Packages: 'Packages',
        Marketplaces: 'Marketplaces',
        Accounting: 'Accounting',
        Products: 'Products',
        Invoices: 'Invoices',
        Feedbacks: 'Feedbacks',
        GridsColours: 'Visualisation',
        Integrations: 'Integrations',
        ShipCheaperApi: 'Ship Cheaper API',
        Help: 'Help',
        Info: 'Info',
        DocumentTemplates: 'Document Templates',
        EmailTemplates: 'Email Templates',
        Warehouse: 'Warehouse',
      },
      AccountsSettings: {
        Title: 'Accounts',
        Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum, praesentium.',
        Subtitle: 'Invoice Company, Invoice Name',
        Form: {
          Email: 'E-mail',
          Password: 'Password',
          MarketCode: 'Market Code',
          Country: 'Country',
          VatId: 'VAT ID',
          CustomerNumber: 'Customer number',
          InternalCode: 'Internal code (PO No)',
          AccountLanguage: 'Account language',
          AccountStatus: 'Account status',
          AccountCategory: 'Account category',
          AccountSegment: 'Account segment',
          RateClass: 'Rate class',
          ActiveDate: 'Active date',
          InactiveDate: 'Inactive date',
          DisconnectReason: 'Disconnect reason',
          InvoiceLanguage: 'Invoice language',
          InvoiceDispatchMethod: 'Invoice dispatch method',
          PaymentDuePeriode: 'Payment due periode',
          BillingCycle: 'Billing cycle',
          PrepaidPostpaid: 'Prepaid / postpaid',
          PaymentMethod: 'Payment method',
          MaxCredit: 'Max credit',
          Balance: 'Balance',
          Currency: 'Currency',
          CustomerInDunning: 'Customer in dunning',
          TotalInDunning: 'Total in dunning',
          DunningStop: 'Dunning stop',
          LastDunningEvent: 'Last dunning event',
          LastDunningEventDate: 'Last dunning event date',
          NextDunningEvent: 'Next dunning event',
          NextDunningEventDate: 'Next dunning event date',
          RefinancePlan: 'Refinance plan',
          ChangePassword: 'Change password',
          EmailForInvoices: 'Email for invoices',
        },
        ChangePasswordModal: {
          Title: 'Change password',
          Subtitle: 'Insert your current password and new one with confirmation',
          ChangePasswordForm: {
            CurrentPassword: 'Current password',
            NewPassword: 'New password',
            ConfirmNewPassword: 'Confirm new password',
          },
        },
        ChangeFieldModal: {
          Title: 'Change {0}',

          Fields: {
            internalCode: 'Internal Code',
            vatId: 'Vat ID',
            invoiceEmail: 'Email for invoices',
          },
        },
      },
      AddressBookSettings: {
        Title: 'Favorite address book',
        Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum, praesentium.',
        Controls: {
          CreateLogisticLable: 'Create logistic lable',
          CreateReturnLogisticLable: 'Create return logistic lable',
        },
        LableSection: {
          SenderOrRecipient: 'Sender/Recipient',
          Marketplaces: 'Marketplaces',
          DefaultPackage: 'Default Package',
          DefaultLogistic: 'Default Logistic',
          CustomerOrSupplier: 'Customer/Supplier',
        },

        AddressSection: {
          Company: 'Company',
          FirstName: 'First name',
          LastName: 'Last name',
          StreetName: 'Street name',
          HouseNumber: 'House number',
          ZIP: 'ZIP',
          City: 'City',
          Country: 'Country',
          FirstContactPersonPhoneNumber: 'First Contact Person Phone Number',
          FirstContactPersonEmail: 'First Contact Person E-Mail',
        },
      },
      MarketplaceAccounts: {
        MarketplaceInfoSameAsDefault: '{0} same as default',
        Steps: {
          Account: 'Account',
          LegalNotice: 'Legal Notice',
          Documents: 'Documents',
          Email: 'E-Mail',
          FreeText: 'FreeText',
          Connecting: 'Connecting',
          Finish: 'Finish',
        },
        ModalDialog: {
          AddOneMoreMarketplaceAccount: 'Add one more marketplace account?',
          YouCanComeBackToAddingMarketplaceAccountsLater: 'You can come back to adding marketplace accounts later.',
          WhereDoYouWantToGo: 'Where do you want to go?',
          GoToSales: 'Go to sales?',
          GoToPurchases: 'Go to purchases?',
          PleaseWaitSyncingAccounts: 'Please wait, syncing accounts!',
        },
        Account: {
          IsActive: 'Is active',
          Mandat: 'Mandat',
          AccountName: 'Account name',
          Marketplace: 'Marketplace',
          MarketplaceLogin: 'Marketplace login',
          Country: 'Country',
          SynchronizeFrom: 'Next synchronize from',
          AllowUpdatingOfSynchTransactions: 'Allow updating of synchronised transactions',
          TransferStatusChangeOnTheMp: 'Transfer status change on the marketplace',
          TransferTrackingIdOnTheMp: 'Transfer tracking ID on the marketplace',
          AllowCustomerSavingOnSynchronization: 'Save customers during synchronization',
          ConnectingStatus: 'Connecting status',
          Available: 'Available',
          Unavailable: 'Unavailable',
          ConnectingValidUntil: 'Connecting valid until',
          ConnectToTheMp: 'Connect to the marketplace',
          CheckConnection: 'Check connection',
          RefreshMpConnection: 'Refresh marketplace connection',
          ClientKey: 'Client key',
          SecretKey: 'Secret key',
          MarketplaceSite: 'Marketplace site',
          AllowUploadInvoices: 'Allow upload invoices',
          NotAllowUploadInvoices: 'Dont allow upload invoices',
          AllowCreateInvoices: 'Invoice can be created',
          NotAllowCreateInvoices: 'Invoice can not be created',
          WarehouseLocation: {
            MarketplaceFulfillment: 'Fulfilled by Marketplace',
            MerchantFulfillment: 'Fulfilled by Merchant',
            FulfillmentActive: 'Fulfillment active',
            Location: 'Warehouse location',
            Countries: 'Countries',
          },
          AllowCustomerSavingDisableHints: {
            AmazonForbidsToStore: 'Amazon forbids to store customers information.',
          },
          SellerCountrySelectMessage: {
            Title: 'Location settings',
            Text: 'Locations for all countries are not filled',
          },
          MarketplaceCountrySelectMessage: {
            Title: 'Location settings',
            Text: 'Locations for all countries are not filled',
          },
          AutoDisableSellerCountryMessage: {
            Title: 'Location settings',
            Text: 'The fulfillment function will be deactivated, since no warehouse is linked to it',
          },
          ConnectionSuccessMessage: {
            Title: 'Check marketplace connection',
            Text: 'The account {0} is connected with {1}',
          },
          ConnectionLostMessage: {
            Title: 'Check marketplace connection',
            Text: 'The account {0} is not connected with {1}. Check your credentials',
          },
        },
        LegalNotice: {
          Company: 'Company',
          FirstName: 'First Name',
          LastName: 'Last Name',
          StreetHouseNo: 'Street / House No',
          ZipCity: 'ZIP / CITY',
          Country: 'Country',
          Phone: 'Phone',
          Fax: 'Fax',
          Email: 'E-Mail',
          Homepage: 'Homepage',
          BankAccountHolder: 'Bank Account Holder',
          Iban: 'IBAN',
          Bic: 'BIC',
          bankName: 'Bank Name',
          Street: 'Street',
          HouseNo: 'House No',
          Zip: 'Zip',
          City: 'City',
          SelectLogoToUpload: 'Select logo to upload',
          Logo: 'Logo',
          InvoiceAddress: 'Invoice Address',
          ShippingAddress: 'Shipping Address',
          RemoveDifferentShippingAddress: '+ Remove different shipping address',
          AddDifferentShippingAddress: '+ Add different shipping address',
          CreditorId: 'Creditor identification number',
          ReturnAddress: 'Return Address',
          RemoveDifferentReturnAddress: '+ Remove different return address',
          AddDifferentReturnAddress: '+ Add different return address',
          Contact: 'Contact',
          BankPayments: 'Bank account',
          CountryVatList: {
            InsertVatId: 'Insert Vat ID for other Countries',
            IsActive: 'Is Vat ID active',
            IsEuOss: 'EU OSS',
            CountryNotSelected: 'Please select first invoice country',
            Country: 'Country',
            VatId: 'Vat ID',
            Percent1: 'Standard VAT rate, %',
            Percent2: 'Reduced VAT rate, %',
            Percent3: 'Special VAT rate 1, %',
            Percent4: 'Special VAT rate 2, %',
            Percent5: 'Special VAT rate 3, %',
          },
        },
        Documents: {
          Prefix: 'Prefix',
          NextNumber: 'Next number',
          Suffix: 'Suffix',
          PackListNumber: 'Pack List Number',
          InvoiceNumber: 'Invoice Number',
          ExtraRangeForCancelInvoice: 'Extra Range for Cancel Invoice',
          CustomerNumber: 'Customer Number',
          FreeText1: 'Free text 1',
          FreeText2: 'Free text 2',
          FreeText3: 'Free text 3',
          FreeText4: 'Free text 4',
          FreeText5: 'Free text 5',
          OrderNumberSales: 'Order Number (sales)',
          OrderNumberPurchases: 'Order Number (purchases)',
          SupplierNumber: 'Supplier Number',
          Divider: 'Divider',
        },
        Email: {
          OutgoingSMTPServer: 'Outgoing SMTP Server',
          Port: 'Port',
          TryServerRequestAnEncryptedConnection: 'Try server request an encrypted connection (SSL)',
          UseTheFollowTypeOfEncryptedConnection: 'Type of encrypted connection',
          Email: 'E-Mail',
          Name: 'Name',
          Login: 'Login',
          Password: 'Password',
          ChangePassword: 'Change password',
          CheckConnectionNotAvailable: 'Check connection is only available after saving the settings!',
          CheckEmailConnectionButton: 'Check email connection',
          EmailValidationSuccessMessage: 'Connected',
        },
        FreeText: {
          FreeText1: 'FreeText1',
          FreeText2: 'FreeText2',
          FreeText3: 'FreeText3',
          FreeText4: 'FreeText4',
          FreeText5: 'FreeText5',
        },
        Connecting: {
          NowConnectMarketplaceYourAccountToYourShipCheaperERPSystem:
            'Now connect {0} your account {1} to your Ship-cheaper ERP system.',
          PasswordFromYourMarketplaceAccountWillNotBeSaved: 'Password from your {0} account will not be saved',
          ClickOnTheButton: '1. Click on the button.',
          RequestAccessToTheMarketplace: 'Request access to the {0}',
          YourWebBrowserOpensWithMarketplacePage: '2. Your Web browser opens with {0} page.',
          PleaseLoginUnderAccount: 'Please login under account {0}.',
          ClickAgreeInTheMarketplacePage: "3. Click 'Agree' in the {0} page.",
          PleaseWaitWhileWeReceiveYourTokenFromTheMarketplace:
            '4. Please wait while we receive your token from the {0}.',
          ConnectingStatus: 'Connecting status',
          Available: 'Available',
          Unavailable: 'Unavailable',
          ConnectingValidUntil: 'Connecting valid until',
          ClientKey: 'Client Key',
          SecretKey: 'Secret Key',
        },
        Finish: {
          Congratulations: 'Congratulations!',
          YouHaveSuccessfullySetUpYourMarketplaceAccount: 'You have successfully set up your Marketplace account.',
        },
        DeleteModal: {
          title: 'Deleting a marketplace account',
          text: 'Are you sure? The selected marketplace account will be deleted!',
        },
        CredentialForms: {
          Ozon: {
            ClientId: 'Client id',
            ApiKey: 'Api key',
          },
          Kaufland: {
            ClientKey: 'Client key',
            SecretKey: 'Secret key',
            Country: 'Country',
          },
        },
      },
      DocumentTemplateSettings: {
        Description: {
          Title: 'Document templates',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum, praesentium.',
        },
        Title: 'Title',
        Sharing: {
          TemplateSharingType: 'Template sharing type',
        },
        Templates: {
          Title: 'Templates',
          UseToSendDeliveryNote: 'Use to send delivery note',
          UseToSendSale: 'Use to send sale',
          UseToSendPurchase: 'Use to send purchase',
          UseToSendInvoice: 'Use to send invoice',
          UseToSendCancelInvoice: 'Use to send cancel invoice',
          TemplateSharingType: 'Template sharing type',
        },
        Automation: {
          Title: 'Automation',
          DoNotUse: 'Do not use',
          UseToSendDeliveryNote: 'Use to send delivery note',
          UseToSendSale: 'Use to send sale',
          UseToSendPurchase: 'Use to send purchase',
          UseToSendInvoice: 'Use to send invoice',
          UseToSendCancelInvoice: 'Use to send cancel invoice',
        },
        Save: {
          Title: 'Save',
          CheckedOnlineArchive: 'Online Archive',
          Filename: 'File Name',
          SeparatingSign: 'Separating sign',
          Position: 'Position',
          Date: 'Date',
          CustomId: 'Custom ID',
          InvoiceNo: 'Invoice No',
          CancInvoiceNo: 'Canc InvoiceNo',
          OrderNo: 'Order No',
          CustomerName: 'Customer Name',
          OnlyForElectron: 'This function is only available for the desktop version.',
          SaveWhenSending: 'Save when sending',
          SaveWhenPrinting: 'Save when printing',
        },
        Printer: {
          Title: 'Printer',
          Preview: 'Preview',
        },
        Compartment: {
          Title: 'Compartment',
          Sales: 'Sales',
          Purchases: 'Purchases',
          Customer: 'Customer/Supplier',
          Warehouse: 'Warehouse',
        },
        SetStatus: {
          Title: 'Set status',
          SelectDocumentType: 'Select document type...',
        },
        Modal: {
          Modified: {
            Title: 'Attention',
            Text: "Template '{0}' has been changed. Save?",
          },
          Delete: {
            Title: 'Attention',
            Text: "Delete template '{0}'?",
          },
          Restore: {
            Title: 'Attention',
            Text: "Restore template '{0}'?",
          },
          Copy: {
            Title: 'Attention',
            Text: "Copy template '{0}'?",
          },
        },
      },
      PackageSettings: {
        Title: 'Packages',
        Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum, praesentium.',
        Id: 'ID',
        DeletedTime: 'Deleted Time',
        Name: 'Name',
        IsDefault: 'Is Default',
        Height: 'Height, mm',
        Length: 'Length, mm',
        Weight: 'Weight, kg',
        Width: 'Width, mm',
        EAN: 'Barcode',
        ModalWindows: {
          DeleteModalWindow: {
            Title: 'Deleting',
            Message: "Do you want to delete '{0}'?",
          },
        },
        PackageTypes: 'Package Types',
        LogisticTypes: 'Logistic Types',
        IsActive: 'Is Active',
        Sku: 'SKU',
        Description: {
          Title: 'Packages',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic quae sit vero.',
        },
      },
      ShippingAutomationSettings: {
        Title: 'Shipping automation',
        Warning:
          'If an order has a shipping method change rule at the same time due to order amount and goods quantity, then shipping method is changed according to goods quantity rule',
        NoCategoriesWithoutRules:
          'The rules for all item categories have been created. You can create new item categories for items in the warehouse at the item.',
        NoPackages: 'No packages added. Please add packages using the button below',
        Tabs: {
          AmountAutomation: 'Amount automation',
          ItemsCategoryShippingAutomation: 'Items category shipping automation',
          ItemsCategoryPackagingAutomation: 'Items category packaging automation',
          MixCategoriesAutomation: 'Mix categories automation',
        },
        DeleteModal: {
          title: 'Automation rule deleting',
          text: 'Do you want to delete automation rule?',
        },
        AmountAutomation: {
          AutomaticShippingChangeAccordingToAmount: 'Automatic shipping change according to amount',
          Marketplace: 'Marketplaces',
          AmountBrutto: 'Total Amount',
          OriginalShippingMethod: 'Original Shipping Method',
          ActualShippingMethod: 'Actual Shipping Method',
          FirstSaveOrDeleteNewAmountRule: 'First save or delete new amount rule!',
          CreateNewShippingChargeRules: 'Create new shipping charge rules',
          MoreOrEqual: 'More or equal',
          CountriesRecipient: 'Countries Recipient',
        },
        CategoriesShippingAutomation: {
          ItemsCategoryForShipping: 'Logistics automation by item category',
          MoreThanQty: 'More than QTY',
          OriginalShippingMethod: 'Original Shipping Method',
          ActualShippingMethod: 'Actual Shipping Method',
          FirstSaveOrDeleteNewShippingRule: 'First save or delete new shipping rule!',
          CreateNewShippingChargeRules: 'Create new shipping charge rules',
          AddNewCategoryToTheRules: 'Add a rule for other item category',
          FirstSaveOrDeleteNewCategory: 'First save or delete new category',
          MoreOrEqualQty: 'More or equal QTY',
          CountriesRecipient: 'Countries Recipient',
          CategoryOfAutomatedDelivery: 'Category of automated delivery',
        },
        CategoriesPackagingAutomation: {
          ItemsCategoryForPackaging: 'Packaging automation by item category',
          MaxItemQty: 'Max Item QTY',
          Package: 'Package',
          WeightKg: 'Weight, kg',
          FirstSaveOrDeleteNewPackagingRule: 'First save or delete new packaging rule!',
          AddNewPackageToTheRule: 'Add new package to the rule',
          CreateNewPackagingChargeRules: 'Create new packaging charge rules',
          CreateNewCategoryForShipping: 'Create new category for shipping',
          CreateNewPackage: 'Create new package',
          AddNewCategoryToTheRules: 'Add new category to the rules',
          FirstSaveOrDeleteNewCategory: 'First save or delete new category',
          CategoryOfAutomatedDelivery: 'Category of automated delivery',
        },
        MixCategoriesAutomation: {
          PackagesByMixCategories: 'Mix categories automation',
          Id: 'ID',
          Categories: 'Item categories',
          QtyItems: 'QTY items',
          ShippingMethod: 'Shipping method',
          PackageName: 'Package name',
          WeightKg: 'Weight, kg',
          CountriesRecipient: 'Countries Recipient',
        },
      },
      Controls: {
        Cancel: 'Cancel',
        Save: 'Save',
      },
      OnlineArchiveSettings: {
        Title: 'Accounting',
        Tabs: {
          General: 'General',
          DocumentCategories: 'Document categories',
          AllocateCategories: 'Categories for allocate',
          UploadTemplates: 'Upload templates',
          AccountChart: 'Account chart',
          ScanDocuments: 'Scan documents',
        },
        General: {
          Description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum, praesentium.',
        },
        DocumentCategories: {
          Description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum, praesentium.',
          DocumentCategories: 'Document categories',
          DocumentTypes: 'Document types',
          Name: 'Name',
          AutomaticRemoval: 'Automatic removal',
          DocumentTypesTooltip:
            'If no date is given for the document, it will be calculated from the date the document was uploaded',
          DeleteTypeModal: {
            Title: 'Delete type',
            Text: 'Selected type will be deleted permanently. Are you sure?',
          },
          DocumentTypeUpdateModal: {
            Title: 'Update existing documents?',
            Text:
              'According to the new criteria, {0} documents will be deleted right now without the possibility of recovery.',
          },
          DisabledDocumentTypes: {
            Offer: 'Offer',
            OrderConfirmation: 'Order confirmation',
            DeliveryNote: 'Delivery note',
            Invoice: 'Invoice',
            Dunning: 'Dunning',
            Cancellation: 'Cancellation',
            DirectDebitMandate: 'Direct debit mandate',
            Contract: 'Contract',
            Insurance: 'Insurance',
            AccountStatement: 'Account statement',
            Payroll: 'Payroll',
            TaxOffice: 'Tax office',
            Report: 'Report',
            'Order confirmation': 'Order confirmation',
            'Delivery note': 'Delivery note',
            'Direct debit mandate': 'Direct debit mandate',
            'Account statement': 'Account statement',
            'Tax office': 'Tax office',
          },
          Years: 'year(s)',
        },
        AllocateCategories: {
          Description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum, praesentium.',
          AllocateCategory: 'Allocate category',
          DisabledCategories: {
            Open: 'Open',
            Deleted: 'Deleted',
            Completed: 'Completed',
          },
          DeleteModal: {
            Title: 'Delete folder',
            Text: 'Selected folder will be deleted permanently. Are you sure?',
          },
        },
        UploadTemplates: {
          Mandat: 'Mandat',
          TemlateName: 'Temlate name',
          DocumentTemplate: 'Document template',
          DebitOrCredit: 'Debit/Credit',
          Category: 'Category',
          Subcategory: 'Subcategory',
          PaymentDate: 'Payment date',
          DocumentType: 'Document type',
          DocumentFolder: 'Document folder',
          UseForAutoSave: 'Use for auto save',
          MarketplaceAccounts: 'Marketplace accounts',
          PostingAmount: 'Posting amount',
          AmountType: 'Amount type',
          StandartAccountCharts: 'Standart Account Charts',
          AddNewScenariosDisabled: 'All scenarios have already been selected',
          CustomerCountries: 'Customer countries',
          Vat: 'Vat',
          AccountChart: 'Account chart',
          IntraCommunityInvoices: 'Intra-community invoices (only for EU)',
          NewScenarioForOtherCountries: '+ new scenario for other countries',
          NewScenarioForThisCountries: '+ new scenario for this countries',
          DeleteModal: {
            title: 'Upload template deleting',
            text: 'Are you sure? The selected upload template will be deleted!',
          },
          Description: {
            Title: 'Upload template',
            Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque id labore quod',
          },
          BlockDescription: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque id labore quod',
        },
        AccountChart: {
          Description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum, praesentium.',
          AccountChartModal: {
            Title: 'Account chart',
            Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque id labore quod',
            Form: {
              Title: 'Title',
              Active: 'Active',
            },
          },
          AccountChartSettingModal: {
            Title: 'Account chart setting',
            Form: {
              Country: 'Country',
              AccountChartCategory: 'Account chart category',
              AccountChart: 'Account chart',
            },
            AccountChartSettingConfirmDialog: {
              Title: 'Account chart setting',
              Text:
                'Please confirm the account chart setting. You cannot subsequently change selected account chart and the selected country - {0}',
            },
            AccountChartsPreviewModal: {
              Title: 'Account chart preview',
            },
          },
          NoAccountChartSettingMessage: {
            Message: 'There are no account chart settings selected. Please set your settings!',
            Settings: 'Settings',
          },
        },
        ScanDocuments: {
          Description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum, praesentium.',
          DeleteAfterUpload: 'Delete after upload',
          ScanRestrictionMessage: 'Document scanning only works in the desktop version',
        },
      },
      WarehouseSettings: {
        Title: 'Warehouse',
        WarehouseTypes: {
          Title: 'Warehouse types',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic quae sit vero.',
          Create: 'Create new warehouse type',
          Update: 'Update warehouse type',
          Description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt, ipsum.',
          Name: 'Warehouse type name',
          Color: 'Type color',
          Active: 'Active',
          ParamsText: '',
          ThirdPartyFulfillment: 'Third-party fulfillment service',
          IncomingAllowed: 'Goods incoming allowed',
          TransferAllowed: 'Goods transfer allowed',
          ProduceAllowed: 'Goods for produce allowed',
          ShippingAllowed: 'Goods shipping allowed',
          DeleteModal: {
            Title: 'Deleting a warehouse type',
            Text: 'Are you sure? The selected warehouse type will be deleted permanently!',
          },
        },
        WarehouseLocations: {
          Title: 'Warehouse locations',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic quae sit vero.',
          Create: 'Create new warehouse location',
          Update: 'Update warehouse location',
          Description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt, ipsum.',
          WarehouseName: 'Warehouse name',
          Active: 'Active',
          Mandats: 'Mandats',
          WarehouseTypes: 'Warehouse types',
          Address: 'Address',
          Company: 'Company',
          Name: 'Name',
          Additional: 'Additional',
          Street: 'Street',
          ZIPCity: 'ZIP/City',
          County: 'Country',
          DeleteModal: {
            Title: 'Deleting a warehouse location',
            Text: 'Are you sure? The selected warehouse location will be deleted permanently!',
          },
        },
        WarehouseTemplates: {
          Title: 'Warehouse templates',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic quae sit vero.',
          TemplateModal: {
            Create: 'Create new warehouse template',
            Update: 'Update warehouse template',
            Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic quae sit vero.',
            Name: 'Template name',
            List: 'List',
            Template: 'Template',
            NewUnit: 'Create new unit',
          },
          DeleteTemplate: {
            Title: 'Deleting a warehouse template',
            Text: 'Are you sure? The selected warehouse template will be deleted permanently!',
          },
          UnitModal: {
            Create: 'Create new unit',
            Update: 'Update unit',
            Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic quae sit vero.',
            Name: 'Unit name',
          },
          DeleteUnit: {
            Title: 'Deleting a warehouse template unit',
            Text: 'Are you sure? The selected warehouse template unit will be deleted permanently!',
          },
        },
      },
      Account: {
        RegionalSettings: {
          Inputs: {
            TimeZone: 'Time zone',
            TimeFormat: 'Time format',
            DateFormat: 'Date format',
            FirstDayOfTheWeek: 'First day of the week',
            FirstWeekOfTheYear: 'First week of the year',
            StartOfTheFinancialYear: 'Start of the financial year',
          },
          Title: 'Regional settings',
          CalendarSettings: {
            TimeZone: 'Time zone',
            TimeFormat: 'Time format',
            DateFormat: 'Date format',
            FirstDayOfTheWeek: 'First day of the week',
            FirstWeekOfTheYear: 'First week of the year',
            StartOfTheFinancialYear: 'Start of the financial year',
          },
          CurrencySettings: {
            Title: 'Currencies',
            DeleteModal: {
              title: 'Delete currency',
              text: 'The selected currency will be deleted!',
              error: "You can't delete the standard currency. Set the new standard currency first.",
            },
          },
          NumbersFormat: 'Numbers format',
        },
        PaymentMethods: {
          Title: 'Payment Methods',
          Buttons: {
            Save: 'Save',
            Cancel: 'Cancel',
            AddPaymentMethod: '+ Add payment method',
          },
          PaymentMethodsForm: {
            ModalHeader: 'Payment Method',
            SelectPaymentMethod: 'Select Payment Method',
            DirectDebitForm: {
              Header: 'Direct Debit',
              AccountHolderFirstName: 'Account Holder First Name',
              Iban: 'IBAN',
              Bic: 'BIC',
              BankName: 'Bank Name',
              Company: 'Company Name',
              Name: 'First Name',
              LastName: 'Last Name',
              HouseNo: 'House',
              ZipCity: 'Zip/City',
              Country: 'Country',
              Street: 'Street',
              AccountHolderName: 'Account Holder Name',
              CustomerNo: 'Customer Number',
              CompanyName: 'Company/Name',
              ForPermanentDirectDebit: 'For regular payments',
              ForTemporaryDirectDebit: 'For temporary payments',
              Address: 'Address',
              StreetHouse: 'Street/House',
              CreditorId: 'Creditor Id',
              NameLastName: 'Name/Last name',
              Agreement:
                'Collect payments from my account by direct debit. At the same time, I instruct my bank to redeem the direct debits drawn from <b>{0}</b> on my account.<br />Note: I can request reimbursement of the debited amount within eight weeks, starting with the debit date. Applicable in this regard by the contract with my bank conditions.',
              PaymentMethodSwitchedToBankTransfer: 'Your payment method is switched to Bank Transfer',
              PaymentMethodSwitchedToDirectDebit: 'Your payment method is switched to Direct Debit',
              CityDate: 'City, Date',
              Signature: 'Signature',
            },
          },
          PaymentMethodsList: {
            NoPaymentMethods: "You don't have any payment method.",

            DirectDebit: {
              DirectDebitNumber: 'Direct Debit {0}.',
              CreateDate: 'Create date: ',
              IBAN: 'IBAN: ',
              DeleteModalTitle: 'Delete Direct Debit',
              DeleteModalText: 'Are you shure you want to remove your Direct Debit?',
            },
          },
        },
      },
      LogisticSettings: {
        Title: 'Logistic',
        Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum, praesentium.',
        LogisticInfo: {
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum, praesentium.',
          Active: 'active contract',
          Inactive: 'inactive contract',
          Connect: 'Connect logistic contract',
        },
        LogisticModal: {
          Title: 'Logistic',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facere, tempora.',
          Mandat: 'Mandat',
          Country: 'Country',
          Warehouse: 'Warehouse',
          Address: 'Address',
          Active: 'Active contract',
          Inactive: 'Inactive contract',
        },
        DeleteModal: {
          Title: 'Deleting logistic contract',
          Message: 'Are you sure? The selected contract will be deleted!',
        },
        ConfirmConnectModal: {
          Title: 'Check connection',
          Text:
            'To check the connection, click on the “Check connection“ button. If you want to check the connection later, click on “Cencel“',
          CheckConnection: 'Check connection',
        },
        CheckConnection: {
          Title: 'Check connection result',
          TextSuccess: 'Success',
          TextError: 'Error',
        },
        ConnectModal: {
          Title: 'Select logistic',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facere, tempora.',
          LogisticType: 'Logistic type',
        },
        LogisticContractModal: {
          Title: 'Enter contract settings for {0}',
          Tooltip: 'All data on the server will be encrypted',
          AccountSettingsForm: 'Account',
          ShippingServiceForm: 'Shipping Service',
          PrinterSettingsForm: 'Printer',
          Name: 'Name',
          AccountNumber: 'Account number',
          UserName: 'User name',
          Password: 'Password',
          CalendarNotFilled: 'For these shipping methods, you must select a date',
          PortokasseUsername: 'Portokasse username',
          PortokassePassword: 'Portokasse password',
          ProductMandat: 'Product mandat',
          ProductUsername: 'Product username',
          ProductPassword: 'Product password',
          PrintPreview: 'Print preview',
          AssignPrinter: 'Assign printer',
          LabelFormat: 'Label format',
          ReferenceType: 'Reference type',
          SetStatus: 'Set status',
          By: 'By',
        },
      },
      EmailTemplateSettings: {
        Description: {
          Title: 'Email templates',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum, praesentium.',
        },
        Title: 'Title',
        Sharing: {
          TemplateSharingType: 'Template sharing',
        },
        Email: {
          Title: 'Email',
          Subject: 'Subject',
          Cc: 'Cc',
          Bcc: 'Bcc',
        },
        Sending: {
          Title: 'Sending',
          PreviewBeforeSending: 'Preview before sending',
        },
        Compartment: {
          Title: 'Compartment',
          Sales: 'Sales',
          Purchases: 'Purchases',
          CustomerOrSupplier: 'Customer/Supplier',
          Warehouse: 'Warehouse',
        },
        AttachFiles: {
          Title: 'Attach files',
          SelectFilesForUpload: 'Select files for upload',
        },
        Automation: {
          Title: 'Automation',
          DoNotUse: 'Do not use',
          UseToSendDeliveryNote: 'Use to send delivery note',
          UseToSendSale: 'Use to send sale',
          UseToSendPurchase: 'Use to send purchase',
          UseToSendInvoice: 'Use to send invoice',
          UseToSendCancelInvoice: 'Use to send cancel invoice',
        },
      },
      SynchronizeSettings: {
        Title: 'Synchronize',
        Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum, praesentium.',
        OrdersUpdateAfterSynchronization: 'Orders update after synchronization',
      },
      FeedbacksSettings: {
        Title: 'Feedbacks',
        Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum, praesentium.',
        SalesForm: {
          Title: 'Sale',
          Fields: {
            AutomaticTapeCreationFeedbackSettings: 'Automatic creation of the feedback by status',
            SelectYourLanguage: 'Select your languages',
            FeedbackText: {
              Title: 'Feedback texts:',
              Texts: {
                Text1: 'Feedback text1',
                Text2: 'Feedback text2',
                Text3: 'Feedback text3',
                Text4: 'Feedback text4',
                Text5: 'Feedback text5',
                Text6: 'Feedback text6',
                Text7: 'Feedback text7',
                Text8: 'Feedback text8',
                Text9: 'Feedback text9',
                Text10: 'Feedback text10',
              },
            },
          },
        },
      },
      OrderAutomationSettings: {
        Title: 'Order automation settings',
        Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum, praesentium.',
        OrderAutomationSettingsForm: {
          DaysBeforeArchiving: 'Automatic archiving after, days',
          OrderStatus: 'Order status',
          Accounts: 'Accounts',
        },
      },
      MandatsSettings: {
        Title: 'Mandats',
        Steps: {
          Mandat: {
            Title: 'Mandat',
            Text: 'You can think of a name for the new mandate',
          },
          LegalNotice: {
            Title: 'Legal Notice',
            Text: 'Fill in the legal notice fields for the {0} mandate',
          },
          Documents: {
            Title: 'Legal Documents',
            Text: 'Fill in the documents fields for the {0} mandate',
          },
          Email: {
            Title: 'Email setting',
            Text: 'Fill in the Email setting fields for the {0} mandate',
          },
          FreeText: {
            Title: 'Free texts',
            Text: 'Fill in the Free texts fields for the {0} mandate',
          },
        },
        Mandat: {
          MandatName: 'Mandat name',
        },
        DeleteModal: {
          title: 'Deleting a mandat',
          text: 'Are you sure? The selected mandat will be deleted!',
        },
      },
      ProductsSettings: {
        Title: 'Products',
        Tabs: {
          Categories: 'Categories',
        },
        Categories: {
          Description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore, minima.',
          CreateCategory: 'Create category',
          CategoryModal: {
            AddCategory: 'Add product category',
            UpdateCategory: 'Update product category',
            Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti, repellat.',
            Name: 'Category name',
            Logo: 'Category logo',
            SelectLogo: 'Select logo to upload',
          },
          DeleteCategoryModal: {
            Title: 'Delete product category',
            Text: 'Are you sure ? The selected category will be deleted permanently!',
          },
        },
      },
    },
    Suppliers: {
      Suppliers: 'Suppliers',
      Tabs: {
        Titles: {
          General: 'General',
          BankData: 'Bank Data',
          OnlineArchive: 'Online Archive',
          History: 'History',
        },
        General: {
          Mandat: 'Mandat',
          SupplierNumber: 'Supplier Number',
          FavoriteInAddressBook: 'Favorite In Address book',
          CreateShippingLabel: 'Create shipping label',
          CreateReturnLabel: 'Create return label',
          VatNumber: 'VAT ID',
        },
      },
      Modal: {
        Titles: {
          General: 'General',
          Bank: 'Bank',
          Documents: 'Documents',
        },
        General: {
          AddDifferentShippingAddress: '+ Add different shipping address',
          RemoveDifferentShippingAddress: '- Remove different shipping address',
          AddSecondContactPerson: '+ Add second contact person',
          RemoveSecondContactPerson: '- Remove second contact person',
        },
        Description: {
          Title: 'Supplier',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium error itaque laudantium.',
        },
      },
      Forms: {
        AddressForm: {
          Mandat: 'Mandat',
          CurrentInvoiceAddress: 'Current Invoice Address',
          CurrentShippingAddress: 'Current Shipping Address',
          Address: {
            Company: 'Company',
            Name: 'Name',
            Additional: 'Additional',
            Street: 'Street',
            ZIPCity: 'ZIP/City',
            County: 'Country',
            VatNumber: 'VAT ID',
          },
        },
        ContactForm: {
          FirstContactPerson: 'Contact Person 1',
          SecondContactPerson: 'Contact Person 2',
          Contact: {
            Name: 'Name',
            Email: 'E-Mail',
            Phones: 'Phones',
            Notes: 'Notes',
          },
        },
        BankDataForm: {
          BankData: 'Bank Data',
          Data: {
            Name: 'Name',
            IBAN: 'IBAN',
            BIC: 'BIC',
            BankName: 'Bank name',
          },
        },
      },
      ModalDialogs: {
        DeleteSupplierModal: {
          Title: 'Deleting supplier',
          Text: 'Are you sure? The selected supplier will be deleted!',
        },
      },
    },
    Customers: {
      Customers: 'Customers',
      Tabs: {
        Titles: {
          General: 'General',
          BankData: 'Bank Data',
          OnlineArchive: 'Online Archive',
          History: 'History',
        },
        General: {
          Mandat: 'Mandat',
          CustomerNumber: 'Customer Number',
          FavoriteInAddressBook: 'Favorite In Address book',
          CreateShippingLabel: 'Create shipping label',
          CreateReturnLabel: 'Create return label',
          VatNumber: 'VAT ID',
        },
      },
      Modal: {
        Titles: {
          General: 'General',
          Bank: 'Bank',
          Documents: 'Documents',
        },
        General: {
          AddDifferentShippingAddress: '+ Add different shipping address',
          RemoveDifferentShippingAddress: '- Remove different shipping address',
          AddSecondContactPerson: '+ Add second contact person',
          RemoveSecondContactPerson: '- Remove second contact person',
        },
        Description: {
          Title: 'Customer',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium error itaque laudantium.',
        },
      },
      Forms: {
        AddressForm: {
          Mandat: 'Mandat',
          CurrentInvoiceAddress: 'Current Invoice Address',
          CurrentShippingAddress: 'Current Shipping Address',
          Address: {
            Company: 'Company',
            Name: 'Name',
            Additional: 'Additional',
            Street: 'Street',
            ZIPCity: 'ZIP/City',
            Country: 'Country',
            VatNumber: 'VAT ID',
          },
        },
        ContactForm: {
          FirstContactPerson: 'Contact Person 1',
          SecondContactPerson: 'Contact Person 2',
          Contact: {
            Name: 'Name',
            Email: 'E-Mail',
            Phones: 'Phones',
            Notes: 'Notes',
          },
        },
        BankDataForm: {
          BankData: 'Bank Data',
          Data: {
            Name: 'Name',
            IBAN: 'IBAN',
            BIC: 'BIC',
            BankName: 'Bank name',
          },
        },
      },
      ModalDialogs: {
        DeleteCustomerModal: {
          Title: 'Deleting customer',
          Text: 'Are you sure? The selected customer will be deleted!',
        },
      },
    },
    Logistics: {
      Shipping: {
        Shipping: 'Shipping',
        Marketplace: 'Marketplace',
        Logistic: 'Logistic',
        CustomerNo: 'Customer No',
        OrderNo: 'Order No',
        DeliveryNoteNo: 'Delivery Note No',
        InvoiceNo: 'Invoice No',
        OrderDate: 'Order Date',
        ShippingDate: 'Shipping Date',
        ShippedDate: 'Shipped Date',
        ValueOfGoodsPackage1: 'Value of goods Package 1:',
        Product: 'Product:',
        ShippingPricePackage: 'Shipping Price Package {0}:',
        ValueOfGoodsPackage: 'Value of goods Package {0}',
        TotalShipping: 'Total Shipping:',
        Sender: 'Sender',
        Receiver: 'Receiver',
        Company: 'Company',
        Name: 'Name',
        Additional: 'Additional',
        Street: 'Street/No',
        ZIPCity: 'ZIP/City',
        Country: 'Country',
        Email: 'E-Mail',
        Phone: 'Phone',
        CreateAdditionalLabel: 'Create additional label',
        CreateRetourLabel: 'Create retour label',
        CancelShipment: 'Cancel shipment',
        LabelPrint: 'Label Print',
        LabelViaEmailSend: 'Label via E-mail send',
      },
      Retour: {
        Retour: 'Retour',
      },
    },
    Users: {
      UserInfo: {
        Tabs: {
          CustomerDetails: 'Customer Details',
          TransactionOverview: 'Transaction Overview',
          Invoices: 'Invoices',
          PaymentInformation: 'Payment Information',
          OrderBatches: 'Order Batches',
          Subscriptions: 'Subscriptions',
          Dunning: 'Dunning',
          FileOrNotes: 'Files/Notes',
          ShoppingCard: 'Shopping card',
        },
        UserDetails: {
          CustomerNumber: 'Customer Number',
          MarketCode: 'Market Code',
          Country: 'Country',
          Comments: 'Comments',
          VatId: 'VAT ID',
          Email: 'E-Mail',
          PasswordReset: 'Password reset',
          InvoiceLanguage: 'Invoice Language',
          InvoiceDispatchMethod: 'Invoice Dispatch Method',
          InternalCode: 'PO Number',
          BillingCycle: 'Billing Cycle',
          PaymentDuePeriode: 'Payment Due Periode',
          CustomerInDunning: 'Customer in dunning',
          TotalInDunning: 'Total in dinning',
          DunningStop: 'Dunning stop',
          LastDunningEvent: 'Last dunning event',
          LastDunningEventDate: 'Last dunning event date',
          NextDunningEvent: 'Next dunning event',
          NextDunningEventDate: 'Next dunning event date',
          RefinancePlan: 'Refinance plan',
          AccountSegment: 'Account Segment',
          AccountCategory: 'Account Category',
          AccountSubcategory: 'Account Subcategory',
          RateClass: 'Rate Class',
          EditableLocalizations: 'Editable localizations',
          ActualSubscripion: 'Actual Subscripion',
          PrepaidOrPostpaid: 'Prepaid/Postpaid',
          PaymentMethod: 'Payment Method',
          MaxCredit: 'Max Credit',
          Balance: 'Balance',
          Currency: 'Currency',
          Status: 'Status',
          ActivDate: 'Activ date',
          InactivDate: 'Inactiv Date',
          DisconnectReason: 'Disconnect Reason',
          RemarketingAllowed: 'Remarketing allowed',
          BankAccountHolder: 'Bank Account Holder',
          Iban: 'IBAN',
          Bic: 'BIC',
          BankName: 'Bank Name',
          IbanValidated: 'IBAN Validated',
          CurrentInvoiceAddress: 'Current Invoice Address',
          CurrentShippingAddress: 'Current Shipping Address',
          Company: 'Company',
          Name: 'Name',
          Additional: 'Additional',
          Street: 'Street/No',
          ZipOrCity: 'ZIP/City',
        },
        Toasts: {
          UpdateUserToast: {
            title: 'Update user',
            text: 'The user has been updated. ',
          },
        },
      },
    },
    WarehouseLogistics: {
      Tabs: {
        Logistics: 'Warehouse Logistics',
        SellingPrices: 'Selling Prices',
        SettingsProducts: 'Settings Products',
        LogisticProductGenerator: 'Logistic Product Generator',
        CountryZones: 'Logistic Country Zones',
        ApplicationAccess: 'Application Access',
        LogisticsLogoSettings: 'Logistics logo settings',
      },
      Description: {
        UserProduct: {
          Title: 'Product',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque magni pariatur veritatis!',
        },
        SubProduct: {
          Title: 'Sub product package',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque magni pariatur veritatis!',
        },
        Additional: {
          Title: 'Additional',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque magni pariatur veritatis!',
        },
        Prices: {
          Title: 'Prices',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque magni pariatur veritatis!',
        },
      },
      LogisticUserProduct: {
        ProductType: 'Type',
        AdditionaServiceType: 'Title',
        International: 'National/International',
        CountriesSender: 'Countries Sender',
        CountriesRecipient: 'Countries Recipient',
        UserLogistic: 'Display Logistic',
        UserProduct: 'Display Product',
        UserSubProduct: 'Display Subproduct',
        ShippingService: 'Shipping Service',
        ShippingMethods: 'Shipping Methods',
        Title: 'Product',
      },
      LogisticUserSubProduct: {
        AllowedPackageTypes: 'Allowed Package Types',
        MeasurePackageType: 'Measure Package Type',
        MeasureMinCm: 'Min, cm',
        MeasureMaxCm: 'Max, cm',
        Length: 'Length',
        Width: 'Width',
        Height: 'Height',
        MaxDeterminingGirth: 'Max Determining Girth',
        MaxShortestLongestSize: 'Max Shortest + Longest Size',
        WeightMinKg: 'Min, kg',
        WeightMaxKg: 'Max, kg',
        Weight: 'Weight',
        MaxInsurance: 'Max Insurance',
        Title: 'Sub product package',
      },
      LogisticUserSubProductAdditional: {
        OriginalLogistic: 'Original Logistic',
        OriginalProduct: 'Original Product',
        OriginalSubproduct: 'Original Subproduct',
        Duty: 'Duty',
        Decalred: 'Decalred',
        PrinterRequired: 'Printer Required',
        SkuBilliable: 'SKU Billiable',
        SkuNoBilliable: 'SKU No Billiable',
        Title: 'Additional',
      },
      LogisticUserProductPrices: {
        PurchasePrice: 'Purchase price',
        PurchasePercent: 'Purchase percent',
        SalesPrice: 'Sales price',
      },
      SettingsProducts: {
        LogisticTypes: 'Logistic',
        ProductTypes: 'Product',
        AdditionalServiceTypes: 'Additional service',
        SubProductTypes: 'Subproduct',
        ShippingMethodTypes: 'Shipping Methods',
        PackageTypes: 'Package Types',
        Types: {
          GenProductType: {
            'SOME 10:00': 'SOME 10:001',
            locExpress: 'locExpress111',
          },
          GenAdditionalServiceType: {
            loc1000: 'loc1000bb',
          },
          GenLogisticType: {
            locDPD: 'locDPDbb',
          },
          GenSubProductType: {
            locXS: 'locXSbb',
          },
          GenPackageType: {
            locPacket: 'locPacketbb',
          },
        },
      },
      TemplatesProducts: {
        OriginalLogistic: 'Original Logistic',
        Name: 'Name',
        ProductType: 'Type',
        Title: 'Title',
        International: 'National/International',
        CountriesRecipient: 'Countries Recipient',
        OriginalProduct: 'Original Product',
        OriginalSubproduct: 'Original Subproduct',
        ShippingService: 'Shipping Service',
        ShippingMethods: 'Shipping Methods',
        AllowedPackageTypes: 'Allowed Package Types',
        MeasurePackageTypes: 'Measure Package Types',
        MaxInsurance: 'Max Insurance',
        PrinterRequired: 'Printer Required',
        Duty: 'Duty',
        Decalred: 'Decalred',
        BillingActive: 'Product/Add.Service for Billing active',
        NonBillingActive: 'Product/Add.Service for Non-Billing active',
        Buttons: {
          Delete: 'Delete',
          Save: 'Save',
          Update: 'Update',
        },
      },
      GeneratorProducts: {
        OriginalLogistic: 'Original Logistic',
        Name: 'Name',
        ProductType: 'Type',
        Title: 'Title',
        International: 'National/International',
        CountriesRecipient: 'Countries Recipient',
        OriginalProduct: 'Original Product',
        OriginalSubproduct: 'Original Subproduct',
        ShippingService: 'Shipping Service',
        ShippingMethods: 'Shipping Methods',
        AllowedPackageTypes: 'Allowed Package Types',
        MeasurePackageTypes: 'Measure Package Types',
        MaxInsurance: 'Max Insurance',
        PrinterRequired: 'Printer Required',
        Duty: 'Duty',
        Decalred: 'Decalred',
        BillingActive: 'Product/Add.Service for Billing active',
        NonBillingActive: 'Product/Add.Service for Non-Billing active',
        Buttons: {
          Delete: 'Delete',
          Save: 'Save',
          UpdateProducts: 'Update products',
        },
        ModalWindows: {
          DeleteModalWindow: {
            Title: 'Deleting',
            Message: "Do you want to delete '{0}'?",
          },
          SaveModalWindow: {
            Title: 'Saving',
            Message: "Do you want to save '{0}'?",
          },
          UpdatingProductsWindow: {
            Title: 'Updating products',
            Message: "Do you want to updating products by '{0}'?",
          },
          UserProductDuplicatedModal: {
            Title: 'Duplicated user products',
          },
          UserProductDuplicatedWindow: {
            Title: 'Duplicated user products',
          },
        },
        Statuses: {
          NotSaved: 'Not Saved',
          NeedForUpdate: 'Need for update',
          Updated: 'Updated',
          Active: '{0} Active',
          Inactive: '{0} Inactive',
          RemovedManually: '{0} Removed manually',
        },
        CommonStatuses: {
          NeedForUpdate: '{0} Scenarios need for update ({1})',
          Active: '{0} Active Products ({1})',
          Inactive: '{0} Product(s) inactive ({1})',
          RemovedManually: '{0} Product(s) removed manually ({1})',
        },
      },
      ModalDialogs: {
        DeletingConfirmationModal: {
          Title: 'Deleteing user product',
          Message: 'Do you want to delete user product with ID={0}',
        },
      },
      ModalWindows: {
        DeletingConfirmationWindow: {
          Title: 'Deleteing user product',
          Message: 'Do you want to delete user product with ID={0}?',
        },
      },
      GeneratorCountryZones: {
        LogisticCountries: 'Logistic Countries',
        Name: 'Name',
        OriginalLogistic: 'Original Logistic',
        CountriesRecipient: 'Countries Recipient',
        Buttons: {
          Delete: 'Delete',
          Save: 'Save',
        },
        ModalWindows: {
          DeleteModalWindow: {
            Title: 'Deleting',
            Message: "Do you want to delete '{0}'?",
          },
          SaveModalWindow: {
            Title: 'Saving',
            Message: "Do you want to save '{0}'?",
          },
        },
      },
      ApplicationAccess: {
        LogisticCountries: 'Logistic Countries',
        Products: 'Products',
        AccountNumber: 'Account number',
        UserName: 'User Name',
        Password: 'Password',
        Onec4APartnerId: 'oneC4A PartnerId',
        OneC4ASecret: 'oneC4A Secret',
        PortokasseUserName: 'Portokasse UserName',
        PortokassePassword: 'Portokasse Password',
        ProductMandatId: 'Product Mandat Id',
        ProductUserName: 'Product User Name',
        ProductPassword: 'Product Password',
      },
      LogisticsLogoSettings: {
        Title: 'Logistics logo settings',
        Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem, sequi',
        LogisticLogo: 'Logistic logo',
        LogisticType: 'Logistic type',
        CreateNewSetting: 'Create new setting',
        DeleteModal: {
          Title: 'Are you sure?',
          Text: 'The selected settings will be deleted permanently!',
        },
      },
    },
    Subscriptions: {
      Tabs: {
        Subscriptions: 'Subscriptions',
        OneOffPrices: 'One-Off prices',
        RecurringPrices: 'Recurring prices',
      },
      ModalDialog: {
        title: 'Delete subscription',
        text: 'Do you really want to delete the subscription?',
      },
      GeneralInfo: {
        Sku: 'Sku',
        SubscriptionName: 'Subscription name',
        Amount: 'Amount',
        UserInfo: 'Customer info',
        UserSegments: 'Customer segments',
        UserCategories: 'Customer categories',
        UserSubcategories: 'Customer subcategories',
        ContractTerm: 'Contract term, month(s)',
        CancellationPeriod: 'Cancellation period, month(s)',
        IsRecurring: 'Is recurring',
        IsActiveForSales: 'Is active for sales',
        ActiveForSalesByCountries: 'Active for sales by countries:',
        Functions: 'Functions:',
        Countries: 'Countries',
        IsSpecialOffer: 'Is special offer',
        IsDefault: 'Is default',
        Vat: 'Vat, %',
        TrialOrDiscount1: 'Trial or discount 1',
        TrialPeriodNumber1: 'Trial period number 1',
        DiscountPeriodNumber1: 'Discount period number 1',
        TrialOrDiscount2: 'Trial or discount 2',
        TrialPeriodNumber2: 'Trial period number 2',
        DiscountPeriodNumber2: 'Discount period number 2',
        SubscriptionType: 'Subscription type',
        SubscriptionSubtype: 'Subscription subtype',
        SubscriptionPosition: 'Subscription position',
        PeriodPriceDisplay: 'Period price display',
        PeriodAmountDisplay: 'Period amount display',
        RecurringPeriod1: 'Recurring period 1, month(s)',
        RecurringPeriod2: 'Recurring period 2, month(s)',
      },
      Description: {
        Subscriptions: {
          Title: 'Subscriptions',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem, sequi?',
        },
        OneOffPrices: {
          Title: 'One-Off prices',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem, sequi?',
        },
        RecurringPrices: {
          Title: 'Recurring prices',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem, sequi?',
        },
      },
    },
    DigitalProducts: {
      Tabs: {
        DigitalProducts: 'Digital products',
        Prices: 'Prices',
        Settings: 'Settings',
      },
      ModalDialog: {
        title: 'Delete digital product',
        text: 'Do you really want to delete the digital product?',
      },
      GeneralInfo: {
        Sku: 'Sku',
        Name: 'Name',
        NumberOfUses: 'Number of uses',
        Function: 'Function',
        Templates: 'Templates',
        IsActiveForSales: 'Is active for sales',
        IsIntern: 'Is intern',
        Countries: 'Countries',
        ActiveForSalesByCountries: 'Active for sales by countries:',
        Image: 'Image',
        SelectImageToDownload: 'Select image to download',
        Description: 'Description',
        Vat: 'Vat, %',
      },
      Description: {
        DigitalProducts: {
          Title: 'Digital products',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem, sequi?',
        },
        Prices: {
          Title: 'Prices',
          Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem, sequi?',
        },
      },
      Settings: {
        Title: 'Template settings',
        Text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem, sequi',
        CreateByRegister: 'Create by register',
        Template: 'Template',
        Countries: 'Countries',
        CreateNewSetting: 'Create new setting',
        DeleteSettingsModal: {
          Title: 'Are you sure?',
          Text: 'The selected template settings will be deleted permanently!',
        },
      },
    },
    Accounting: {
      Tabs: {
        AccountChart: 'Account chart',
        Vat: 'Vat',
      },
      AccountChart: {
        AccountChartCountries: {
          MarketUserCountries: 'Market user countries',
        },
        AccountChartModal: {
          Title: 'Account chart',
          Form: {
            Title: 'Title',
            Values: {
              Title: 'Values',
              Active: 'Active',
              Value: 'Value',
            },
          },
        },
        AccountChartTableControls: {
          EditColumns: 'Edit columns',
        },
        AccountChartTypesModal: {
          Title: 'Account chart columns',
          Form: {
            ColumnName: 'Column name',
          },
        },
      },
      Vat: {
        VatRateCountries: {
          Countries: 'Countries',
        },
        VatRateForm: {
          StandardRate: 'Standard VAT rate, %',
          ReducedRate: 'Reduced VAT rate, %',
          SpecialRate1: 'Special VAT rate 1, %',
          SpecialRate2: 'Special VAT rate 2, %',
          SpecialRate3: 'Special VAT rate 3, %',
          NotUsingVat: 'This country does not use the VAT system',
        },
        Messages: {
          SaveVatRatesSuccess: {
            Title: 'Vat rate settings',
            Text: 'Vat rate settings saved successfully!',
          },
        },
      },
    },
    Administrator: {
      LanguageEditor: {
        Title: 'Language resources',
        La: '!L-A',
        De: 'de-DE',
        En: 'en-EN',
        Ru: 'ru-RU',
        Descriptions: '!Descriptions',
        LastUpdate: 'Last update',
        Toasts: {
          SaveToast: {
            Title: 'Language editor',
            Text: 'Translations are saved!',
          },
          AddErrorToast: {
            Title: 'Add translation key',
            Text: 'Translation key $ already exists',
          },
          EditErrorToast: {
            Title: 'Edit translation key',
            Text: 'Translation key $ already exists',
          },
          SaveSettingsToast: {
            Title: 'Language editor settings',
            Text: 'Settings are saved!',
          },
          NoNewTranslationsToast: {
            Title: 'Language editor',
            Text: 'No new or changed translations!',
          },
        },
        Modals: {
          SaveModal: {
            Title: 'Saving',
            Text: 'Do you want to save translations?',
          },
          DeleteModal: {
            Title: 'Deleting',
            Text: 'Do you want to delete item?',
          },
        },
        ContextMenu: {
          Add: {
            Key: 'add',
            Value: 'Add',
          },
          Edit: {
            Key: 'edit',
            Value: 'Edit',
          },
          Delete: {
            Key: 'delete',
            Value: 'Delete',
          },
        },
        Tabs: {
          Erp: 'ERP system translations',
          Website: 'Website translations',
          Product: 'Product translations',
          Settings: 'Settings',
        },
        Settings: {
          VisibleLanguages: 'Visible languages',
          MarkLanguages: 'Mark languages',
          SelectableLanguages: 'Selectable languages',
        },
      },
      Calendar: {
        Title: 'Calendar',
        Tabs: {
          Holidays: 'Holidays',
        },
        Holidays: {
          HolidaysCountries: {
            Title: 'Countries',
          },
          CountryHolidaysInfo: {
            Buttons: {
              AddYearButton: '+ Add year',
              AddHolidayButton: '+ Add holiday',
            },
            Modals: {
              CountryHolidayModal: {
                Title: 'Country holiday',
              },
              DeleteHolidayDialog: {
                Title: 'Delete holiday',
                Text: 'Are you sure you want to delete holiday?',
              },
            },
            CountryHolidayForm: {
              Name: 'Name',
              Date: 'Date',
            },
          },
        },
      },
    },
    Payments: {
      SuccessPayment: {
        PaymentWasSuccessful: 'Payment was successful',
        GoToSales: 'Go to sales',
      },
      ErrorPayment: {
        ErrorInPayment: 'Error in payment',
        GoToShop: 'Go to shop',
      },
    },
    Billing: {
      Tabs: {
        ShoppingCard: 'Shopping card',
      },
    },
    Contacts: {
      Suppliers: 'Customers',
      Customers: 'Suppliers',
      Forms: {
        AddressForm: {
          Address: {
            Additional: 'Additional',
            Company: 'Company',
            County: 'Country',
            Name: 'Name',
            Street: 'Street',
            VatNumber: 'VAT ID',
            ZIPCity: 'ZIP/City',
          },
          CurrentInvoiceAddress: '',
          CurrentShippingAddress: '',
          Mandat: 'Mandat',
        },
        BankDataForm: {
          BankData: 'Bank Data',
          Data: {
            BankName: 'Bank name',
            BIC: 'BIC',
            IBAN: 'IBAN',
            Name: 'Name',
          },
        },
        ContactForm: {
          Contact: {
            Name: 'Name',
            Email: 'E-Mail',
            Notes: 'Notes',
            Phones: 'Phones',
          },
          FirstContactPerson: 'Contact Person 1',
          SecondContactPerson: 'Contact Person 2',
        },
      },
      Modal: {
        General: {
          AddDifferentShippingAddress: '+ Add different shipping address',
          AddSecondContactPerson: '+ Add second contact person',
          RemoveDifferentShippingAddress: '- Remove different shipping address',
          RemoveSecondContactPerson: '- Remove second contact person',
        },
        Titles: {
          Bank: 'Bank',
          Documents: 'Documents',
          General: 'General',
        },
      },
      Tabs: {
        General: {
          CreateReturnLabel: 'Create return label',
          CreateShippingLabel: 'Create shipping label',
          CustomerNumber: 'Customer Number',
          FavoriteInAddressBook: 'Favorite In Address book',
          SupplierNumber: 'Supplier Number',
          VatNumber: 'VAT ID',
          Mandat: 'Mandat',
        },
        Titles: {
          BankData: 'Bank Data',
          General: 'General',
          History: 'History',
          OnlineArchive: 'Online Archive',
        },
      },
    },
  },
  TableColumns: {
    OrderColumns: {
      id: 'ID',
      mpOrderNumber: 'Marketplace order number',
      extendedOrderId: 'Extended order ID',
      orderType: 'Order type',
      orderStatus: 'Order status',
      cancelStatus: 'Cancel status',
      buyerMarketplaceAccount: 'Buyer marketplace account',
      sellerMarketplaceAccount: 'Seller marketplace account',
      accountName: 'Account name',
      buyerEmail: 'Buyer E-Mail',
      customerNumber: 'Customer number',
      refundAmount: 'Refund amount',
      refundStatus: 'Refund status',
      refundType: 'Refund type',
      refundTime: 'Refund time',
      priceBruttoWithShipping: 'Total amount brutto with shipping',
      amountPaid: 'Amount paid',
      shippingPrice: 'Total shipping amount brutto',
      actualShippingCostNetto: 'Actual shipping cost net',
      openAmount: 'Open amount',
      currency: 'Currency',
      paymentStatus: 'Payment status',
      deliveryStatus: 'Delivery status',
      paymentMethod: 'Payment method',
      orderFixFee: 'Order fix fee',
      profit: 'Profit',
      marketplacePayout: 'Marketplace payout',
      appCreatedTime: 'First synchronisation',
      appLastModifiedTime: 'Last modified',
      synchTime: 'Synchronisation time',
      mpLastModifiedTime: 'Last change at the marketplace',
      mpCreatedTime: 'Sales date',
      paidTime: 'Payment date',
      shippingMethod: 'Original shipping method',
      actualShippingMethod1: 'Actual shipping method 1',
      actualShippingMethod2: 'Actual shipping method 2',
      actualShippingMethod3: 'Actual shipping method 3',
      actualShippingMethod4: 'Actual shipping method 4',
      actualShippingMethod5: 'Actual shipping method 5',
      shippingType1: 'Shipping Type 1',
      shippingType2: 'Shipping Type 2',
      shippingType3: 'Shipping Type 3',
      shippingType4: 'Shipping Type 4',
      shippingType5: 'Shipping Type 5',
      estimatedDeliveryTimeMax: 'Estimated delivery time max',
      estimatedDeliveryTimeMin: 'Estimated delivery time min',
      handleByTime: 'Handle by time',
      actualDeliveryTime: 'Actual delivery time',
      shippingCompanyName: 'Company, validated delivery address',
      shippingFirstName: 'First name, validated delivery address',
      shippingLastName: 'Last name, validated delivery address',
      shippingAdditionalLine: 'Additional line, validated delivery address',
      shippingStreet: 'Street, validated delivery address',
      shippingHouseNo: 'House No, validated delivery address',
      shippingPostalCode: 'Postal code (ZIP), validated delivery address',
      shippingCityName: 'City, validated delivery address',
      shippingCountryName: 'Country, validated delivery address',
      shippingCountryCode: 'Country code, validated delivery address',
      shippingPhoneNumber: 'Phone number, validated delivery address',
      originalShippingCompanyName: 'Company, original shipping address',
      originalShippingFirstName: 'First name, original shipping address',
      originalShippingLastName: 'Last name, original shipping address',
      originalShippingAdditionalLine: 'Additional line, original shipping address',
      originalShippingStreet: 'Street, original shipping address',
      originalShippingHouseNo: 'House No, original shipping address',
      originalShippingPostalCode: 'Postal code (ZIP), original shipping address',
      originalShippingCityName: 'City, original shipping address',
      originalShippingCountryCode: 'Country code, original shipping address',
      originalShippingCountryName: 'Country, original shipping address',
      originalShippingPhoneNumber: 'Phone number, original shipping address',
      invoiceCompanyName: 'Company, invoice address',
      invoiceFirstName: 'First name, invoice address',
      invoiceLastName: 'Last name, invoice address',
      invoiceAdditionalLine: 'Additional line, invoice address',
      invoiceStreet: 'Street, invoice address',
      invoiceHouseNo: 'House No, invoice address',
      invoicePostalCode: 'Postal code (ZIP), invoice address',
      invoiceCityName: 'City, invoice address',
      invoiceCountryName: 'Country, invoice address',
      invoiceCountryCode: 'Country code, invoice address',
      invoicePhoneNumber: 'Phone number, invoice address',
      internalNote: 'Internal note',
      externalNote: 'External note',
      deliveryNoteNumber: 'Delivery note number',
      deliveryNoteNumberCreatedTime: 'Delivery note number created time',
      deliveryNotePrinted: 'Delivery note printed',
      deliveryNoteMailed: 'Delivery note mailed',
      orderNumber: 'Order number',
      orderNumberCreatedTime: 'Order number created time',
      orderPrinted: 'Order printed',
      orderMailed: 'Order mailed',
      currentInvoiceNumber: 'Current invoice number',
      currentInvoiceCreatedTime: 'Current invoice number created time',
      currentInvoicePrinted: 'Current invoice printed',
      currentInvoiceMailed: 'Current invoice mailed',
      currentCancelInvoiceNumber: 'Current cancel invoice number',
      currentCancelInvoiceNumberCreationTime: 'Current cancel invoice number created time',
      currentCancelInvoicePrinted: 'Current cancel invoice printed',
      currentCancelInvoiceMailed: 'Curent cancel invoice mailed',
      buyerNote: 'Buyer note',
      archived: 'Archived',
      exported: 'Exported',
      canceledNotPaid: 'Canceled, not paid',
      canceledRefunded: 'Canceled, refunded',
      orderItemId: 'Order Item ID',
      marketplaceId: 'Marketplace item number',
      transactionId: 'Marketplace transaction ID',
      ebayPlus: 'Ebay Plus',
      sku: 'SKU',
      title: 'Item',
      saleFormat: 'Sale format',
      priceNetto: 'Unit price net',
      priceBrutto: 'Unit price gross',
      totalPriceNettoWithShipping: 'Invoice position net with shipping',
      totalPriceBruttoWithShipping: 'Invoice position gross with shipping',
      totalPriceNettoWithoutShipping: 'Invoice position net without shipping',
      totalPriceBruttoWithoutShipping: 'Invoice position gross without shipping',
      salesTaxPercent: 'VAT percent',
      salesTaxTotalWithShipping: 'VAT amount per position with shipping',
      transactionVariableFee: 'Transaction variable fee',
      promotionFee: 'Promotion fee',
      quantity: 'Quantity',
      commonVat1Percent: 'Order VAT 1, %',
      commonVat1Amount: 'Order VAT 1, amount',
      commonNettoVat1Amount: 'Netto for VAT 1',
      commonVat2Percent: 'Order VAT 2, %',
      commonVat2Amount: 'Order VAT 2, amount',
      commonNettoVat2Amount: 'Netto for VAT 2',
      commonVat3Percent: 'Order VAT 3, %',
      commonVat3Amount: 'Order VAT 3, amount',
      commonNettoVat3Amount: 'Netto for VAT 3',
      commonVat4Percent: 'Order VAT 4, %',
      commonVat4Amount: 'Order VAT 4, amount',
      commonNettoVat4Amount: 'Netto for VAT 4',
      commonVat5Percent: 'Order VAT 5, %',
      commonVat5Amount: 'Order VAT 5, amount',
      commonNettoVat5Amount: 'Netto for VAT 5',
      shippedTime: 'Shipped time',
      trackingNumber: 'Tracking number',
      buyerFeedbackTime: 'Buyer feedback time',
      buyerFeedbackType: 'Buyer feedback status',
      buyerFeedbackText: 'Buyer feedback text',
      sellerFeedbackTime: 'Seller feedback time',
      sellerFeedbackType: 'Seller feedback status',
      sellerFeedbackText: 'Seller feedback text',
      purchaseNet: 'Purchase net',
      variantName1: 'Variant 1, title',
      variantValue1: 'Variant 1, value',
      variantName2: 'Variant 2, title',
      variantValue2: 'Variant 2, value',
      variantName3: 'Variant 3, title',
      variantValue3: 'Variant 3, value',
      variantName4: 'Variant 4, title',
      variantValue4: 'Variant 4, value',
      variantName5: 'Variant 5, title',
      variantValue5: 'Variant 5, value',
      orderItems: 'Item',
      shippingCostBrutto: 'Shipping amount brutto per position',
      shippingCostNettoPerPosition: 'Shipping Amount Netto Per Position',
      shippingCostBruttoPerPosition: 'Shipping amount gross per position',
      termsOfPayment: 'Terms Of Payment',
      paymentDueDays: 'Payment Due Days',
      paymentDueDate: 'Payment Due Date',
      category: 'Item Category',
      variants: 'Variants',
      packageName1: 'Package Name 1',
      packageName2: 'Package Name 2',
      packageName3: 'Package Name 3',
      packageName4: 'Package Name 4',
      packageName5: 'Package Name 5',
      currencyId: 'Currency',
      returnFirstName: 'Return First Name',
      returnLastName: 'Return Last Name',
      returnCompanyName: 'Return Company Name',
      returnStreet: 'Return Street',
      returnHouseNo: 'Return House No.',
      returnPostalCode: 'Return ZIP',
      returnCountryCode: 'Return Country Code',
      returnCountryName: 'Return Country Name',
      returnPhoneNumber: 'Return Phone Number',
      mpAccountId: 'Marketplace Account Id',
      paymentsProgramType: 'Payments Program Type',
      invoiceType: 'Invoice type',
      isAmazonPrime: 'Amazon Prime',
      isPremiumOrder: 'Premium Order',
      isBusinessOrder: 'Business Order',
      shipmentServiceLevelCategory: 'Shipment Service Level Category',
      marketplace: 'Marketplace',
      invoiceConfirmedOnMarketplace: 'Invoice Confirmed On Marketplace',
      shippingQuantity: 'Shipped Quantity',
      orderBalance: 'Order balance',
      fulfillmentChannel: 'Fulfillment Channel',
      isDeliveryRequired: 'Delivery Required',
      warnings: 'Warnings',
      mandatName: 'Mandat name',
      countryVatId: 'Country vat id',
      vatCountry: 'Vat country',
      shipFromCountry: 'Ship from country',
      taxAddressRole: 'Tax address role',
      taxReportingScheme: 'Tax reporting scheme',
      jurisdictionName: 'Jurisdiction name',
      exchangeRate: 'Exchange rate',
      exchangeRateDate: 'Exchange rate date',
      exchangeRateCurrencyTo: 'Exchange rate currency',
    },
    ProductColumns: {
      Id: 'ID',
      Mandat: 'Mandat',
      Item: 'Item',
      Variant: 'Variant',
      Category: 'Item category',
      Sku: 'SKU',
      WhPlace: 'Warehouse зlace',
      Ean: 'Barcode',
      EanPackage: 'Barcode package',
      QtyInWh: 'Quantity in warehouse',
      QtyAvailable: 'Quantity available for sale',
      QtyMinimum: 'Quantity minimum for warehouse',
      QtyOrderedTotal: 'Quantity, ordered total',
      QtyTarget: 'QTY Target',
      QtyOrderedUnpaid: 'Quantity, ordered total unpaid',
      QtyOrderedPaid: 'Quantity, ordered total paid',
      QtyAtDeliveryDate: 'Quantity at delivery date (if ordered today)',
      Supplier: 'Supplier',
      SupplierCurrency: 'Supplier currency',
      SupplierItem: 'Title of item from supplier',
      SupplierUnitPrice: 'Purchase price',
      SupplierVariant: 'Variant of item from supplier',
      SupplierCountInPack: 'Quantity of goods in the package from the supplier',
      SupplierValue: 'Value of purchased goods',
      SupplierMoq: 'Minimum order quantity',
      SupplierMoqUnit: 'Unit of minimum order quantity',
      SupplierDeliveryTimeInDays: 'Delivery time in days',
      QtyReorderItemsRecommended: 'Recommended quantity for the next order',
      QtyReorderItemsIs: 'Actual order quantity for the next order',
      QtyReorderItemsReordered: 'Number of items ordered',
      QtyReorderPackRecommended: 'Recommended number of packages for the next order',
      QtyReorderPackIs: 'Actual number of packages for the next order',
      QtyReorderPackReordered: 'Number of packages ordered',
      ReorderDateRecommended: 'Recommended date for the next order',
      ReorderDateIs: 'Actual date for the next order',
      ReorderDateReordered: 'Date of an order already placed',
      ExpectDeliveryDateRecommended: 'Expect Delivery Date Recommended',
      ExpectDeliveryDateIs: 'Expect Delivery Date Is',
      ExpectDeliveryDateReordered: 'Expected delivery date of an order already placed',
      ProductPackagesCount: 'Quantity other packaging',
      ProductCountryCode: 'Product country code',
    },
    ProductTransferErrorColumns: {
      Id: 'ID',
      Barcode: 'Barcode',
      CreatedDate: 'Created date',
      ReportNumber: 'Report number',
    },
    TransferReportColumns: {
      Id: 'ID',
      Title: 'Name of report',
      ReportNumber: 'Report number',
      CreateDate: 'Created date',
      Download: 'Download',
    },
    ContactColumns: {
      Id: 'Id',
      SupplierNumber: 'Supplier number',
      CustomerNumber: 'Customer number',
      IsFavorite: 'Favorite',
      InvoiceCompanyName: 'Company, invoice address',
      InvoiceFirstName: 'First name, invoice address',
      InvoiceLastName: 'Last name, invoice address',
      InvoiceAdditionalLine: 'Additional line, invoice address',
      InvoiceStreet: 'Street, invoice address',
      InvoiceHouseNo: 'House No, invoice address',
      InvoicePostalCode: 'Postal code (ZIP), invoice address',
      InvoiceCityName: 'City, invoice address',
      InvoiceCountryCode: 'Country code, invoice address',
      InvoiceCountryName: 'Country, invoice address',
      ShippingCompanyName: 'Company, delivery address',
      ShippingFirstName: 'First name, delivery address',
      ShippingLastName: 'Last name, delivery address',
      ShippingAdditionalLine: 'Additional line, delivery address',
      ShippingStreet: 'Street, delivery address',
      ShippingHouseNo: 'House No, delivery address',
      ShippingPostalCode: 'Postal code (ZIP), delivery address',
      ShippingCityName: 'City, delivery address',
      ShippingCountryCode: 'Country code, delivery address',
      ShippingCountryName: 'Country, delivery address',
      FirstContactPersonTitle: 'Title, contact person 1',
      FirstContactPersonName: 'Name, contact person 1',
      FirstContactPersonEmail: 'E-Mail, contact person 1',
      FirstContactPersonPhones: 'Phone, contact person 1',
      FirstContactPersonNotes: 'Note, contact person 1',
      SecondContactPersonTitle: 'Title, contact person 2',
      SecondContactPersonName: 'Name, contact person 2',
      SecondContactPersonEmail: 'E-Mail, contact person 2',
      SecondContactPersonPhones: 'Phone, contact person 2',
      SecondContactPersonNotes: 'Note, contact person 2',
      BankDataName: 'Bank account holder',
      BankDataIBAN: 'IBAN',
      BankDataBIC: 'BIC',
      BankDataBankName: 'Bank name',
      VatNumber: 'Vat ID',
      Number: 'Number',
      Select: 'Select',
      Type: 'Type',
    },
    AddressBookColumns: {
      Id: 'ID',
      CompanyName: 'Company',
      FirstName: 'First name',
      LastName: 'Last name',
      Street: 'Street',
      HouseNo: 'House No',
      PostalCode: 'Postal code (ZIP)',
      CityName: 'City',
      CountryName: 'Country',
      FirstContactPersonPhoneNumber: 'First contact person phone number',
      FirstContactPersonEmail: 'First contact person email',
    },
    UserColumns: {
      CustomerNumber: 'Customer number',
      UserType: 'User type',
      CompanyAndName: 'Company, name',
      Country: 'Country',
      Status: 'Status',
      Select: 'Select',
    },
    TicketColumns: {
      Ticket: 'Ticket number',
      Subject: 'Subject',
      CustomerNumber: 'Customer number',
      CompanyAndName: 'Company, name',
      DateOpened: 'Date opened',
      Owner: 'Owner',
      Status: 'Status',
    },
    FileColumns: {
      Name: 'File name',
      Subject: 'Subject',
      CustomerNumber: 'Customer number',
      CompanyAndName: 'Company, name',
      Owner: 'Owner',
      CreateDate: 'Erstellungsdatum',
    },
    TransactionColumns: {
      Date: 'Date',
      TransactionType: 'Transaction type',
      PayOrInvoiceNo: 'Transaction number',
      TransactionDescription: 'Transaction description',
      TransactionStatus: 'Transaction status',
      Credit: 'Credit',
      Debit: 'Debit',
      Balance: 'Balance',
    },
    InvoiceColumns: {
      Date: 'Date',
      InvoiceNumber: 'Invoice number',
      Amount: 'Amount',
      OutstandingBalance: 'Outstanding balance',
      PaymentMethod: 'Payment method',
      PaymentDueDate: 'Payment Due Date',
      InvoiceCloseDate: 'Invoice Close Date',
      OpenInvoice: 'Open Invoice',
      OpenSdr: 'Open SDR',
    },
    PaymentColumns: {
      Date: 'Date',
      PayId: 'Pay ID',
      BatchId: 'Batch ID',
      PayAmount: 'Pay amount',
      AccountBalance: 'Balance',
      RelatedInvoiceNumbers: 'Allocation to the invoice',
      PaymentReference: 'Payment reference',
      Status: 'Status',
    },
    MpAccountColumns: {
      Id: 'ID',
      IsActive: 'Is active',
      Mandat: 'Mandat',
      MarketplaceType: 'Marketplace Type',
      AccountName: 'Account Name',
      MarketplaceLogin: 'Marketplace Login',
      TokenExpirationTime: 'Token Expiration Time',
      CreatedTime: 'Created Time',
    },
    SubscriptionColumns: {
      Id: 'ID',
      Sku: 'SKU',
      IsActive: 'Is active?',
      IsActiveForSales: 'Is active for sales?',
      IsRecurring: 'Is recurring?',
      SubscriptionName: 'Subscription name',
      ContractTerm: 'Contract term',
      CancellationPeriod: 'Cancellation period',
      UserSegment: 'Customer segment',
      UserCategory: 'Customer category',
      RecurringPeriod: 'Recurring period',
      IsSpecialOffer: 'Is Special Offer',
      IsDefault: 'Is Default',
      Vat: 'Vat, %',
    },
    UserProductColumns: {
      Id: 'ID',
      ProductType: 'Type',
      AdditionaServiceType: 'Additional service tittle',
      International: 'National/international',
      CountriesSender: 'Country of sender',
      CountriesRecipient: 'Country of recipient',
      UserLogistic: 'Display logistic, appears with the customer',
      UserProduct: 'Display product, appears with the customer',
      UserSubProduct: 'Display subproduct, appears with the customer',
      ShippingService: 'Pickup/curier',
      ShippingMethods: 'Actual shipping methods',
      AllowedPackageTypes: 'Allowed package types',
      MeasurePackageType: 'Package measure type',
      MinLength: 'Min. length, cm',
      MaxLength: 'Max. length, cm',
      MinWidth: 'Min. width, cm',
      MaxWidth: 'Min. width, cm',
      MinHeight: 'Min. height, cm',
      MaxHeight: 'Max. height, cm',
      MaxDeterminingGirth: 'Max. determining girth',
      MaxShortestLongestSize: 'Max shortest + longest Size',
      WeightMinKg: 'Min. weight, kg',
      WeightMaxKg: 'Max. weight, kg',
      MaxInsurance: 'Max insurance',
      CurrencyType: 'Currency',
      OriginalLogistic: 'Original logistic',
      OriginalProduct: 'Original product',
      OriginalSubproduct: 'Original subproduct',
      Duty: 'Duty',
      Decalred: 'Declared',
      PrinterRequired: 'Printer required',
      ProductServiceForBillingActive: 'For billing active',
      SkuBilliable: 'SKU for billing',
      ProductAddServiceForNonBillingActive: 'Without billing active',
      SkuNoBilliable: 'SKU non-billiable',
      AdditionalServiceType: 'Additional service tittle',
      CreatedTime: 'Created Time',
    },
    ShortDocumentColumns: {
      Id: 'ID',
      Name: 'Name',
      Size: 'Size',
      Date: 'Date',
    },
    DocumentColumns: {
      Id: 'ID',
      Mandat: 'Mandat',
      DocumentName: 'Name',
      DocumentDebitOrCredit: 'Debit/credit',
      DocumentCategory: 'Category',
      DocumentSubcategory: 'Subcategory',
      DocumentFolder: 'Allocate category',
      DocumentDate: 'Document date',
      PaymentDate: 'Payment Date',
      DocumentType: 'Document type',
      DocumentNumber: 'Document number',
      NetAmount: 'Net amount',
      NetAmountCurrency: 'Net amount, currency',
      VatAmount: 'VAT amount',
      VatAmountCurrency: 'VAT amount, currency',
      GrosAmount: 'Gross amount',
      GrosAmountCurrency: 'Gross amount, currency',
      Note: 'Note',
      ContactNumber: 'Customer/supplier Number',
      ContactType: 'type',
      ContactCompany: 'Company',
      ContactName: 'Name',
      ContactAdditional: 'Additional',
      ContactStreet: 'Street, House Number',
      ContactZip: 'ZIP',
      ContactCity: 'City',
      ContactCountryCode: 'Country сode',
      ContactCountry: 'Country',
      ContactEmail: 'E-Mail',
      ContactPhone: 'Phone number',
      UploadDate: 'Upload date',
      AllocateDate: 'Allocate date',
      LastModifiedDate: 'Last modified date',
      LastReportedDate: 'Last reported date',
      DocumentExtension: 'File format',
    },
    DocumentReportColumns: {
      Id: 'ID',
      Mandat: 'Mandat',
      DocumentsCount: 'Number of documents',
      Title: 'Name of report',
      CreateDate: 'Created Date',
      ValidDate: 'Valid Date',
      FileSize: 'Size',
      Download: 'Download',
      PrintStatus: 'Print status',
      PackageTrackingId: 'Package tracking ID',
      Delete: 'Delete',
    },
    OrderItemColumns: {
      Quantity: 'Quantity',
      SKU: 'SKU',
      ItemName: 'Item Name',
      ItemVariant: 'Item Variant',
      UnitPrice: 'Unit Price',
      VAT: 'VAT, %',
      Price: 'Price',
      Select: 'Select',
      Item: 'Item',
      Category: 'Category',
    },
    UserProductDuplicatedColumns: {
      GeneratorProductId: 'Generator Id',
      GeneratorProductName: 'Generator name',
      UserProductId: 'User product id',
    },
    DigitalProductColumns: {
      Id: 'Id',
      Sku: 'Sku',
      Name: 'Name',
      NumberOfUses: 'Number Of Uses',
      IsActive: 'Is Active',
      IsActiveForSales: 'Is Active For Sales',
      Vat: 'Vat, %',
      IsIntern: 'Is intern',
      Description: 'Description',
      ActiveForSalesByCountries: 'Active for sales by countries',
      FunctionId: 'Function id',
      FunctionName: 'Function name',
    },
    CurrencyColumns: {
      Standard: 'Standard',
      Title: 'Title',
      Symbol: 'Symbol',
      AlphabeticCode: 'Alphabetic Code',
      NumericCode: 'Numeric Code',
      MinorUnit: 'Minor Unit',
      Select: 'Select',
    },
    UploadTemplateColumns: {
      UseForAutoSave: 'Use for auto save',
      TemplateName: 'Template name',
      DocumentTemplate: 'Document template',
      DebitOrCredit: 'Debit/Credit',
      Category: 'Category',
      Subcategory: 'Subcategory',
      PaymentDate: 'Payment date',
      DocumentType: 'Document type',
      DocumentFolder: 'Document folder',
      PostingAmount: 'Posting amount',
    },
    EmailTemplateColumns: {
      Templates: 'Templates',
      UseToSendDeliveryNote: 'Use to send delivery note',
      UseToSendSale: 'Use to send sale',
      UseToSendPurchase: 'Use to send purchase',
      UseToSendInvoice: 'Use to send invoice',
      UseToSendCancelInvoice: 'Use to send cancel invoice',
      TemplateSharingType: 'Template sharing type',
    },
    CustomerShoppingCardColumns: {
      Id: 'ID',
      ShoppingCardId: 'Shopping Card ID',
      CardItemId: 'Shopping Card Item ID',
      Sku: 'SKU ',
      MarketUser: 'Market User',
      CustomerNumber: 'Customer Number',
      InsertItemDate: 'Insert Item date',
      LastModifiedDate: 'Last modified date',
      BillingStopDate: 'Billing stop date',
      BillingStatus: 'Billing status',
      ItemType: 'Item Type',
      Qty: 'QTY',
      StartDate: 'Start date',
      EndDate: 'End date',
      ItemTitle: 'Item Title',
      Variant1Title: 'Variant 1 Title',
      Variant1Value: 'Variant 1 Value',
      Variant2Title: 'Variant 2 Title',
      Variant2Value: 'Variant 2 Value',
      Variant3Title: 'Variant 3 Title',
      Variant3Value: 'Variant 3 Value',
      Variant4Title: 'Variant 4 Title',
      Variant4Value: 'Variant 4 Value',
      Variant5Title: 'Variant 5 Title',
      Variant5Value: 'Variant 5 Value',
      UnitPriceNet: 'Unit Price Net',
      Vat: 'VAT, %',
      IsDeliveryRequired: 'Is Delivery required',
      InternalNote: 'Internal Note',
    },
    AccountChartColumns: {
      CreateDate: 'Create Date',
      LastModifiedTime: 'Last Modified Time',
      Title: 'Title',
      Active: 'Active',
      Value: 'Value',
      Manual: 'Manual',
      Default: 'Default',
    },
    BillingShoppingCardColumns: {
      Id: 'ID',
      ShoppingCardId: 'Shopping Card ID',
      CardItemId: 'Shopping Card Item ID',
      Sku: 'SKU ',
      MarketUser: 'Market User',
      CustomerNumber: 'Customer Number',
      InsertItemDate: 'Insert Item date',
      LastModifiedDate: 'Last modified date',
      BillingStopDate: 'Billing stop date',
      BillingStatus: 'Billing status',
      ItemType: 'Item Type',
      Qty: 'QTY',
      StartDate: 'Start date',
      EndDate: 'End date',
      ItemTitle: 'Item Title',
      Variant1Title: 'Variant 1 Title',
      Variant1Value: 'Variant 1 Value',
      Variant2Title: 'Variant 2 Title',
      Variant2Value: 'Variant 2 Value',
      Variant3Title: 'Variant 3 Title',
      Variant3Value: 'Variant 3 Value',
      Variant4Title: 'Variant 4 Title',
      Variant4Value: 'Variant 4 Value',
      Variant5Title: 'Variant 5 Title',
      Variant5Value: 'Variant 5 Value',
      UnitPriceNet: 'Unit Price Net',
      Vat: 'VAT, %',
      IsDeliveryRequired: 'Is Delivery required',
      InternalNote: 'Internal Note',
    },
    WarehouseTransferColumns: {
      Qty: 'QTY',
      Ean: 'EAN',
      Sku: 'SKU',
      Item: 'Item',
      Variants: 'Variants',
    },
    MandatsSettingsColumns: {
      Id: 'ID',
      MandatName: 'MandatName',
      InvoiceCountry: 'Invoice country',
      CreatedTime: 'Created time',
    },
    WarehousePostingColumns: {
      Sku: 'Sku',
      PackageQuantity: 'Packages quantity',
      PackageId: 'Package Id',
      ItemsQuantity: 'Items quantity',
      Title: 'Title',
      Variant: 'Variant',
      Charge: 'Charge',
      BestBeforeDate: 'Best before',
      Warranty: 'Warranty',
      CustomTariffNumber: 'Custom tariff number',
      DateIncomingToWarehouse: 'Date incoming to warehouse',
      DateLastOutcoming: 'Date last outcoming',
      PriorityBy: 'Priority by',
      PriorityException: 'Priority exception',
      DatePriorityException: 'Date priority exception',
      Mandat: 'Mandat',
      PriorityChangeDate: 'Priority change date',
    },
    WarehouseUnitHistoryColumns: {
      Id: 'ID',
      CreatedTime: 'Created time',
      AppUserName: 'User name',
      UserOrderId: 'Order Id',
      Quantity: 'Quantity',
      QuantityOld: 'Quantity before',
      QuantityNew: 'Quantity after',
      BatchType: 'Type',
      PackageId: 'Package Id',
      ProductTitle: 'Title',
      ProductVariant: 'Variant',
      Charge: 'Charge',
      ProductMandatName: 'Mandat name',
      BestBefore: 'Best before',
      WarrantyUntil: 'Warranty until',
      CustomsTariffNumber: 'Customs tariff number',
      CustomDeclarationNumber: 'Custom cargo declaration number',
      PostingToPlaceTime: 'Date posting to place',
      PostingFromPlaceTime: 'Date posting from place',
      IncomingToWarehouseTime: 'Incoming to warehouse location time',
      ProductSku: 'Sku',
    },
    TransferModalTableColumns: {
      Quantity: 'Quantity',
      Barcode: 'Ean',
      Sku: 'Sku',
      Item: 'Item',
      Variant: 'Variant',
    },
    SelectedUnitPostingsColumns: {
      Name: 'Product name',
      Sku: 'SKU',
      Quantity: 'Quantity',
      IncomingToWarehouseTime: 'Incoming to warehouse time',
    },
  },
  ModalWindows: {
    ConnectionLostModal: {
      Title: 'No internet connection !',
      Text: 'Please check your connection or try again.',
    },
    CookieModal: {
      Title: 'Cookie and analytics',
      Text:
        'We use cookies to give you the best experience on our website. By continuing to use this site, you agree to the use of cookies.',
    },
    Confirmation: {
      Title: 'Warning',
      Text: 'All changes will be lost. Do you want to save changes?',
    },
    ChangePaymentStatus: {
      Title: 'Warning',
      TextForAllPaid:
        "All selected orders have <b>'Payed'</b> status. Do you want to change status for <b>'Not payed'</b>?",
      TextForAllNotPaid:
        "All selected orders have <b>'Not payed'</b> status. Do you want to change status for <b>'Payed'</b>?",
      TextForPayedAndNotPaid:
        "You have selected orders with the statuses <b>'Not payed'</b> and <b>'Payed'</b> orders. What status do you want to set for orders?",
      TextForSingleNotPaid:
        "The selected order have <b>'Not payed'</b> status. Do you want to change status for <b>'Payed'</b>?",
      PaidAndNotPaidForm: {
        SelectPaymentStatus: 'Select payment status',
        PaymentDate: 'Payment date',
        OverwriteDates: 'Overwrite dates',
      },
      AllNotPaidForm: {
        PaymentDate: 'Payment date',
      },
      SingleNotPaidOrderForm: {
        PaymentDate: 'Payment date',
        Amount: 'Amount',
      },
    },
    ChangeDeliveryStatus: {
      Title: 'Warning',
      TextForAllShipped:
        "All selected orders have <b>'Shipped'</b> status. Do you want to change status for <b>'Not shipped'</b>?",
      TextForAllNotShipped:
        "All selected orders have <b>'Not shipped'</b> status. Do you want to change status for <b>'Shipped'</b>?",
      TextForShippedAndNotShipped:
        "You have selected orders with the statuses <b>'Not Paid'</b> and <b>'Paid'</b>. Which status would you like to set for the orders?",
      TextForAllReceived:
        "All selected orders have <b>'Received'</b> status. Do you want to change status for <b>'Not received'</b>?",
      TextForAllNotReceived:
        "All selected orders have <b>'Not received'</b> status. Do you want to change status for <b>'Received'</b>?",
      TextForReceivedAndNotReceived:
        "You selected <b>'Not received'</b> and <b>'Received'</b> orders. What status do you want to set for orders?",
      ShippedAndNotShippedForm: {
        SelectShippingStatus: 'Select shipping status',
        ShippingDate: 'Shipping date',
        OverwriteDates: 'Overwrite dates',
      },
      AllNotShippedForm: {
        ShippingDate: 'Shipping date',
      },
    },
    ChangeOtherStatuses: {
      Title: 'Change status',
    },
    AddressValidationResolving: {
      Title: 'The address could not be found.',
      Warning: 'The information is taken from Google and the address may not be corrected correctly.',
      AddressNotFound: 'Address not valid, please adjust shipping address.',
      OriginalShippingAddress: 'Original shipping address',
      NewShippingAddress: 'New shipping address',
      EmptyActiveLogisticsModal: {
        Title: 'Сreating labels',
        Text: 'You cannot create a label because there are no active logistics. Please go to logistics settings',
        LogisticSettings: 'Logistics settings',
      },
    },
    OrderLinkWithProduct: {
      Description: {
        Title: 'Order shipping lable',
        Text: 'Allocation of shippingethod for following orders not automated, pleace allocate shipping method.',
      },
      Header: {
        Market: 'Market',
        ID: 'ID',
        Invoice: 'Invoice',
        Items: 'Items',
        Amount: 'Amount',
        Country: 'Country',
        OriginalShippingMethod: 'Original shipping method',
        NewShippingMethod: 'New shipping method',
      },
      Buttons: {
        AddNewPackage: '+ Add new package',
        PackageAutomationRules: 'Shipping automation',
        Cancel: 'Cancel',
        SavePackages: 'Save packages',
        SavePackagesTemplates: 'Saving packets and sending rules',
      },
      Toasts: {
        Title: 'Rules validation',
        Message: 'Rules have already been created',
      },
      Options: {
        OwnShippingContract: 'Own shipping contract',
        OrderShippingLableByShipCheaper: 'Order shipping lable by Ship Cheaper',
      },
      Placeholders: {
        SelectLogisticProduct: 'Select logistic product',
        SelectPackage: 'Select package',
        Width: 'Width',
      },
      OwnShippingContract: {
        YouHaveNotYetActivatedAnyLogisticsProducts: 'You have not activated any logistics products yet',
        Activate: 'Activate',
        ChooseAPackageFirst: 'Choose a package first',
        TherIsNoSingleLogisticsForTheSelectedPackages:
          'There is no single logistics for the selected packages, change the package exchanges',
      },
      ShipCheaperContract: {
        Curier: 'Curier',
        Pickup: 'Pickup',
        Order: 'Order',
      },
    },
    OrderLabelPrint: {
      Title: 'Label create and print',
      Columns: {
        ID: 'ID',
        Market: 'Market',
        Invoice: 'Invoice',
        Country: 'Country',
        Packages: 'Packages',
        Price: 'Price',
        Created: 'Created',
        Printed: 'Printed',
        Edit: 'Edit',
      },
      Buttons: {
        CreateLabes: 'Create labels',
        PrintLables: 'Print lables',
        CreatePrintLables: 'Create and print lables',
      },
      LabelsCreateSuccessToast: {
        title: 'Labels creation',
        messageMany: '{0_created} {0} {0_labels}',
        messageSingle: 'Created label {0}',
        parts: {
          created: {
            one: 'Created',
            few: 'Created',
            many: 'Created',
          },
          labels: {
            one: 'label',
            few: 'labels',
            many: 'labels',
          },
        },
      },
      LabelsPrintedSuccessToast: {
        title: 'Labels printing',
        messageMany: '{0_created} {0} {0_labels}',
        messageSingle: 'Printed label {0}',
        parts: {
          created: {
            one: 'Printed',
            few: 'Printed',
            many: 'Printed',
          },
          labels: {
            one: 'label',
            few: 'labels',
            many: 'labels',
          },
        },
      },
    },
    SelectContact: {
      Title: 'Select customer / supplier',
      AddNewSupplier: '+ Add new supplier',
      AddNewCustomer: '+ Add new customer',
      Buttons: {
        AddNewSupplier: '+ Add new supplier',
        AddNewCustomer: '+ Add new customer',
      },
    },
    MpSynchronization: {
      NoAccountsMessage: 'You have not yet integrated a marketplace. Would you like to connect with your account?',
      ModalTabs: {
        Marketplace: {
          title: 'Marketplace',
          text: 'To synchronise with the Marketplace, select the accounts and press the synchronise button',
        },
        Logistic: {
          title: 'Logistic',
          text: 'To synchronise with the Logistic, select the logistics and press the synchronise button',
        },
        UpdateByFile: {
          title: 'Update by file',
          text: '',
        },
      },
      UpdateByFileTab: {
        dropzoneText: 'Put your .csv or .txt file here',
        updateOrdersButton: 'Update orders by file',
        form: {
          mpAccountLabel: 'Select marketplace account ',
        },
      },
    },
    GenerateDocumentNumberConfirmation: {
      ConfirmationText: 'Should the {0} be created?',
      ModalWindowTitle: 'Documents creating',
      PrintDocument: '{0} print',
      SendEmail: '{0} via email send',
      Sorting: 'Sorting',
      TemplateNotExists: 'There are no templates attached to these documents',
    },
    GenerateAlreadyExistsDocumentNumberConfirmation: {
      ConfirmationText: 'A document with the same number already exists. Do you still want to keep it?',
      ModalWindowTitle: 'Documents creating',
    },
    SelectCurrency: {
      Title: 'Select currency',
    },
    SplitOrder: {
      Description: {
        Title: 'Split order',
        Text: 'Move the items in to orders',
      },
      SaveSplitOrderDialog: {
        Title: 'Split order',
        Text: 'Are you sure you want to split order?',
      },
      CancelSplitOrderDialog: {
        Title: 'Split order',
        Text: 'Are you sure you want to cancel split order? All changes will be lost.',
      },
      AddNewOrderButton: 'Add new order',
      DeleteEmptyOrdersMessage: 'Delete empty orders.',
      CreateMoreThanOneOrderMessage: 'Create more than one not empty order.',
    },
    MergeOrders: {
      Description: {
        Title: 'Merge orders',
        Text: 'There are differences between orders. Choose value, which should be the same.',
      },
      MergeConfirmText: 'Are you sure you want to merge orders?',
      MarketplaceAccountSection: {
        Account: 'Marketplace account',
      },
      MergeBuyerSection: {
        BuyerInfoLabel: 'Buyer info',
        BuyerEmail: 'Buyer email',
        BuyerMarketplaceAccount: 'Buyer marketplace account',
        BuyerNote: 'Buyer note',
        BuyerVatNumber: 'Buyer VAT number',
      },

      AddressContactSection: {
        InvoiceContactInfo: 'Invoice contact info',
        ShippingContactInfo: 'Shipping contact info',
        Company: 'Company',
        Name: 'Name',
        Phone: 'Phone',
      },
      AddressSection: {
        InvoiceAddress: 'Invoice address',
        ShippingAddress: 'Shipping address',
        Additional: 'Additional',
        Street: 'Street',
        ZipAndCity: 'Zip/City',
        Country: 'Country',
      },
    },
    DocumentPrintModal: {
      Title: 'Templates',
      PrintingOrder: 'Printing order',
      PrintDisabled: 'One or more orders do not have documents selected',
      Columns: {
        CountCopy: 'Count copy',
        Sorting: 'Sorting',
        Name: 'Template',
      },
      SelectDocumentModal: {
        Title: 'Document print',
        CountCopy: 'Count copy',
        DocumentNumber: 'Document number',
        CreatedDate: 'Created date',
        DocumentDate: 'Document date',
      },
      CreateDocumentModal: {
        Title: 'Create document',
        Text: 'The document has not been created yet. Do you want to create a document?',
      },
    },
    EmailSendModal: {
      Title: 'Templates',
      EmailsSuccesfullySent: '{0} {0_email} {0_sent} successfully',
      EmailSend: 'Email send',
      EmailPreviewModal: {
        Title: 'Email preview modal',
        To: 'To',
        Subject: 'Subject',
        Cc: 'Cc',
        Bcc: 'Bcc',
        Attachments: 'Attachments',
        ThereAreNoAttachments: 'There are no attachments',
      },
      parts: {
        email: {
          one: 'email',
          many: 'emails',
          few: 'emails',
        },
        sent: {
          one: 'sent',
          few: 'sent',
          many: 'sent',
        },
      },
    },
    SynchOrdersCompleteModal: {
      Text:
        'Synchronization was carried out successfully. <br> You have {0} new sales, {1} udated sales and {2} new purchases, {3} updated purchases.',
      TextForCheckbox: 'Remember for the future (can be reset in settings).',
    },
    DeliveryProgressTimeModal: {
      title: 'Detailed shipment tracking',
    },
  },
  Emails: {
    // eslint-disable-next-line quotes
    LegalNotice: `Legal Notice: <br /><br /> Information provided pursuant to Section 5 of the German Telemedia Act (TMG): <br /> Ship Cheaper <br /> Viktor Schleicher <br /> Kaiserstrasse 19 <br /> 76646 Bruchsal <br /> Deutschland <br /><br /> Contact: <br /> Telephone: +49 152 31794000 <br /> Fax: +49 7251 9235335 <br /> Email: customer.service@ship-cheaper.com <br /><br /> VAT ID number according to Section 27a of the German Value Added Tax Act: DE279959918 <br /> <br /> Dispute resolution: <br /> The European Commission provides a platform for online dispute resolution (OS): <a href='https://ec.europa.eu/consumers/odr'>https://ec.europa.eu/consumers/odr.</a> <br /> Please find our email address in this legal notice. <br /> We do not take part in online dispute resolutions at consumer arbitration boards.`,
    DontReplyText: "We don't check this mailbox, so please don't reply to this message.",
    ConfirmEmail: {
      Subject: 'Confirm your E-Mail address within 24 hours',
      Text: {
        Title: 'Confirm this E-Mail address',
        InformationText:
          "Hi {UserName}, <br /> Please, confirm this email address, and you'll be able to sign in to your new account with it. <br /> If you didn't just try to register for an account with this email address, please contact us.",
        ConfirmButtonText: 'Confirm E-Mail',
      },
    },
    ResetPassword: {
      Subject: 'Ship Cheaper password reset',
      Text: {
        Title: "Confirm it's you to access your Ship-Cheaper account",
        InformationText:
          "Hi {UserName}, <br /> Please use the following button to reset your password. If you don't use this link within 24 hours, it will expire. <br />",
        ConfirmButtonText: 'Reset your password',
      },
    },
  },
  Menu: {
    Title: 'Menu',
    SplitOrder: {
      LabelTitle: 'Splitting orders',
      MultiOrdersSelectedMessage: 'Only one order can be split.',
      OnlyOnePositionMessage: 'More than one line item is required to split an order.',
      SentOrderSelectedMessage: 'You cannot split a sent order.',
      InvoiceWithoutCancelMessage:
        'The order cannot be split because an invoice has been created, create a cancellation invoice.',
      OrderBlockedMessage: 'Order is blocked.',
    },
    MergeOrder: {
      LabelTitle: 'Order merger',
      OneOrLessOrderSelectedMessage: 'Only more than one order could be merged.',
      SentOrderSelectedMessage: "You can not merge a sent order's.",
      OrderBlockedMessage: 'One of order is blocked.',
      InvoiceWithoutCancelMessage:
        "One of order's cannot be merged because an invoice has been created, create a cancellation invoice.",
      DifferentOrderStatusesMessage: 'The orders has different order statuses.',
      CancelStatusIsDifferentFromNotApplicableMessage:
        "One of the orders has different cancel status from 'Not Applicable'.",
      DifferentTermsOfPaymentMessage: 'The orders has different terms of payment.',
      DifferentPaymentMethodsMessage: 'The orders has different payment methods.',
      OrderIsArchived: 'One of the orders has been archived.',
    },
    ShippingLabel: {
      LabelTitle: 'Shipping Label',
    },
    AssignDeliveryNoteNumber: {
      LabelTitle: 'Assign delivery note number',
    },
    AssignInvoiceNumber: {
      LabelTitle: 'Assign invoice number',
    },
    ChangePaymentStatus: {
      LabelTitle: 'Change payment status',
    },
    Document: {
      LabelTitle: 'Document',
    },
    CreateFeedback: {
      LabelTitle: 'Create Feedback',
    },
    ChangeOrderStatuses: {
      LabelTitle: 'Change order statuses',
    },
    AssignOrderNumber: {
      LabelTitle: 'Assign order number',
    },
    AssignCancelInvoiceNumber: {
      LabelTitle: 'Assign cancel invoice number',
    },
    ChangeDeliveryStatus: {
      LabelTitle: 'Change delivery status',
    },
    Email: {
      LabelTitle: 'Email',
    },
    Tooltips: {
      ChangeOrderStatusDisabledFulfilledByMarketplace: {
        Message: 'You can not change order statuses for {1_orders} {0} because of marketplace fulfillment',
      },
      CreateFeedbackDisabledFulfilledByMarketplace: {
        Message: 'You can not create feedback for {1_orders} {0} because of marketplace fulfillment',
      },
      CreateShippingLabelDisabledFulfilledByMarketplace: {
        Message: 'You can not create shipping label for {1_orders} {0} because of marketplace fulfillment',
      },
      ChangeDeliveryStatusNotPostpaidNotPaid: {
        Message: "You can't change delivery status of orders before they are paid. {0_orders}: {1}",
      },

      parts: {
        orders: {
          one: 'order',
          few: 'orders',
          many: 'orders',
        },
      },
    },
  },
  Models: {
    OrderTableRow: {
      InvoiceNumber: {
        DisabledDocumentMessage:
          'Документ <b>{0}</b> создан <b>{1}</b> , для измененного текущего счёта сначала создайте отмену счёта',
        DisableHeaderDocumentMessage:
          'The selected {1_order} already have one or more {1_invoice} with the {1_invoiceNumber} <b>{0}</b> created.',
        DisableMessage:
          'Документ <b>{0}</b> создан <b>{1}</b> , для измененного текущего счёта сначала создайте отмену счёта',
        InvoiceCreateDisabledForMpAccountMessage:
          'Invoice can not be created for current marketplace. It can be changed by updating corresponding marketplace setup',
      },
      CancelInvoiceNumber: {
        DisabledDocumentMessage:
          'Invoice <b>{0}</b>, created on <b>{1}</b>; cancellation number <b>{2}</b>, canceled on <b>{3}</b>',
        DisableHeaderDocumentMessage: '{1_order} <b> {0} </b> already have {1_cancelInvoiceNumber} assigned.',
        NoInvoicesCreatedYetMessage: 'No invoices created yet',
      },
      OrderNumber: {
        DisabledDocumentMessage: 'Order <b>{0}</b> created on <b>{1}</b>',
        DisableHeaderDocumentMessage: '{1_order} <b>{0}</b> already have {1_orderNumber} assigned.',
        DisableMessage: 'Order <b>{0}</b> created on <b>{1}</b>',
      },
      DeliveryNoteNumber: {
        DisabledDocumentMessage: 'Delivery note <b>{0}</b> created on <b>{1}</b>',
        DisableHeaderDocumentMessage: '{1_order} <b>{0}</b> already have {1_deliveryNumber} assigned.',
        DisableMessage: 'Delivery note <b>{0}</b> created on <b>{1}</b>',
      },
      CustomerNumber: {
        DisabledDocumentMessage: 'Customer number <b>{0}</b> created on <b>{1}</b>',
        DIsabledDocumentMessage: 'Customer number <b>{0}</b> created on <b>{1}</b>',
      },
      parts: {
        selected: {
          one: 'selected',
          few: 'selected',
          many: 'selected',
        },
        order: {
          one: 'order',
          few: 'orders',
          many: 'orders',
        },
        invoiceNumber: {
          one: 'invoice number',
          few: 'invoice number',
          many: 'invoice numbers',
        },
        invoice: {
          one: 'invoice',
          many: 'invoices',
          few: 'invoices',
        },
        cancelInvoiceNumber: {
          one: 'cancel invoice number',
          many: 'cancel invoice numbers',
          few: 'cancel invoice numbers',
        },
        orderNumber: {
          one: 'order number',
          many: 'order numbers',
          few: 'order numbers',
        },
        deliveryNumber: {
          one: 'delivery note number',
          many: 'delivery note numbers',
          few: 'delivery note numbers',
        },
      },
      CommonErrors: {
        AmazonOrderPending: {
          DisabledDocumentMessage: '',
          DisableHeaderDocumentMessage: '',
          DisableMessage: '',
        },
      },
    },
  },
  DigitalProducts: {
    'Online archive': {
      name: 'Online archive',
      description: '{numberOfUses} document units only for {price}',
    },
  },
  FunctionGroups: {
    Marketplace: 'Marketplace',
    Warehouse: 'Warehouse',
  },
  Functions: {
    AddMarketplaces: 'Add Marketplaces',
    ShippingAutomation: 'Shipping Automation',
  },
  Controls: {
    Pagination: {
      Results: 'Results: {0} – {1} of {2}',
    },
  },
  Sidebar: {
    Title: 'Help & Support',
    TourStartMessage: {
      Title: 'Tour start',
      ErrorMessage: '',
      SuccessMessage: '',
    },
  },
  Tours: {
    Modals: {
      DocumentCreatingTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      ChangePaymentStatusTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      ChangeDeliveryStatusTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      DocumentPrintTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      ChangeStatusTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
    },
    Sales: {
      Default: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step7: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step8: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step9: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step10: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step11: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      FunctionMenuTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step7: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step8: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step9: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step10: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step11: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      OrderItemsTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      BasicParametersTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      AddressesTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step7: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step8: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step9: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      ShippingTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      NotesTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      NotesModalTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      HistoryModalTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      AdditionalTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
    },
    Accounting: {
      UploadTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step7: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step8: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step9: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step10: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step11: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step12: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      AllocateTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step7: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step8: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step9: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step10: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      AllocateModalTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step7: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step8: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step9: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step10: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      ArchiveTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step7: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step8: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step9: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step10: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      ArchiveModalTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step7: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step8: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step9: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      PostingTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step7: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step8: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step9: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      ReportTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step7: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step8: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
    },
    Warehouse: {
      PageTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step7: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step8: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step9: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step10: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step11: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      CreateUnitTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      AddDownUnitTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      UpdateUnitTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      ReportsTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
    },
    AccountSettings: {
      Default: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step7: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      AccountSettings: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
    },
    AddressBookSettings: {
      Default: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step7: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
    },
    SyncSettings: {
      Default: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
    },
    MandatsSettings: {
      Default: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step7: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step8: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step9: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step10: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step11: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step12: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step13: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step14: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step15: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step16: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step17: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      LegalNotice: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step7: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step8: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step9: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step10: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step11: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step12: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step13: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
    },
    MarketplaceSettings: {
      Default: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      Account: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step7: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step8: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
    },
    OrderAutomationSettings: {
      Default: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
    },
    AccountingSettings: {
      General: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      DocumentCategories: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step7: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step8: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      Allocate: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      UploadTemplates: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step7: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step8: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step9: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      UploadTemplatesModal: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step7: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step8: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step9: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      AccountChart: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      AccountChartModal: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      ScanDocuments: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
    },
    PackageSettings: {
      Default: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      Package: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
    },
    EmailTemplateSettings: {
      Default: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      Template: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step7: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
    },
    WarehouseSettings: {
      PageTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step7: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step8: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step9: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step10: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step11: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      WarehouseTypeTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      WarehouseLocationTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      WarehouseTemplateTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      TemplateUnitTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
    },
    ShippingAutomationSettings: {
      Amount: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step7: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step8: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step9: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      Category: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step7: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step8: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      Packaging: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step7: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step8: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      Mix: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
    },
    DocumentTemplateSettings: {
      PageTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      TemplateTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step7: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
    },
    ProductSettings: {
      PageTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      CategoryTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
    },
    LogisticSettings: {
      PageTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      ConnectTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      LogisticTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
      AccountSettingsTour: {
        Title: '',
        Step1: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step2: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step3: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step4: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step5: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step6: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step7: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step8: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step9: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step10: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step11: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
        Step12: {
          Title: '',
          Text: '',
          DetailsLink: '',
        },
      },
    },
  },
} as const;

export const enTranslationDocumentDesigner = {
  TranslationDocumentDesigner: {},
};
